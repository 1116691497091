import { render, staticRenderFns } from "./Robot_contract_detail.vue?vue&type=template&id=45c051da&scoped=true&"
import script from "./Robot_contract_detail.vue?vue&type=script&lang=js&"
export * from "./Robot_contract_detail.vue?vue&type=script&lang=js&"
import style0 from "./Robot_contract_detail.vue?vue&type=style&index=0&id=45c051da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c051da",
  null
  
)

export default component.exports