<template>
  <div style="">
    <el-card class="robot-strategy" style="padding-bottom: 0px" id="robot-earn" shadow="hover">
      <div style="vertical-align: middle;position:relative;font-size: 16px;color: #1E2329;">
        <span style="font-weight: 600;font-family: PingFangTC-Semibold, PingFangTC;">{{ $t('robot.robot_products') }}</span>
        <tooltip :content="$t('robot.content1')" :width="'300px'" :imgWidth="'19px'"></tooltip>
      </div>
      <div class="robot_set" style="margin-top: 20px; position: relative;">
        <div class="create_celue" style="">
          <div style="position:relative;vertical-align: middle;font-size: 14px;font-family: PingFangTC-Regular, PingFangTC;font-weight: 400;color: #72777E;display: flex; align-items: center">{{ $t('robot.futures_spot') }}
            <tooltip :content="$t('robot.auto_robot_tip')" :width="'300px'" :imgWidth="'16px'"></tooltip>
            <span style="font-size: 14px; position: absolute; right: 0; cursor: pointer;" @click="showMInPosition(1)">{{ $t('robot.minimum_position') }} ></span>
          </div>
          <div class="trade_z" @click="openAddDialog5('')">
            <img src="../../assets/img/robot/robot_strategy/strategy_term.png">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.futures_spot_strategy') }}</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">现货合约无限对冲，无惧风险，不止损、不爆仓</span>
            </div>
            <span class="trade_add_y">+</span>
            <img style="position: absolute; right: 0; top: 0;" src="../../assets/img/robot/robot_strategy/recommend_icon.png">
          </div>
          <div class="trade_z" @click="openAddDialog6('')">
            <img src="../../assets/img/robot/robot_strategy/strategy_term.png">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.futures_spot_strategy') }}（闪电版）</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">现货合约无限对冲，无惧风险，不止损、不爆仓</span>
            </div>
            <span class="trade_add_y">+</span>
            <img style="position: absolute; top: 0; right: 0;" src="../../assets/img/robot/robot_strategy/speed_icon1.png">
          </div>
          <div class="trade_z" @click="openAddDialog7('')">
            <img src="../../assets/img/robot/robot_strategy/strategy_term.png">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.futures_spot_strategy') }}（BTC 版）</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">现货合约无限对冲，无惧风险，不止损、不爆仓</span>
            </div>
            <span class="trade_add_y">+</span>
            <img style="position: absolute; top: 0; right: 0;" src="../../assets/img/robot/robot_strategy/btc_icon.png">
          </div>
          <div style="position:relative;vertical-align: middle;font-size: 14px;font-family: PingFangTC-Regular, PingFangTC;font-weight: 400;color: #72777E;margin-top: 20px;display: flex; align-items: center">{{ $t('robot.futures_products') }}
            <tooltip :content="$t('robot.auto_robot_tip')" :width="'300px'" :imgWidth="'16px'"></tooltip>
            <span style="font-size: 14px; position: absolute; right: 0; cursor: pointer;" @click="showMInPosition(2)">{{ $t('robot.minimum_position') }} ></span>
<!--            <span v-if="isMobile" style="display:inline-block;position:absolute;right:0;top: 0px;font-size: 14px">{{ $t('robot.yitianjjia') }}<span style="font-weight: 600"> {{spotFullCount}}</span>，{{ $t('robot.haiketianjia') }}<span style="color:#AB54DB;font-weight: 600"> {{spotFullUsable}}</span></span>-->
<!--            <span v-else style="display:inline-block;position:absolute;right:0;color: #1E2329;font-size: 14px;word-break: break-word;">{{ $t('robot.yitianjjia') }}<span style="font-weight: 600"> {{spotFullCount}} </span>，{{ $t('robot.haiketianjia') }}<span style="color: #AB54DB;font-weight: 600"> {{ spotFullUsable }} </span></span>-->
          </div>
          <div class="trade_z" @click="openAddDialog4('')">
            <img src="../../assets/img/robot/robot_strategy/strategy_contract.svg">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.grid_contract_strategy') }}</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{ $t('robot.contract_strategy_tip') }}</span>
            </div>
            <span class="trade_add_y">+</span>
          </div>
<!--          <div v-if="robotType == 1" class="trade_z" @click="openAddDialog4('trialVersion')">-->
<!--            <img src="../../assets/img/robot/robot_strategy/strategy_contract.svg">-->
<!--            <div class="trade">-->
<!--              <span style="color: #1E2329">{{ $t('robot.grid_contract_strategy') }}（体验版）</span>-->
<!--              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">合约多空对冲，小资金开仓体验</span>-->
<!--            </div>-->
<!--            <span class="trade_add_y">+</span>-->
<!--            <img style="position: absolute; top: 0; right: 0;" src="../../assets/img/robot/robot_strategy/trial_version_icon.png">-->
<!--          </div>-->
          <div class="trade_z" @click="openAddDialog4('speedVersion')">
            <img src="../../assets/img/robot/robot_strategy/strategy_contract.svg">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.grid_contract_strategy') }}（闪电版）</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{ $t('robot.futures_tip1_3') }}</span>
            </div>
            <span class="trade_add_y">+</span>
            <img style="position: absolute; top: 0; right: 0;" src="../../assets/img/robot/robot_strategy/speed_icon1.png">
          </div>
          <div class="trade_z" @click="openAddDialog4('singleVersion')">
            <img src="../../assets/img/robot/robot_strategy/strategy_contract.svg">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.grid_contract_strategy') }}（单向版）</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{ $t('robot.futures_tip1_3') }}</span>
            </div>
            <span class="trade_add_y">+</span>
            <img style="position: absolute; top: 0; right: 0;" src="../../assets/img/robot/robot_strategy/single_icon.png">
          </div>
<!--          <div class="trade_z" @click="openAddDialog1()">-->
<!--            <img src="../../assets/img/robot/robot_strategy/triangle.svg">-->
<!--            <div class="trade">-->
<!--              <div>-->
<!--                <span style="display: inline-block;vertical-align: middle;color: #1E2329">{{ $t('robot.golden_triangle_arbitrage') }}</span>-->
<!--                <img style="display: inline-block;margin:0 0 0 5px;width: 60px;vertical-align: middle" src="../../assets/img/robot/robot_strategy/exclusive.svg">-->
<!--              </div>-->
<!--              <span style="color:#72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{ $t('robot.golden_triangle_arbitrage_tip') }}</span>-->
<!--            </div>-->
<!--            <span class="trade_add_y">+</span>-->
<!--          </div>-->
<!--          <div class="trade_z" @click="openAddDialog3()">-->
<!--            <img src="../../assets/img/robot/robot_strategy/strategy_ub.svg">-->
<!--            <div class="trade">-->
<!--              <span style="color: #1E2329">{{ $t('robot.ub_trade') }}</span>-->
<!--              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{ $t('robot.ub_trade_tip') }}</span>-->
<!--            </div>-->
<!--            <span class="trade_add_y">+</span>-->
<!--          </div>-->
          <div style="position:relative;vertical-align: middle;font-size: 14px;font-family: PingFangTC-Regular, PingFangTC;font-weight: 400;color: #72777E;margin-top: 20px;display: flex; align-items: center">{{ $t('robot.Spot_product') }}
            <tooltip :content="$t('robot.sime_robot_tip')" :width="'300px'" :imgWidth="'16px'"></tooltip>
<!--            <span v-if="isMobile" style="display:inline-block;position:absolute;right:0;top: 0px;font-size: 14px">{{ $t('robot.yitianjjia') }}<span style="font-weight: 600"> {{swapCount}}</span>，{{ $t('robot.haiketianjia') }}<span style="color:#AB54DB;font-weight: 600"> {{swapUsable}}</span></span>-->
<!--            <span v-else style="display:inline-block;position:absolute;right:0;color: #1E2329;font-size: 14px;word-break: break-word;">{{ $t('robot.yitianjjia') }}<span style="font-weight: 600"> {{swapCount}} </span>，{{ $t('robot.haiketianjia') }}<span style="color: #AB54DB;font-weight: 600"> {{ swapUsable }} </span></span>-->
          </div>
          <div class="trade_z" @click="openAddDialog2()">
            <img src="../../assets/img/robot/robot_strategy/strategy_bb_purple.png">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.bb_trade') }}</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{ $t('robot.bb_trade_tip') }}</span>
            </div>
            <span class="trade_add_b">+</span>
          </div>
          <div class="trade_z" @click="openAddDialogGrid">
            <img src="../../assets/img/robot/robot_strategy/strategy_mw.svg">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.grid_strategy') }}</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{ $t('robot.grid_strategy_tip') }}</span>
            </div>
            <span class="trade_add_b">+</span>
          </div>
<!--          v-if="testUser == 'true'"-->
          <div class="trade_z" @click="openAddDialogMartin">
            <img src="../../assets/img/robot/robot_strategy/strategy_martin.svg">
            <div class="trade">
              <span style="color: #1E2329">{{ $t('robot.martin_strategy') }}</span>
              <span style="color: #72777E;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{ $t('robot.martin_strategy_tip') }}</span>
            </div>
            <span class="trade_add_b">+</span>
          </div>
<!--          <div class="trade_z" @click="openAddDialogContract">-->
<!--            <img src="../../assets/img/robot/robot_strategy/strategy_contract_grey.svg">-->
<!--            <div class="trade">-->
<!--              <span>合约策略</span>-->
<!--              <span style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">开发中，敬请期待…</span>-->
<!--            </div>-->
<!--            <span class="trade_add_b" style="background-color: #72777E;box-shadow: 0px 2px 8px 0px rgba(119, 119, 119, 0.15), 0px 2px 4px 0px rgba(106, 106, 106, 0.3);">+</span>-->
<!--          </div>-->
        </div>

        <!-- 策略内容 -->
        <div v-show="!isMobile" class="celue_div" style="height: 100%">
          <div style="margin-top: -6px;border-bottom: 1px #EAECEF solid; position:relative;">
            <el-tabs style="display: inline-block;vertical-align: middle" v-model="switchValue" @tab-click="switchCelue">
              <el-tab-pane :label="$t('robot.current_policy')" name="1"></el-tab-pane>
              <el-tab-pane :label="$t('robot.historical_policy')" name="2"></el-tab-pane>
            </el-tabs>
            <img class="list_img" id="listImg1" style="display: inline-block;cursor: pointer" @click="changeListImg(changes)" :src="changeImage">
            <div v-if="switchValue == '1'" style="position: absolute; right: 0;top: 8px">
              <span v-if="showCheck == false && robotInfo.state != 2" style="color: #AB54DB;font-size: 14px;cursor: pointer" @click="showCheck = true">{{ $t('robot.batch_open') }}</span>
              <div v-else-if="showCheck == true && robotInfo.state != 2" style="cursor: pointer">
                <el-checkbox  :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllChange">{{ $t('robot.select_all') }}</el-checkbox>
                <span style="color: #AB54DB;font-size: 14px;margin-left: 20px; border-radius: 5px; border: #AB54DB 1px solid;padding: 5px 10px" @click="startAutoStrategy()">{{ $t('robot.start') }}</span>
                <span style="color: #AB54DB;font-size: 14px;margin-left: 20px;" @click="cancelCheck()">{{ $t('robot.cancel') }}</span>
              </div>
            </div>
<!--            <el-select class="celue-selelct" v-model="selectValue" style="position:absolute;width: 200px;right: 0;top: -5px;text-align: right" filterable placeholder="请选择" ref="selectblur">-->
<!--              <el-option :value="selectValue" :label="selectValues" :key="selectValues" style="height: auto; padding: 12px;background: white;">-->
<!--                <el-tree-->
<!--                    ref="tree"-->
<!--                    :data="celueTree"-->
<!--                    :default-expand-all="true"-->
<!--                    :expand-on-click-node="false"-->
<!--                    node-key="id"-->
<!--                    :accordion="false"-->
<!--                    :props="defaultProps"-->
<!--                    @node-click="handleNodeClick"/>-->
<!--              </el-option>-->
<!--            </el-select>-->
          </div>

          <div v-loading="strategyLoading" id="history_scroll" style="height: 89%; overflow-y: auto;padding-right: 20px;position: relative">
            <div v-if="changes == 0" class="celue_list" style="position:relative;" v-for="(item, i) in robotType == 0?strategyList_bn:strategyList_ok">
              <el-checkbox v-if="showCheck" :disabled="item.isNotChecked" style="display: inline-block;vertical-align:top;width:2.6%;margin-top: 3px" v-model="item.checked" @change="checkStrategyChange(i, item.id, item.product_type)" :title="item.strategyType != 0?'仅支持黄金三角交易': ''"></el-checkbox>
              <div style="display: inline-block;" :class="[(((item.strategyType == 3 || item.strategyType == 4) && item.state==1) || robotInfo.state==2 || (item.strategyType == 0 && item.state == 2))?'set_gray':'', showCheck?'contentWidth2':'contentWidth1']">
                <div class="" style="cursor: pointer" @click="showDetails(item)">
                  <img v-if="item.strategyType == 0 && (robotInfo.state != 2 && item.state != 2)" src="../../assets/img/robot/robot_strategy/triangle.svg">
                  <img v-if="item.strategyType == 0 && (robotInfo.state == 2 || item.state == 2)" src="../../assets/img/robot/robot_strategy/triangle_grey.svg">
                  <img v-else-if="item.strategyType == 1 && robotInfo.state != 2" src="../../assets/img/robot/robot_strategy/strategy_bb_purple.png">
                  <img v-else-if="item.strategyType == 1 && robotInfo.state == 2" src="../../assets/img/robot/robot_strategy/strategy_bb_grey.svg">
                  <img v-else-if="item.strategyType == 2 && robotInfo.state != 2" src="../../assets/img/robot/robot_strategy/strategy_ub.svg">
                  <img v-else-if="item.strategyType == 2 && robotInfo.state == 2" src="../../assets/img/robot/robot_strategy/strategy_ub_grey.svg">
                  <img v-else-if="item.product_type == 'hedge' && robotInfo.state != 2" src="../../assets/img/robot/robot_strategy/strategy_contract.svg">
                  <img v-else-if="item.product_type == 'hedge' && robotInfo.state == 2" src="../../assets/img/robot/robot_strategy/strategy_contract_grey.svg">
                  <img v-if="item.strategyType == 3 && robotInfo.state != 2 && (item.state == 0||item.state == 3||item.state == 2)" src="../../assets/img/robot/robot_strategy/strategy_mw.svg">
                  <img v-else-if="item.strategyType == 3 && (robotInfo.state == 2 || item.state == 1)" src="../../assets/img/robot/robot_strategy/strategy_mw_grey.svg">
                  <img v-if="item.strategyType == 4 && robotInfo.state != 2 && (item.state == 0||item.state == 3||item.state == 2)" src="../../assets/img/robot/robot_strategy/strategy_martin.svg">
                  <img v-else-if="item.strategyType == 4 && (robotInfo.state == 2 || item.state == 1)" src="../../assets/img/robot/robot_strategy/strategy_martin_grey.svg">
                  <img v-else-if="item.product_type == 'Term' && robotInfo.state != 2" src="../../assets/img/robot/robot_strategy/strategy_term.png">
                  <img v-else-if="item.product_type == 'Term' && robotInfo.state == 2" src="../../assets/img/robot/robot_strategy/strategy_term_grey.png">

                  <span v-if="item.strategyType == 0" class="celue_title" style="margin-left: 7px">{{ item.coin_one }} <span style="font-size: 18px;color:#52C41A">⇀</span> {{ item.coin_two }} <span style="font-size: 18px; cursor: pointer;color:#52C41A">⇀</span> {{ item.coin_three }}</span>
                  <span v-else-if="item.strategyType == 1" class="celue_title" style="margin-left: 7px">{{ item.coin_one }} <div style="display:inline-block;vertical-align:middle"><span style="font-size: 18px;display: block;color:#52C41A">⇀</span><span v-show="item.space_two != 0" style="display: block;color:#FF4D4F;font-size: 18px;transform: scale(-1.0);height: 5px;">⇀</span></div> {{ item.coin_two }}</span>
                  <span v-else-if="item.strategyType == 2" class="celue_title" style="margin-left: 7px">{{ item.coin_one }} <span style="font-size: 18px;color: #52C41A">⇀</span> {{ item.coin_two }}</span>
                  <span v-else-if="item.product_type == 'hedge' && item.future_type == 2" class="celue_title" style="margin-left: 7px">{{ item.coin }}{{ item.coin_two }} {{item.robotType==0?'CM':''}} 永续 10X <span v-if="item.version=='oneWay'" style="margin-left: 2px; font-size: 15px; font-weight: 400;" :style="{color: item.direction==1?'#2CC197':'rgba(220, 77, 77,1)'}">{{item.direction==1?'开多':'开空'}}</span></span>
                  <span v-else-if="item.product_type == 'hedge' && item.future_type != 2" class="celue_title" style="margin-left: 7px">{{ item.coin }} <span style="font-size: 18px;color: #52C41A">⇀</span> {{ item.coin_two }} 永续 10X <span v-if="item.version=='oneWay'" style="margin-left: 2px; font-size: 15px; font-weight: 400;" :style="{color: item.direction==1?'#2CC197':'rgba(220, 77, 77,1)'}">{{item.direction==1?'开多':'开空'}}</span></span>
                  <span v-else-if="item.strategyType == 3" class="celue_title" style="margin-left: 7px">{{ item.coin_one }} <div v-if="item.after_space != 0" style="display:inline-block;vertical-align:middle"><span style="display:block;font-size: 18px;color: #AB54DB">⇀</span><span v-show="item.front_space != 0" style="display: block;color:#FF4D4F;font-size: 18px;transform: scale(-1.0);height: 5px;">⇀</span></div><span v-if="item.after_space == 0" style="display: inline-block;vertical-align:middle;color:#AB54DB;font-size: 18px;transform: scale(-1.0);">⇀</span> {{ item.coin_two }} </span>
                  <span v-else-if="item.strategyType == 4" class="celue_title" style="margin-left: 7px">{{ item.coin_one }} <span style="font-size: 18px;color: #AB54DB">⇀</span> {{ item.coin_two }} </span>
                  <span v-else-if="item.product_type == 'Term'" class="celue_title" style="margin-left: 7px">{{ item.coin }} <span style="font-size: 18px;color: #AB54DB">⇀</span> {{ item.coin_two }} </span>

                  <span v-if="item.strategyType == 0" style="font-size: 14px;font-weight: 300;margin-left: 16px;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.golden_triangle_arbitrage') }}</span>
                  <span v-if="item.strategyType == 1" style="font-size: 14px;font-weight: 300;margin-left: 16px;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.bb_trade') }}</span>
                  <span v-if="item.strategyType == 2" style="font-size: 14px;font-weight: 300;margin-left: 16px;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.ub_trade') }}</span>
                  <span v-if="item.product_type == 'hedge'" style="font-size: 14px;font-weight: 300;margin-left: 16px;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.grid_contract_strategy') }}{{item.product_type == 'hedge'&&item.version_number!='speed'&&(item.coin_two=='ICP'||item.coin_two=='FITFI')?'':item.product_type == 'hedge'&&item.version_number=='speed'?'（闪电版）':item.product_type == 'hedge'&&item.version=='oneWay'?'（单项版）':''}}</span>
                  <span v-if="item.strategyType == 3" style="font-size: 14px;font-weight: 300;margin-left: 16px;vertical-align: middle">{{ $t('robot.semi') }}：{{ $t('robot.grid_strategy') }}</span>
                  <span v-if="item.strategyType == 4" style="font-size: 14px;font-weight: 300;margin-left: 16px;vertical-align: middle">{{ $t('robot.semi') }}：{{ $t('robot.martin_strategy') }}</span>
                  <span v-if="item.product_type == 'Term'" style="font-size: 14px;font-weight: 300;margin-left: 16px;vertical-align: middle">{{ $t('robot.auto') }}：期现AI交易{{item.old_new==3?'（闪电版）':item.old_new==4?'（BTC 版）':''}}</span>
                  <img style="margin-left: 15px;width: 18px;display:inline-block;vertical-align: middle" src="../../assets/img/robot/robot_strategy/go.svg">
                  <span v-if="(item.strategyType == 0) && item.state == 1 && item.capital == 0 && robotInfo.state != 2 && switchValue != 2" style="display:inline-block;vertical-align:unset;font-size:12px;color: #AB54DB;margin-left: 10px;"><span class="el-icon-refresh-right" style="font-size: 16px;animation: rotating 1s linear infinite;vertical-align: middle"></span>{{ $t('robot.initializing') }}<span  style="margin-left: 5px"  v-if="item.need_one || item.need_two || item.need_three">{{ $t('robot.go_to_exchange_recharge') }}</span><span style="margin-left: 5px" v-if="item.need_one">{{ item.coin_one }}</span><span style="margin-left: 5px" v-if="item.need_two">{{ item.coin_two }}</span><span style="margin-left: 5px" v-if="item.need_three">{{ item.coin_three }}</span></span>
                  <img v-if="(item.product_type == 'hedge' && item.state == 1 && robotInfo.state != 2 && switchValue != 2)" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
                  <img v-if="(item.product_type == 'Term' && item.state == 1 && robotInfo.state != 2 && switchValue != 2)" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
                  <img v-if="item.product_type != 'hedge' && item.product_type != 'Trem' && robotInfo.state != 2 && (((item.strategyType == 0) && item.state == 1 && item.capital != 0) || item.strategyType == 1 || item.strategyType == 2 || item.state == 0) && switchValue != 2" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
                  <span v-else-if="switchValue != 2&&(item.number_of_cycles != 0 && item.number_of_cycles!=item.current_number_of_cycles || item.number_of_cycles == 0)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E">{{ $t('robot.paused') }}</span>
                  <span v-else-if="switchValue != 2 && item.strategyType == 4 &&(item.number_of_cycles != 0 && item.number_of_cycles==item.current_number_of_cycles)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.cycle_completed') }}</span>
                  <span v-else-if="switchValue != 2 && item.strategyType == 4 && (item.stop_replenishment == 2 || item.clearance_sale == 2)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.cycle_stopped') }}</span>
                  <!--                <img v-if="1 != 1" style="width:16px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/robot_strategy/warn_red.svg">-->
                  <!--                <img v-if="1 == 2" style="width:16px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/robot_strategy/warn_orange.svg">-->
                </div>
                <span style="font-size: 40px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: rgba(114, 119, 126, 0.1) !important;position:absolute; right: 0;top: -16px">{{ item.rank }}</span>
                <div v-if="item.product_type != 'hedge' && item.strategyType != 3 && item.strategyType != 4 && item.product_type != 'Term'" class="celue_content" style="margin-top: 3px">
                  <div style="width: 90%;">
                    <span v-if="item.strategyType == 0" style="display: inline-block;width: 53.3%">{{ $t('robot.put_position') }}：<span style="color: #1E2329">{{ item.coin_one }}({{ item.space_one | roundEightFilter }})、{{ item.coin_two }}({{ item.space_two | roundEightFilter }})、{{ item.coin_three }}({{ item.space_three | roundEightFilter }})</span></span>
                    <span v-if="item.strategyType == 1" style="display: inline-block;width: 53.3%">{{ $t('robot.put_position') }}：<span style="color: #1E2329">{{ item.coin_one }}({{ item.space | roundEightFilter }})、{{ item.coin_two }}<span v-show="item.space_two != 0" style="color: #1E2329">({{ item.space_two | roundEightFilter }})</span></span></span>
                    <span v-if="item.strategyType == 2" style="display: inline-block;width: 53.3%">{{ $t('robot.put_position') }}：<span style="color: #1E2329">{{ item.coin_one }}({{ item.space | roundEightFilter }})、{{ item.coin_two }}</span></span>
                    <span style="display: inline-block;width: 40%;margin-top: 5px">{{ $t('robot.create_time') }}：<span style="color: #1E2329">{{ item.add_time }}</span></span>
                  </div>
                  <div v-if="item.strategyType == 1" style="width: 90%; margin-top: 5px">
                    <span style="display: inline-block;width: 53.3%">{{ $t('robot.Total_revenue') }}(USDT)：<span :style="{color: item.profit_total>0?'#52C41A':item.profit_total<0?'#FF4D4F':'#1E2329'}">{{ item.profit_total | roundEightFilter }}</span></span>
                    <span style="display: inline-block;width: 40%">{{ $t('robot.yield') }}：<span :style="{color: item.profit_rate>0?'#52C41A':item.profit_rate<0?'#FF4D4F':'#1E2329'}">{{ item.profit_rate | roundEightFilter }}%</span></span>
                  </div>
<!--                  <div style="position: absolute; right: 0; font-weight: 400;color: #AB54DB;">-->
<!--                    <span style="display: inline-block;margin: 5px 0 0 20px;color: #AB54DB;cursor: pointer;color:#AB54DB !important;" @click="delet(item.strategyType, item)">{{ $t('robot.del') }}</span>-->
<!--                  </div>-->
                  <div v-if="switchValue == 1" style="display: block; text-align: right; margin-top: 5px; font-weight: 400;color: #AB54DB;">
<!--                    <span v-if="(item.strategyType == 0) && item.state == 2 && robotInfo.state != 2" style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-right: 10px" @click="openStrategy(item.strategyType, item)">{{ $t('robot.start') }}</span>-->
                    <span v-if="item.strategyType == 1" style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-right: 20px" @click="addBBPosition(item.strategyType, item)">{{ $t('robot.add_position') }}</span>
                    <span style="display: inline-block;color: #AB54DB !important;cursor: pointer;" @click="delet(item.strategyType, item)">{{ $t('robot.del') }}</span>
                  </div>
                </div>

                <div v-else-if="item.strategyType == 3" class="celue_content" style="margin-top: 4px">
                  <div style="width: 25%;vertical-align: top">
                    <span style="display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ $t('robot.input_amount') }}：<span v-if="item.after_space" style="color: #1E2329">{{ item.after_space | roundEightFilter }}({{ item.coin_one }})</span><span v-if="item.front_space" style="color: #1E2329">、{{ item.front_space | roundEightFilter }}({{ item.coin_two }})</span></span>
                    <span style="display: block;margin-top: 5px;max-width: 201px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis">{{ $t('robot.create_time') }}：<span style="color: #1E2329;">{{ item.add_time }}</span></span>
                  </div>
                  <div style="width: 25%;">
                    <span style="display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ $t('robot.Price_range') }}({{ item.coin_one }})：<span style="color:#1E2329;">{{ item.floor_price | roundEightFilter}} {{ item.floor_price?'~':''}} {{ item.ceiling_price | roundEightFilter}} </span></span>
                    <span style="display: block;margin-top: 5px">{{ $t('robot.number_of_grids') }}：<span style="color: #1E2329">{{ item.grid_number }}</span></span>
                  </div>
                  <div style="width: 25%;">
                    <span style="display: block;">{{ $t('robot.Total_revenue') }}({{ item.coin_one }})：<span :style="{color: item.profit_total>0?'#52C41A':item.profit_total<0?'#FF4D4F':'#1E2329'}">{{ item.profit_total | roundEightFilter }}</span></span>
                    <span style="display: block;margin-top: 5px;">{{ $t('robot.yield') }}：<span :style="{color: item.profit_rate>0?'#52C41A':item.profit_rate<0?'#FF4D4F':'#1E2329'}">{{ item.profit_rate | roundEightFilter }}%</span></span>
                  </div>
                  <div style="width: 25%;vertical-align: top">
                    <span style="display: block">{{ $t('robot.Arbitrage_times') }}：<span style="color: #1E2329">{{ item.profit_times }}</span></span>
                    <!--                  <span v-if="switchValue == 1" style="display: block;margin-top: 5px">止盈止損(U)：<span style="color: #1E2329">200</span></span>-->
                    <span v-if="switchValue == 2" style="display: block;margin-top: 5px">{{ $t('robot.Manually_stopped') }}</span>
                  </div>
<!--                  <div style="position: absolute; right: 0; top: 0; font-weight: 400;color: #AB54DB;">-->
<!--                    <span v-if="switchValue == 1 && item.state == 0" style="display: block;color: #AB54DB !important;cursor: pointer" @click="startAndStopThis(item, 2)">{{ $t('robot.pause') }}</span>-->
<!--                    <span v-if="switchValue == 1 && item.state == 1" style="display: block;color: #52C41A !important;cursor: pointer" @click="startAndStopThis(item, 1)">{{ $t('robot.start') }}</span>-->
<!--                    <span v-if="switchValue != '2'" style="display: block;margin-top: 5px;color:#AB54DB !important;cursor: pointer" @click="delet(item.strategyType, item)">{{ $t('robot.del') }}</span>-->
<!--                  </div>-->
                  <div style="display: block; text-align: right; margin-top: 5px; font-weight: 400;color: #AB54DB;">
                    <span v-if="switchValue == 1 && item.state == 0" style="display: inline-block;color: #AB54DB !important;cursor: pointer;" @click="startAndStopThis(item, 2)">{{ $t('robot.pause') }}</span>
                    <span v-if="switchValue == 1 && item.state == 1" style="display: inline-block;color: #AB54DB !important;cursor: pointer;" @click="startAndStopThis(item, 1)">{{ $t('robot.start') }}</span>
                    <span v-if="switchValue != '2'" style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-left: 20px;" @click="delet(item.strategyType, item)">{{ $t('robot.del') }}</span>
                  </div>
                </div>
                <div v-else-if="item.strategyType == 4" class="celue_content" style="margin-top: 4px">
                  <div style="width: 25%;vertical-align: top">
                    <span style="display: block">{{ $t('robot.input_amount') }}(U)：<span style="color: #1E2329">{{ item.fund | roundEightFilter }}</span></span>
                    <span style="display: block;margin-top: 5px;max-width: 201px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis">{{ $t('robot.average_position_price') }}：<span style="color: #1E2329;">{{ item.average_price | roundEightFilter }}</span></span>
                  </div>
                  <div style="width: 25%;">
                    <span style="display: block">{{ $t('robot.number_of_cycles') }}：<span style="color:#1E2329;">{{ item.current_number_of_cycles_new }}{{ $t('robot.ci') }}</span></span>
                    <span style="display: block;margin-top: 5px">{{ $t('robot.replenishment_depth') }}：<span style="color: #1E2329">{{ item.current_order_no==0?$t('robot.not_yet'):item.current_order_no==1?$t('robot.first_order'):$t('robot.di')+item.current_order_no+$t('robot.order') }}</span></span>
                  </div>
                  <div style="width: 25%;">
                    <span style="display: block;">{{ $t('robot.floating_profit_loss') }}(U)：<span :style="{color: item.profit_loss>0?'#52C41A':item.profit_loss<0?'#FF4D4F':'#1E2329'}">{{ item.profit_loss | roundEightFilter }}</span></span>
                    <span style="display: block;margin-top: 5px;">{{ $t('robot.yield') }}：<span :style="{color: item.profit_rate>0?'#52C41A':item.profit_rate<0?'#FF4D4F':'#1E2329'}">{{ item.profit_rate | roundEightFilter }}%</span></span>
                  </div>
                  <div style="width: 25%;vertical-align: top">
                    <span style="display: block">{{ $t('robot.current_price') }}：<span style="color: #1E2329">{{ item.current_price | roundEightFilter }}</span></span>
                    <span style="display: block;margin-top: 5px;max-width: 201px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis">{{ $t('robot.create_time') }}：<span style="color: #1E2329;">{{ item.add_time }}</span></span>
                    <!--                  <span v-if="switchValue == 1" style="display: block;margin-top: 5px">止盈止損(U)：<span style="color: #1E2329">200</span></span>-->
<!--                    <span v-if="switchValue == 2" style="display: block;margin-top: 5px">已手動停止</span>-->
                  </div>
<!--                  <div style="position: absolute; right: 0; top: 0; font-weight: 400;color: #AB54DB;">-->
<!--&lt;!&ndash;                    <span v-if="switchValue == 1 && item.state == 0" style="display: block;color: #AB54DB !important;cursor: pointer" @click="startAndStopThis(item, 2)">暫停</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span v-if="switchValue == 1 && item.state == 1" style="display: block;color: #52C41A !important;cursor: pointer" @click="startAndStopThis(item, 1)">開啓</span>&ndash;&gt;-->

<!--                    <span v-if="switchValue != '2'" style="display: block;margin-top: 5px;color:#AB54DB !important;cursor: pointer" @click="delet(item.strategyType, item)">{{ $t('robot.stop') }}</span>-->
<!--                    <span v-if="switchValue == '2'" style="display: block;margin-top: 5px;color:#AB54DB !important;cursor: pointer" @click="Restart(item.strategyType, item)">{{ $t('robot.restart_strategy') }}</span>-->
<!--                  </div>-->

                  <div style="display: block; text-align: right; margin-top: 5px; font-weight: 400;color: #AB54DB;">
                    <span v-if="switchValue != '2'" style="display: inline-block;color: #AB54DB !important;cursor: pointer;" @click="delet(item.strategyType, item)">{{ $t('robot.stop') }}</span>
                    <span v-if="switchValue == '2'" style="display: inline-block;color: #AB54DB !important;cursor: pointer;" @click="Restart(item.strategyType, item)">{{ $t('robot.restart_strategy') }}</span>
                  </div>
                </div>
                <div v-if="item.product_type == 'hedge'" class="celue_content" style="margin-top: 4px">
                  <div style="width: 90%; display: flex; justify-content: space-between">
                    <span style="display: inline-block; width: 33%">{{ $t('robot.input_amount') }}{{item.future_type==2?'':'U'}}：<span style="color: #1E2329">{{ item.space }} {{item.future_type==2?item.coin:''}}</span></span>
                    <span style="display: inline-block; width: 33%">{{ $t('robot.Total_revenue') }}：<span style="color: #1E2329" :style="{color: item.profit_total>0?'#52C41A':item.profit_total<0?'#FF4D4F':'#1E2329'}">{{ item.profit_total }} {{item.future_type==2?item.coin:'U'}}</span></span>
<!--                    <span style="display: inline-block;width: 33%">{{$t('robot.loss')}}：<span style="color: #1E2329">{{item.stop_loss != 0?(item.stop_loss*100).toFixed(2)+'%': $t('robot.not_stop_loss')}}</span></span>-->
                    <span style="display: inline-block;width: 33%">{{$t('robot.loss')}}：<span style="color: #1E2329">{{item.stop_loss == 100?$t('robot.loss'):item.stop_loss==5?'部分止损':$t('robot.not_stop_loss')}}</span></span>
                  </div>
                  <div style="width: 90%;display: flex; justify-content: space-between; margin-top: 5px">
                    <span style="display: inline-block; width: 33%">{{ $t('robot.create_time') }}：<span style="color: #1E2329">{{ item.add_time }}</span></span>
                    <span style="display: inline-block; width: 33%">{{ $t('robot.yield') }}：<span style="color: #1E2329":style="{color: item.profit_rate>0?'#52C41A':item.profit_rate<0?'#FF4D4F':'#1E2329'}">{{ item.profit_rate?item.profit_rate:'0.00' }}%</span></span>
<!--                    <span style="display: inline-block; width: 33%">{{ $t('robot.interference') }}：<span style="color: #1E2329">{{item.stop_surplus != 0?(item.stop_surplus*100).toFixed(2)+'%': $t('robot.not_stop_surplus')}}</span></span>-->
                    <span style="display: inline-block; width: 33%; height: 19px"></span>
                  </div>
                  <div v-if="switchValue == 1" style="display: block; text-align: right; margin-top: 5px; font-weight: 400;color: #AB54DB;">
                    <span style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-right: 10px" @click="changeStrategy(item)">{{ $t('robot.add_position') }}</span>
                    <span v-if="item.state == 2" style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-right: 10px" @click="startHedge(item, item.strategyType)">{{ $t('robot.start') }}</span>
                    <span v-if="item.state == 1" style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-right: 10px" @click="pauseHedge(item, item.strategyType)">{{ $t('robot.pause') }}</span>
                    <span style="display: inline-block;color: #AB54DB !important;cursor: pointer;" @click="delet(5, item)">{{ $t('robot.del') }}</span>
                  </div>
                </div>
                <div v-if="item.product_type == 'Term'" class="celue_content" style="margin-top: 4px">
                  <div style="width: 90%; display: flex; justify-content: space-between">
                    <span style="display: inline-block; width: 33%">{{ $t('robot.input_amount') }}U：<span style="color: #1E2329">{{ item.totalSpace }}</span></span>
                    <span style="display: inline-block; width: 33%">{{ $t('robot.Total_revenue') }}：<span style="color: #1E2329" :style="{color: item.profit_total>0?'#52C41A':item.profit_total<0?'#FF4D4F':'#1E2329'}">{{ item.profit_total }}</span></span>
                  </div>
                  <div style="width: 90%;display: flex; justify-content: space-between; margin-top: 5px">
                    <span style="display: inline-block; width: 33%">{{ $t('robot.create_time') }}：<span style="color: #1E2329">{{ item.add_time }}</span></span>
                    <span style="display: inline-block; width: 33%">{{ $t('robot.yield') }}：<span style="color: #1E2329":style="{color: item.profit_rate>0?'#52C41A':item.profit_rate<0?'#FF4D4F':'#1E2329'}">{{ item.profit_rate?item.profit_rate:'0.00' }}%</span></span>
                  </div>
                  <div v-if="switchValue == 1" style="display: block; text-align: right; margin-top: 5px; font-weight: 400;color: #AB54DB;">
                    <span style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-right: 10px" @click="changeTermStrategy(item)">{{ $t('robot.add_position') }}</span>
<!--                    <span v-if="item.state == 2" style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-right: 10px" @click="startHedge(item, item.strategyType)">{{ $t('robot.start') }}</span>-->
<!--                    <span v-if="item.state == 1" style="display: inline-block;color: #AB54DB !important;cursor: pointer;margin-right: 10px" @click="pauseHedge(item, item.strategyType)">{{ $t('robot.pause') }}</span>-->
                    <span style="display: inline-block;color: #AB54DB !important;cursor: pointer;" @click="delet(6, item)">{{ $t('robot.del') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <span v-if="strategyLoading == false && ((robotType == 0 && strategyList_bn.length == 0) || (robotType == 1&&strategyList_ok.length == 0))" style="display:block;text-align: center;vertical-align: middle;line-height: 360px">{{ $t('robot.no_data') }}</span>
            <!-- pc簡版 -->
            <div v-if="changes == 1" class="celue_list concise_list" style="position:relative;cursor: pointer;margin-top: 6px;padding-bottom: 6px" v-for="(item, i) in robotType == 0?strategyList_bn:strategyList_ok">
              <el-checkbox v-if="showCheck" :disabled="item.isNotChecked" style="display: inline-block;vertical-align:top;width:2%;margin-top: 3px" v-model="item.checked" @change="checkStrategyChange(i, item.id, item.product_type)"></el-checkbox>
              <div style="display: inline-block" @click="showDetails(item)">
                <span v-if="item.strategyType == 0" style="display:inline-block;color: #1E2329;margin-top: 5px">{{ item.coin_one }}({{ item.space_one | roundEightFilter }})<span style="color: #52C41A;margin:0 3px">⇀</span>{{ item.coin_two }}({{ item.space_two | roundEightFilter }})<span style="color: #52C41A;margin:0 3px">⇀</span>{{ item.coin_three }}({{ item.space_three | roundEightFilter }})</span>
                <span v-if="item.strategyType == 1" style="display:inline-block;color: #1E2329;margin-top: 5px">{{ item.coin_one }}({{ item.space | roundEightFilter }})<div style="display: inline-block;vertical-align: middle;margin-top: -6px;"><span style="color: #52C41A;margin: 0 3px">⇀</span><span v-show="item.space_two != 0"style="color: #FF4D4F;display: block;line-height:0; padding: 3px 0px 0px 0px; margin:0 3px; transform: scale(-1.0);font-size: 14px">⇀</span></div>{{ item.coin_two }}<span v-show="item.space_two != 0" style="color: #1E2329">({{ item.space_two | roundEightFilter }})</span></span>
                <span v-if="item.strategyType == 2" style="display:inline-block;color: #1E2329;margin-top: 5px">{{ item.coin_one }}({{ item.space | roundEightFilter }})<span style="color: #52C41A;margin:0 3px">⇀</span>{{item.coin_two}}</span>


                <span v-if="item.product_type == 'hedge' && item.future_type == 2" style="display:inline-block;color: #1E2329;margin-top: 5px">{{ item.coin }}{{item.coin_two}} ({{ item.space | roundEightFilter }}) 永续10X<span v-if="item.version=='oneWay'" style="font-size: 14px;" :style="{color: item.direction==1?'#2CC197':'rgba(220, 77, 77,1)'}">{{item.direction==1?'开多':'开空'}}</span></span>


                <span v-if="item.product_type == 'hedge' && item.future_type != 2" style="display:inline-block;color: #1E2329;margin-top: 5px">{{ item.coin }}({{ item.space | roundEightFilter }})<span style="color: #52C41A;margin:0 3px">⇀</span>{{item.coin_two}} 永续10X<span v-if="item.version=='oneWay'" style="font-size: 14px;" :style="{color: item.direction==1?'#2CC197':'rgba(220, 77, 77,1)'}">{{item.direction==1?'开多':'开空'}}</span></span>
                <span v-if="item.product_type == 'hedge'" style="display:inline-block;color: #1E2329;margin-top: 5px;color: #72777E">{{item.product_type == 'hedge'&&item.version_number!='speed'&&item.coin_two=='ICP'?'':item.product_type == 'hedge'&&item.version_number=='speed'?'(闪电版)':item.product_type == 'hedge'&&item.version=='oneWay'?'(单向版)':''}}</span>
                <span v-if="item.strategyType == 3" style="display:inline-block;color: #1E2329;margin-top: 5px">{{ item.coin_one }}({{item.after_space | roundEightFilter}})<div v-if="item.after_space != 0" style="display:inline-block;vertical-align:middle;margin-top: -6px"><span style="display:block;margin: 0 3px;color: #AB54DB">⇀</span><span v-show="item.front_space != 0" style="display: block;color:#FF4D4F;padding: 3px 0px 0px 0px; margin:0 3px;transform: scale(-1.0);line-height: 0;">⇀</span></div><span v-if="item.after_space == 0" style="display: inline-block;color:#AB54DB; margin:0 3px;transform: scale(-1.0);">⇀</span>{{ item.coin_two }}{{ item.front_space?'('+ $options.filters['roundEightFilter'](item.front_space)  +')':'' }}</span>
                <span v-if="item.strategyType == 4" style="display:inline-block;color: #1E2329;margin-top: 5px">{{ item.coin_one }}({{item.fund | roundEightFilter}})<span style="color: #AB54DB;margin:0 3px">⇀</span>{{ item.coin_two }}</span>
                <span v-if="item.product_type == 'Term'" style="display:inline-block;color: #1E2329;margin-top: 5px">{{ item.coin }}({{ item.totalSpace | roundEightFilter }})<span style="color: #52C41A;margin:0 3px">⇀</span>{{item.coin_two}}</span>
                <span v-if="item.product_type == 'Term' && item.old_new==3" style="display:inline-block;color: #1E2329;margin-top: 5px;color: #72777E">(闪电版)</span>
                <span v-if="item.product_type == 'Term' && item.old_new==4" style="display:inline-block;color: #1E2329;margin-top: 5px;color: #72777E">(BTC 版)</span>

                <span v-if="item.strategyType == 0" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.sj') }}</span>
                <span v-if="item.strategyType == 1" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.bb_trade') }}</span>
                <span v-if="item.strategyType == 2" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.ub_trade') }}</span>
                <span v-if="item.product_type == 'hedge'" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.grid_contract_strategy') }}</span>
                <span v-if="item.strategyType == 3" style="background-color:#F4F8FF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #AB54DB;font-size: 12px;padding: 2px 6px">{{ $t('robot.grid_strategy') }}</span>
                <span v-if="item.strategyType == 4" style="background-color:#F4F8FF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #AB54DB;font-size: 12px;padding: 2px 6px">{{ $t('robot.martin_strategy') }}</span>
                <span v-if="item.product_type == 'Term'" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.futures_spot_strategy') }}</span>

                <span v-if="(item.strategyType == 0) && item.state == 1 && item.capital == 0 && robotInfo.state != 2 && switchValue != 2" style="display:inline-block;vertical-align:unset;font-size:12px;color: #AB54DB;margin-left: 10px"><span class="el-icon-refresh-right" style="color: #AB54DB;font-size: 16px;animation: rotating 1s linear infinite;vertical-align: top"></span>{{ $t('robot.initializing') }}<span style="margin-left: 5px;color: #AB54DB;font-size:12px;" v-if="item.need_one || item.need_two || item.need_three">{{ $t('robot.go_to_exchange_recharge') }}</span><span style="margin-left: 5px;color: #AB54DB;font-size:12px;" v-if="item.need_one">{{ item.coin_one }}</span><span style="margin-left: 5px;color: #AB54DB;font-size:12px;" v-if="item.need_two">{{ item.coin_two }}</span><span style="margin-left: 5px;color: #AB54DB;font-size:12px;" v-if="item.need_three">{{ item.coin_three }}</span></span>
                <img v-if="(item.product_type == 'hedge' && item.state == 1 && robotInfo.state != 2 && switchValue != 2)" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
                <img v-if="(item.product_type == 'Term' && item.state == 1 && robotInfo.state != 2 && switchValue != 2)" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
                <img v-if="item.product_type != 'hedge' && item.product_type != 'Term' && robotInfo.state != 2 && (((item.strategyType == 0) && item.state == 1 && item.capital != 0) || item.strategyType == 1 || item.strategyType == 2 || item.state == 0) && switchValue != 2" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
                <span v-else-if="switchValue != 2&&(item.number_of_cycles != 0 && item.number_of_cycles!=item.current_number_of_cycles || item.number_of_cycles == 0)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E">{{ $t('robot.paused') }}</span>
                <span v-else-if="switchValue != 2 && item.strategyType == 4 &&(item.number_of_cycles != 0 && item.number_of_cycles==item.current_number_of_cycles)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.cycle_completed') }}</span>
                <span v-else-if="switchValue != 2 && item.strategyType == 4 && (item.stop_replenishment == 2 || item.clearance_sale == 2)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.cycle_stopped') }}</span>
              </div>
              <div style="position: absolute;right: 0; font-size: 14px; color: #AB54DB; font-weight: 400;top: 0">
<!--                <span v-if="(item.strategyType==0) && item.state == 2 && robotInfo.state != 2 && switchValue != 2" style="font-weight: 400;color:#AB54DB" @click="openStrategy(item.strategyType, item)">{{ $t('robot.start') }}</span>-->
                <span v-if="(item.strategyType==1) && switchValue != '2'" style="font-weight: 400;color: #AB54DB;cursor: pointer;" @click="addBBPosition(item.strategyType, item)">{{ $t('robot.add_position') }}</span>
                <span v-if="(item.strategyType==3) && item.state == 0" style="font-weight: 400;color: #AB54DB;cursor: pointer;" @click="startAndStopThis(item, 2)">{{ $t('robot.pause') }}</span>
                <span v-if="(item.strategyType==3) && item.state == 1" style="color:#52C41A;font-weight: 400;cursor: pointer;" @click="startAndStopThis(item, 1)">{{ $t('robot.start') }}</span>
                <span v-if="(item.product_type == 'hedge') && item.state == 1" style="font-weight: 400;color: #AB54DB;cursor: pointer;" @click="pauseHedge(item, 2)">{{ $t('robot.pause') }}</span>
                <span v-if="(item.product_type == 'hedge') && item.state == 2" style="color:#52C41A;font-weight: 400;cursor: pointer;" @click="startHedge(item, 1)">{{ $t('robot.start') }}</span>
                <span v-if="(item.strategyType!=4) && switchValue != '2'" style="font-weight: 400;color: #AB54DB;cursor: pointer;z-index: 1000;" @click="delet(item.strategyType, item)">{{ $t('robot.del') }}</span>
                <span v-if="(item.strategyType==4) && switchValue != '2'" style="font-weight: 400;color: #AB54DB;cursor: pointer;z-index: 1000;" @click="delet(item.strategyType, item)">{{ $t('robot.stop') }}</span>
                <span v-if="(item.strategyType==4) && switchValue == '2'" style="font-weight: 400;color: #AB54DB;cursor: pointer;z-index: 1000;" @click="Restart(item.strategyType, item)">{{ $t('robot.restart') }}</span>
<!--                <span v-if="(item.product_type == 'Term')" style="font-weight: 400;color: #AB54DB;cursor: pointer;" @click="changeTermStrategy(item)">{{ $t('robot.add_position') }}</span>-->
<!--                <span v-if="item.product_type == 'Term' && switchValue != '2'" style="font-weight: 400;color: #AB54DB;cursor: pointer;z-index: 1000;" @click="delet(6, item)">{{ $t('robot.del') }}</span>-->
              </div>
            </div>
          </div>
          <span style="position:absolute;bottom: -6px;font-size:14px; color: #000613; line-height: 22px;">{{ $t('robot.total') }} <span>{{ totalRow }}</span> {{ $t('robot.strip') }}</span>
          <el-pagination
              v-if="switchValue == '2'"
              style="position:absolute;right:0;bottom: -26px;"
              layout="prev, pager, next"
              @current-change="changePage"
              :page-size="10"
              :current-page="pageNum"
              :hide-on-single-page="capitalListTotal<=0"
              :total="capitalListTotal">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-card v-if="isMobile" style="margin-top: 12px">
      <div class="celue_div" style="margin-top: -10px !important;">
        <div style="border-bottom: 1px #EAECEF solid; position:relative;">
          <el-tabs style="display: inline-block;vertical-align: middle" v-model="switchValue" @tab-click="switchCelue">
            <el-tab-pane :label="$t('robot.current_policy')" name="1"></el-tab-pane>
            <el-tab-pane :label="$t('robot.historical_policy')" name="2"></el-tab-pane>
          </el-tabs>
          <img class="list_img" id="listImg2" style="display: inline-block;cursor: pointer" @click="changeListImg(changes)" :src="changeImage">
          <div v-if="switchValue == '1'" style="position: absolute; right: 0;top: 10px">
            <span v-if="showCheck == false && robotInfo.state != 2" style="color: #AB54DB;font-size: 14px;cursor: pointer" @click="showCheck = true">{{ $t('robot.batch_open') }}</span>
            <div v-else-if="showCheck == true && robotInfo.state != 2" style="cursor: pointer">
              <el-checkbox  :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllChange">{{ $t('robot.select_all') }}</el-checkbox>
              <span style="color: #AB54DB;font-size: 14px;margin-left: 10px; border-radius: 5px; border: #AB54DB 1px solid;padding: 5px 10px" @click="startAutoStrategy()">{{ $t('robot.start') }}</span>
              <span style="color: #AB54DB;font-size: 14px;margin-left: 10px" @click="cancelCheck()">{{ $t('robot.cancel') }}</span>
            </div>
          </div>
<!--          <el-select class="celue-selelct" v-model="selectValue" style="position:absolute;width: 140px;right: 0;top:-1px;text-align: right" filterable placeholder="请选择" ref="selectblur">-->
<!--            <el-option :value="selectValue" :label="selectValues" :key="selectValues" style="height: auto; padding: 12px;background: white;">-->
<!--              <el-tree-->
<!--                  ref="tree"-->
<!--                  :data="celueTree"-->
<!--                  :default-expand-all="true"-->
<!--                  :expand-on-click-node="false"-->
<!--                  node-key="id"-->
<!--                  :accordion="false"-->
<!--                  :props="defaultProps"-->
<!--                  @node-click="handleNodeClick"/>-->
<!--            </el-option>-->
<!--          </el-select>-->
        </div>
        <div v-loading="strategyLoading" style="display: inline-block;position:relative;overflow: unset;width: 100%;padding-bottom: 15px;min-height: 50px">
          <div v-if="changes == 0" class="celue_list" style="position:relative;" v-for="(item, i) in robotType == 0?strategyList_bn:strategyList_ok" @click="showDetails(item)">
            <div :class="(((item.strategyType == 3 || item.strategyType == 4) && item.state==1) || robotInfo.state==2 || (item.strategyType == 0 && item.state == 2))?'set_gray':''">
              <div @click.stop v-if="showCheck" style="display: inline-block;vertical-align:top;width:2.6%;margin-right: 10px; line-height: 46px;">
                <el-checkbox :disabled="item.isNotChecked" v-model="item.checked" @change="checkStrategyChange(i, item.id, item.product_type)"></el-checkbox>
              </div>
              <div class="" style="display:inline-block;cursor: pointer">
                <img v-if="item.strategyType == 0 && (robotInfo.state != 2 && item.state != 2)" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/triangle.svg">
                <img v-if="item.strategyType == 0 && (robotInfo.state == 2 || item.state == 2)" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/triangle_grey.svg">
                <img v-else-if="item.strategyType == 1 && robotInfo.state != 2" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_bb_purple.png">
                <img v-else-if="item.strategyType == 1 && robotInfo.state == 2" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_bb_grey.svg">
                <img v-else-if="item.strategyType == 2 && robotInfo.state != 2" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_ub.svg">
                <img v-else-if="item.strategyType == 2 && robotInfo.state == 2" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_ub_grey.svg">
                <img v-if="item.product_type == 'hedge' && robotInfo.state != 2" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_contract.svg">
                <img v-else-if="item.product_type == 'hedge' && robotInfo.state == 2" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_contract_grey.svg">
                <img v-if="item.product_type == 'Term' && robotInfo.state != 2" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_term.png">
                <img v-else-if="item.product_type == 'Term' && robotInfo.state == 2" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_term_grey.png">
                <img v-if="item.strategyType == 3 && robotInfo.state != 2 && (item.state == 0 || item.state == 3||item.state == 2)" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_mw.svg">
                <img v-else-if="item.strategyType == 3 && (robotInfo.state == 2 || item.state == 1)" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_mw_grey.svg">
                <img v-if="item.strategyType == 4 && robotInfo.state != 2 && (item.state == 0 || item.state == 3||item.state == 2)" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_martin.svg">
                <img v-else-if="item.strategyType == 4 && (robotInfo.state == 2 || item.state == 1)" style="width: 34px;vertical-align: center" src="../../assets/img/robot/robot_strategy/strategy_martin_grey.svg">
                <div style="display: inline-block;vertical-align:middle;margin-left: 7px"><!--<img style="width: 14px;margin-top: -3px;" src="../../assets/img/green_arrow.svg"></img>-->
                  <span v-if="item.strategyType == 0" class="celue_title">{{ item.coin_one }} <span style="font-size: 18px;color:#52C41A">⇀</span> {{ item.coin_two }} <span style="font-size: 17px; cursor: pointer;color:#52C41A">⇀</span> {{ item.coin_three }}</span>
                  <span v-else-if="item.strategyType == 1" class="celue_title">{{ item.coin_one }} <div style="display:inline-block;vertical-align:middle"><span :class="($util.isAndroid()&&item.front_space == 0)?'array-android-css2':''" style="font-size: 18px;display: block;color:#52C41A;line-height: 6px">⇀</span><span :class="$util.isAndroid()?'array-android-css': ''" v-show="item.space_two != 0" style="display: block;color:#FF4D4F;font-size: 18px;transform: rotate(180deg);line-height: 6px">⇀</span></div> {{item.coin_two}}</span>
                  <span v-else-if="item.strategyType == 2" class="celue_title">{{ item.coin_one }} <span style="font-size: 18px;color:#52C41A">⇀</span> {{ item.coin_two }}</span>
                  <span v-else-if="item.strategyType == 3" class="celue_title">{{ item.coin_one }} <div v-if="item.after_space != 0" style="display:inline-block;vertical-align:middle"><span :class="($util.isAndroid()&&item.front_space == 0)?'array-android-css2':''" style="display:block;vertical-align:middle;font-size: 18px;color: #AB54DB;line-height: 6px">⇀</span><span :class="$util.isAndroid()?'array-android-css': ''" v-if="item.front_space != 0" style="display: block;color:#FF4D4F;font-size: 18px;transform: rotate(180deg);line-height: 6px">⇀</span></div><span v-if="item.after_space == 0" style="display: inline-block;color:#AB54DB;font-size: 18px;transform: rotate(180deg);line-height: 0px; vertical-align: middle">⇀</span> {{ item.coin_two }}</span>
                  <span v-else-if="item.strategyType == 4" class="celue_title">{{ item.coin_one }} <span style="font-size: 18px;color: #AB54DB">⇀</span> {{ item.coin_two }}</span>


                  <span v-else-if="item.product_type == 'hedge' && item.future_type==2" class="celue_title">{{ item.coin }}{{ item.coin_two }} {{item.robotType==0?'CM':''}} 永续 10X<span v-if="item.version=='oneWay'" style="font-size: 14px; margin-left: 8px" :style="{color: item.direction==1?'#2CC197':'rgba(220, 77, 77,1)'}">{{item.direction==1?'开多':'开空'}}</span></span>

                  <span v-else-if="item.product_type == 'hedge' && item.future_type!=2" class="celue_title">{{ item.coin }} <span style="font-size: 18px;color:#52C41A">⇀</span> {{ item.coin_two }} 永续 10X <span v-if="item.version=='oneWay'" style="font-size: 14px; margin-left: 8px" :style="{color: item.direction==1?'#2CC197':'rgba(220, 77, 77,1)'}">{{item.direction==1?'开多':'开空'}}</span></span>
                  <span v-else-if="item.product_type == 'Term'" class="celue_title">{{ item.coin }} <span style="font-size: 18px;color:#52C41A">⇀</span> {{ item.coin_two }}</span>

<!--                  <span v-if="(item.strategyType==0) && item.state == 1 && item.capital == 0 && robotInfo.state != 2 && switchValue != 2" style="display:inline-block;vertical-align:unset;font-size:12px;color: #AB54DB;margin-left: 10px"><span class="el-icon-refresh-right" style="font-size: 16px;animation: rotating 1s linear infinite;vertical-align: top"></span>正在初始化</span>-->
                  <img v-if="item.product_type == 'hedge' && item.state == 1 && robotInfo.state != 2 && switchValue != 2" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
                  <img v-if="robotInfo.state != 2 && (((item.strategyType == 0) && item.state == 1 && item.capital != 0) || item.strategyType == 1 || item.strategyType == 2 || item.state == 0) && switchValue != 2" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
                  <span v-else-if="switchValue != 2&&(item.number_of_cycles != 0 && item.number_of_cycles!=item.current_number_of_cycles || item.number_of_cycles == 0)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.paused') }}</span>
                  <span v-else-if="switchValue != 2 && item.strategyType == 4 &&(item.number_of_cycles != 0 && item.number_of_cycles==item.current_number_of_cycles)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.cycle_completed') }}</span>
                  <span v-else-if="switchValue != 2 && item.strategyType == 4 && (item.stop_replenishment == 2 || item.clearance_sale == 2)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.cycle_stopped') }}</span>
                  <span v-if="item.strategyType == 0" style="font-size: 14px;display:block;font-weight: 300;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.golden_triangle_arbitrage') }}</span>
                  <span v-if="item.strategyType == 1" style="font-size: 14px;display:block;font-weight: 300;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.bb_trade') }}</span>
                  <span v-if="item.strategyType == 2" style="font-size: 14px;display:block;font-weight: 300;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.ub_trade') }}</span>
                  <span v-if="item.product_type == 'hedge'" style="font-size: 14px;display:block;font-weight: 300;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.grid_contract_strategy') }}{{item.product_type == 'hedge'&&item.version_number!='speed'&&item.coin_two=='ICP'?'':item.product_type == 'hedge'&&item.version_number=='speed'?'（闪电版）':item.product_type == 'hedge'&&item.version=='oneWay'?'（单向版）':''}}</span>
                  <span v-if="item.product_type == 'Term'" style="font-size: 14px;display:block;font-weight: 300;vertical-align: middle">{{ $t('robot.auto') }}：{{ $t('robot.futures_spot_strategy') }}{{item.old_new==3?'（闪电版）':item.old_new==4?'（BTC 版）':''}}</span>
                  <span v-if="item.strategyType == 3" style="font-size: 14px;display:block;font-weight: 300;vertical-align: middle">{{ $t('robot.semi') }}：{{ $t('robot.grid_strategy') }}</span>
                  <span v-if="item.strategyType == 4" style="font-size: 14px;display:block;font-weight: 300;vertical-align: middle">{{ $t('robot.semi') }}：{{ $t('robot.martin_strategy') }}</span>
                </div>
              </div>
              <b style="font-size: 48px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: rgba(114, 119, 126, 0.1);position:absolute; right: 0;top: -15px">{{ item.rank }}</b>
              <div class="celue_content">
                <div v-if="item.product_type!='hedge' && item.product_type!='Term' && item.strategyType!= 3 && item.strategyType!= 4" style="display: block;position:relative;">
                  <span v-if="(item.strategyType==0) && item.state == 1 && item.capital == 0 && robotInfo.state != 2 && switchValue != 2" style="display:inline-block;background-color: #F4F8FF; vertical-align:unset;font-size:12px;color: #AB54DB;width: 100%;margin-bottom: 5px; padding: 5px 0;"><span class="el-icon-refresh-right" style="font-size: 16px;color: #AB54DB;animation: rotating 1s linear infinite;vertical-align: top;margin-left: 5px"></span>{{ $t('robot.initializing') }}<span style="margin-left: 5px;font-size:12px;color: #AB54DB;" v-if="item.need_one || item.need_two || item.need_three">{{ $t('robot.go_to_exchange_recharge') }}</span><span style="margin-left: 5px;font-size:12px;color: #AB54DB;" v-if="item.need_one">{{ item.coin_one }}</span><span style="margin-left: 5px;font-size:12px;color: #AB54DB;" v-if="item.need_two">{{ item.coin_two }}</span><span style="margin-left: 5px;font-size:12px;color: #AB54DB;" v-if="item.need_three">{{ item.coin_three }}</span></span>
                  <div style="display:inline-block;width: 50%;vertical-align: top">
                    <span style="display:block;color:#72777E;">{{(item.strategyType == 0 || item.strategyType == 1 || item.strategyType == 2) ? $t('robot.put_position') : $t('robot.input_amount')+'(U)'}}</span>
                    <span v-if="item.strategyType == 0" style="display:block;color: #1E2329;margin-top: 3px">{{ item.coin_one }}({{item.space_one | roundEightFilter}})、{{ item.coin_two }}({{ item.space_two | roundEightFilter }})、{{ item.coin_three }}({{ item.space_three | roundEightFilter }})</span>
                    <span v-if="item.strategyType == 1" style="display:block;color: #1E2329;margin-top: 3px">{{ item.coin_one }}({{item.space | roundEightFilter}})、{{ item.coin_two }}<span v-show="item.space_two != 0" style="color: #1E2329">({{ item.space_two | roundEightFilter }})</span></span>
                    <span v-if="item.strategyType == 2" style="display:block;color: #1E2329;margin-top: 3px">{{ item.coin_one }}({{ item.space | roundEightFilter }})</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.after_space }}</span>
                  </div>
                  <div style="display: inline-block;width: 50%;text-align: left;vertical-align: top">
                    <span style="display:block;color:#72777E;">{{ $t('robot.create_time') }}</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.add_time }}</span>
                  </div>
                </div>
                <div v-if="item.product_type=='hedge'" style="display: block;position:relative;">
                  <div style="display:inline-block;width: 100%;vertical-align: top">
                    <div style="width: 100%;">
                      <span style="display: inline-block;width: 50%">{{ $t('robot.input_amount') }}{{item.future_type==2?'':'U'}}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.space }} {{item.future_type==2?item.coin:''}}</span></span>
                      <span style="display: inline-block;width: 50%;margin-top: 5px">{{ $t('robot.Total_revenue') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.profit_total }} {{item.future_type==2?item.coin:'U'}}</span></span>
                    </div>
                    <div style="width: 100%;">
                      <span style="display: inline-block;width: 50%">{{ $t('robot.create_time') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.add_time }}</span></span>
                      <span style="display: inline-block;width: 50%;margin-top: 5px">{{ $t('robot.yield') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.profit_rate?item.profit_rate:'0.00' }}%</span></span>
                    </div>
                    <div style="width: 100%;margin-top: 5px">
<!--                      <span style="display: inline-block;width: 50%">{{ $t('robot.loss') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.stop_loss!=0?(item.stop_loss*100).toFixed(2)+'%':$t('robot.not_stop_loss') }}</span></span>-->
                      <span style="display: inline-block;width: 50%">{{ $t('robot.loss') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.stop_loss==100?$t('robot.loss'):item.stop_loss==5?'部分止损':$t('robot.not_stop_loss') }}</span></span>
<!--                      <span style="display: inline-block;width: 50%;margin-top: 5px">{{ $t('robot.interference') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.stop_surplus!=0?(item.stop_surplus*100).toFixed(2)+'%':$t('robot.not_stop_surplus') }}</span></span>-->
                    </div>
                  </div>
<!--                  <div style="display: inline-block;width: 50%;text-align: left;vertical-align: top">-->
<!--                    <span style="display:block;color:#72777E;">{{ $t('robot.create_time') }}</span>-->
<!--                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.add_time }}</span>-->
<!--                  </div>-->
                </div>
                <div v-if="item.product_type=='Term'" style="display: block;position:relative;">
                  <div style="display:inline-block;width: 100%;vertical-align: top">
                    <div style="width: 100%;">
                      <span style="display: inline-block;width: 50%">{{ $t('robot.input_amount') }}U：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.totalSpace }}</span></span>
                      <span style="display: inline-block;width: 50%;margin-top: 5px">{{ $t('robot.Total_revenue') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.profit_total }}</span></span>
                    </div>
                    <div style="width: 100%;">
                      <span style="display: inline-block;width: 50%">{{ $t('robot.create_time') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.add_time }}</span></span>
                      <span style="display: inline-block;width: 50%;margin-top: 5px">{{ $t('robot.yield') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.profit_rate?item.profit_rate:'0.00' }}%</span></span>
                    </div>
                    <div style="width: 100%;margin-top: 5px">
                      <span style="display: inline-block;width: 50%">{{ $t('robot.billing_quantity') }}：<span style="display: block;margin-top:3px;color: #1E2329">{{ item.profit_times }} {{$t('robot.order')}}</span></span>
                    </div>
                  </div>
                </div>
                <div v-if="item.strategyType == 3" style="display: block;margin-top: 5px">
                  <div style="display:inline-block;width: 38%;vertical-align: top;">
                    <span style="display:block;color:#72777E;">{{ $t('robot.input_amount') }}</span>
                    <div style="display:block;color: #1E2329;margin-top: 3px">
                      <span v-if="item.after_space" style="display:inline-block;color: #1E2329">{{ item.after_space | roundEightFilter }}({{ item.coin_one }})</span>
                      <span v-if="item.front_space" style="display:inline-block;color: #1E2329">、{{ item.front_space | roundEightFilter }}({{ item.coin_two }})</span>
                    </div>
                  </div>
                  <div style="display:inline-block;width: 37%;vertical-align: top;">
                    <span style="display:block;color:#72777E;">{{ $t('robot.Price_range') }}({{ item.coin_one }})</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.floor_price ? $options.filters['roundEightFilter'](item.floor_price) : '--' }} {{ item.floor_price ? '~' : '' }} {{ $options.filters['roundEightFilter'](item.ceiling_price) ? item.ceiling_price : '' }} </span>
                  </div>
                  <div style="display:inline-block;width: 25%;vertical-align: top;">
                    <span style="display:block;color:#72777E;">{{ $t('robot.Arbitrage_times') }}</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.profit_times }}</span>
                  </div>
                </div>
                <div v-if="item.strategyType == 3" style="display: block;margin-top: 5px">
                  <div style="display:inline-block;width: 38%">
                    <span style="display:block;color:#72777E;">{{ $t('robot.Total_revenue') }}({{ item.coin_one }})</span>
                    <span style="display:block;margin-top: 3px"><span :style="{color: item.profit_total>0?'#52C41A':item.profit_total<0?'#FF4D4F':'#1E2329'}">{{ item.profit_total ? $options.filters['roundEightFilter'](item.profit_total) : '--' }}</span></span>
                  </div>
                  <div style="display: inline-block;width: 37%;text-align: left">
                    <span style="display:block;color:#72777E;">{{ $t('robot.yield') }}</span>
                    <span style="display:block;margin-top: 3px"><span :style="{color: item.profit_rate>0?'#52C41A':item.profit_rate<0?'#FF4D4F':'#1E2329'}">{{ item.profit_rate ? $options.filters['roundEightFilter'](item.profit_rate) : '--' }}%</span></span>
                  </div>
                  <div style="display: inline-block;width: 25%;text-align: left">
                    <span style="display:block;color:#72777E;">{{ $t('robot.create_time') }}</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.add_time_short }}</span>
                  </div>
                </div>
                <div v-if="item.strategyType == 3" style="display: block;margin-top: 5px">
                  <span v-if="switchValue == 2" style="display: inline-block;width: 50%;text-align: left;vertical-align: top">{{ $t('robot.Manually_stopped') }}</span>
                </div>
                <div v-if="item.strategyType == 4" style="display: flex;justify-content: space-between; margin-top: 5px">
                  <div style="display:inline-block;width: 38%">
                    <span style="display:block;color:#72777E;">{{ $t('robot.input_amount') }}(U)</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.fund | roundEightFilter }}</span>
                  </div>
                  <div style="display: inline-block; width: 37%;text-align: left">
                    <span style="display:block;color:#72777E;">{{ $t('robot.average_position_price') }}(U)</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.average_price | roundEightFilter }}</span>
                  </div>
                  <div style="display: inline-block;width: 25%;text-align: right">
                    <span style="display:block;color:#72777E;">{{ $t('robot.cycled') }}</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.current_number_of_cycles_new }}{{ $t('robot.ci') }}</span>
                  </div>
                </div>
                <div v-if="item.strategyType == 4" style="display: block;margin-top: 5px">
                  <div style="display:inline-block;width: 38%">
                    <span style="display:block;color:#72777E;">{{ $t('robot.replenishment_depth') }}</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.current_order_no==0?$t('robot.not_yet'):item.current_order_no==1? $t('robot.first_order'):$t('robot.di')+item.current_order_no+$t('robot.order') }}</span>
                  </div>
                  <div style="display: inline-block;width: 37%;text-align: left">
                    <span style="display:block;color:#72777E;">{{ $t('robot.yield') }}</span>
                    <span style="display:block;margin-top: 3px"><span :style="{color: item.profit_rate>0?'#52C41A':item.profit_rate<0?'#FF4D4F':'#1E2329'}">{{ item.profit_rate | roundEightFilter }}%</span></span>
                  </div>
                  <div style="display: inline-block;position:absolute;width: 25%;right:0;text-align: right">
                    <span style="display:block;color:#72777E;">{{ $t('robot.floating_profit_loss') }}(U)</span>
                    <span style="display:block;margin-top: 3px"><span :style="{color: item.profit_loss>0?'#52C41A':item.profit_loss<0?'#FF4D4F':'#1E2329'}">{{ item.profit_loss | roundEightFilter }}</span></span>
                  </div>
                </div>
                <div v-if="item.strategyType == 4" style="display: block;margin-top: 5px">
                  <div style="display:inline-block;width: 38%">
                    <span style="display:block;color:#72777E;">{{ $t('robot.current_price') }}(U)</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.current_price | roundEightFilter }}</span>
                  </div>
                  <div style="display: inline-block;width: 60%;text-align: left">
                    <span style="display:block;color:#72777E;">{{ $t('robot.create_time') }}</span>
                    <span style="display:block;color: #1E2329;margin-top: 3px">{{ item.add_time_short }}</span>
                  </div>
                </div>

              </div>
            </div>
            <div style="font-weight: 400;color: #AB54DB;margin-top: 10px;width:100%;display:flex;justify-content: center;">
<!--              <el-button class="celue_button" v-if="(item.strategyType == 0) && item.state == 2 && robotInfo.state != 2 && switchValue != 2" style="width: -webkit-fill-available" @click.stop="openStrategy(item.strategyType, item)">{{ $t('robot.start') }}</el-button>-->
              <el-button class="celue_button" style="width: -webkit-fill-available" @click="showDetails(item)">{{ $t('robot.strategy_details') }}</el-button>
              <el-button class="celue_button" v-if="(item.strategyType == 3) && switchValue == 1 && item.state == 0" style="margin-left: 5%;width: -webkit-fill-available" @click.stop="startAndStopThis(item, 2)">{{ $t('robot.pause_strategy') }}</el-button>
              <el-button class="celue_button" v-if="(item.strategyType == 3) && switchValue == 1 && item.state == 1" style="margin-left: 5%;width: -webkit-fill-available;background-color: #52C41A;color: white" @click.stop="startAndStopThis(item, 1)">{{ $t('robot.open_strategy') }}</el-button>
              <el-button class="celue_button" v-if="(item.product_type == 'hedge') && switchValue == 1 && item.state == 1" style="margin-left: 5%;width: -webkit-fill-available" @click.stop="pauseHedge(item, 2)">{{ $t('robot.pause_strategy') }}</el-button>
              <el-button class="celue_button" v-if="(item.product_type == 'hedge') && switchValue == 1 && item.state == 2" style="margin-left: 5%;width: -webkit-fill-available;background-color: #52C41A;color: white" @click.stop="startHedge(item, 1)">{{ $t('robot.open_strategy') }}</el-button>
              <el-button class="celue_button" v-if="(item.product_type == 'Term') && switchValue == 1" style="margin-left: 5%;width: -webkit-fill-available;background-color: #52C41A;color: white" @click.stop="changeTermStrategy(item, 1)">{{ $t('robot.add_position_long') }}</el-button>
              <el-button class="celue_button" v-if="(item.strategyType == 1) && switchValue == 1" style="margin-left: 5%;width: -webkit-fill-available;" @click.stop="addBBPosition(item.strategyType, item)">{{ $t('robot.add_position_long') }}</el-button>
              <el-button class="celue_button" v-if="item.product_type != 'hedge' && item.strategyType != 4 && switchValue !=2" style="margin-left: 5%;width: -webkit-fill-available" @click.stop="delet(item.strategyType, item)">{{ $t('robot.del_strategy') }}</el-button>
              <el-button class="celue_button" v-if="item.strategyType == 4 && switchValue !=2" style="margin-left: 5%;width: -webkit-fill-available" @click.stop="delet(item.strategyType, item)">{{ $t('robot.stop_strategy') }}</el-button>
              <el-button class="celue_button" v-if="item.product_type == 'hedge' && switchValue !=2" style="margin-left: 5%;width: -webkit-fill-available" @click.stop="delet(5, item)">{{ $t('robot.del_strategy') }}</el-button>
              <el-button class="celue_button" v-if="item.strategyType == 4 && switchValue ==2" style="margin-left: 5%;width: -webkit-fill-available" @click.stop="Restart(item.strategyType, item)">{{ $t('robot.restart_strategy') }}</el-button>
            </div>
          </div>

          <!-- 手機簡版 -->
          <div v-if="changes == 1" class="celue_list concise_list" style="position:relative;" v-for="(item, i) in robotType == 0?strategyList_bn:strategyList_ok" @click="showDetails(item)">
            <div @click.stop v-if="showCheck" style="display: inline-block;vertical-align:top;width:2.6%;margin-right: 10px;">
              <el-checkbox :disabled="item.isNotChecked" v-model="item.checked" @change="checkStrategyChange(i, item.id, item.product_type)"></el-checkbox>
            </div>
            <span style="display:inline-block;margin-left: 0">{{item.rank}}<span v-show="item.rank" style="margin-left: 0">.</span></span>
            <span v-if="item.strategyType == 0" class="mobile-simple-strategy" style=""><span class="mobile-simple-strategy-position" style="max-width:28%">{{ item.coin_one }}({{ item.space_one | roundEightFilter }}</span>) <span style="color: #52C41A;margin:0 2px">⇀</span> <span class="mobile-simple-strategy-position" style="max-width:28%">{{ item.coin_two }}({{ item.space_two | roundEightFilter }}</span>) <span style="color: #52C41A;margin:0 2px">⇀</span> <span class="mobile-simple-strategy-position" style="max-width:28%">{{ item.coin_three }}({{ item.space_three | roundEightFilter }}</span>)</span>
            <span v-if="item.strategyType == 1" class="mobile-simple-strategy" style=""><span class="mobile-simple-strategy-position">{{ item.coin_one }}({{ item.space | roundEightFilter }}</span>) <div style="display:inline-block;vertical-align:middle"><span :class="($util.isAndroid()&&item.front_space == 0)?'array-android-css2':''" style="display: block;color:#52C41A;line-height: 6px;margin-left: 0">⇀</span><span :class="$util.isAndroid()?'array-android-css': ''" v-show="item.space_two != 0" style="display: block;color:#FF4D4F;transform: rotate(180deg);line-height: 6px;margin-left: 0">⇀</span></div> <span class="mobile-simple-strategy-position">{{ item.coin_two }}({{ item.space_two | roundEightFilter }}</span>)</span>
            <span v-if="item.strategyType == 2" class="mobile-simple-strategy" style=""><span class="mobile-simple-strategy-position">{{ item.coin_one }}({{ item.space | roundEightFilter }}</span>) <span style="color: #52C41A;margin:0 2px">⇀</span> {{item.coin_two}}</span>

            <span v-if="item.product_type == 'hedge' && item.future_type == 2" class="mobile-simple-strategy" style="display: inline-block;width: unset">{{ item.coin }}{{item.coin_two}} ({{ item.space | roundEightFilter }}) 永续 10X<span v-if="item.version=='oneWay'" style="margin-left: 5px; font-size: 14px; font-weight: 400;" :style="{color: item.direction==1?'#2CC197':'rgba(220, 77, 77,1)'}">{{item.direction==1?'开多':'开空'}}</span></span>

            <span v-if="item.product_type == 'hedge' && item.future_type != 2" class="mobile-simple-strategy" style="display: inline-block;width: unset"><span class="mobile-simple-strategy-position">{{ item.coin }}({{ item.space | roundEightFilter }}</span>) <span style="color: #52C41A;margin:0 2px">⇀</span> {{item.coin_two}}永续 10X<span v-if="item.version=='oneWay'" style="margin-left: 5px; font-size: 14px; font-weight: 400;" :style="{color: item.direction==1?'#2CC197':'rgba(220, 77, 77,1)'}">{{item.direction==1?'开多':'开空'}}</span></span>
            <span v-if="item.product_type == 'hedge'" class="mobile-simple-strategy" style="display: inline-block;width: unset;color: #72777E">{{item.product_type == 'hedge'&&item.version_number!='speed'&&item.coin_two=='ICP'?'':item.product_type == 'hedge'&&item.version_number=='speed'?'(闪电版)':item.product_type == 'hedge'&&item.version=='oneWay'?'(单向版)':''}}</span>
            <span v-if="item.product_type == 'Term'" class="mobile-simple-strategy" style="display: inline-block;"><span class="mobile-simple-strategy-position">{{ item.coin }}({{ item.totalSpace | roundEightFilter }}</span>) <span style="color: #52C41A;margin:0 2px">⇀</span> {{item.coin_two}} <span style="color: #72777E">{{item.old_new==3?'(闪电版)':item.old_new==4?'(BTC版)':''}}</span></span>
            <span v-if="item.strategyType == 3" class="mobile-simple-strategy" style=""><span class="mobile-simple-strategy-position">{{ item.coin_one }}({{item.after_space | roundEightFilter}}</span>) <div v-if="item.after_space != 0" style="display: inline-block;vertical-align: middle;margin-top: -6px;"><span style="margin: 0 2px;color: #AB54DB;">⇀</span><span v-if="item.front_space != 0"style="color: #FF4D4F;display: block;line-height:0; padding: 3px 0px 0px 0px; margin:0 2px; transform: scale(-1.0);font-size: 14px">⇀</span></div><span v-if="item.after_space == 0"style="color: #AB54DB;display: inline-block;margin:0 2px; transform: scale(-1.1);font-size: 14px">⇀</span> <span class="mobile-simple-strategy-position">{{item.coin_two}}{{ item.front_space?'('+ $options.filters['roundEightFilter'](item.front_space):'' }}</span>{{ item.front_space?')':'' }}</span>
            <span v-if="item.strategyType == 4" class="mobile-simple-strategy" style=""><span class="mobile-simple-strategy-position">{{ item.coin_one }}({{item.fund | roundEightFilter}}</span>) <span style="font-size: 14px;color: #AB54DB;margin-left: 0">⇀</span> {{item.coin_two}}</span>
            <div style="margin-top: 5px;position:relative;">
              <span v-if="item.strategyType == 0" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.sj') }}</span>
              <span v-if="item.strategyType == 1" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.bb_trade') }}</span>
              <span v-if="item.strategyType == 2" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.ub_trade') }}</span>
              <span v-if="item.product_type == 'hedge'" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.grid_contract_strategy') }}</span>
              <span v-if="item.product_type == 'Term'" style="background-color:#FFFAEF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #E3B936;font-size: 12px;padding: 2px 6px">{{ $t('robot.futures_spot_strategy') }}</span>
              <span v-if="item.strategyType == 3" style="background-color:#F4F8FF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #AB54DB;font-size: 12px;padding: 2px 6px">{{ $t('robot.grid_strategy') }}</span>
              <span v-if="item.strategyType == 4" style="background-color:#F4F8FF;font-weight: 400; border-radius: 10px 100px 100px 0px;color: #AB54DB;font-size: 12px;padding: 2px 6px">{{ $t('robot.martin_strategy') }}</span>
              <span v-if="(item.strategyType == 0) && item.state == 1 && item.capital == 0 && robotInfo.state != 2 && switchValue != 2" style="display:inline-block;vertical-align:unset;font-size:12px;color: #AB54DB;margin-left: 0px"><span class="el-icon-refresh-right" style="color: #AB54DB;font-size: 16px;animation: rotating 1s linear infinite;vertical-align: top"></span>{{ $t('robot.initializing') }}<span  style="margin-left: 5px;font-size:12px;color: #AB54DB;" v-if="item.need_one || item.need_two || item.need_three">{{ $t('robot.go_to_exchange_recharge') }}</span><span style="margin-left: 5px;font-size:12px;color: #AB54DB;" v-if="item.need_one">{{ item.coin_one }}</span><span style="margin-left: 5px;font-size:12px;color: #AB54DB;" v-if="item.need_two">{{ item.coin_two }}</span><span style="margin-left: 5px;font-size:12px;color: #AB54DB;" v-if="item.need_three">{{ item.coin_three }}</span></span>
              <img v-if="item.product_type == 'hedge' && item.state == 1 && robotInfo.state != 2 && switchValue != 2" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
              <img v-if="item.product_type != 'hedge' && item.product_type != 'Term' && robotInfo.state != 2 && (((item.strategyType == 0) && item.state == 1 && item.capital != 0) || item.strategyType == 1 || item.strategyType == 2 || item.state == 0) && switchValue != 2" style="width:20px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
              <span v-else-if="switchValue != 2&&(item.number_of_cycles!=item.current_number_of_cycles)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.paused') }}</span>
              <span v-else-if="switchValue != 2 && item.strategyType == 4 &&(item.number_of_cycles != 0 && item.number_of_cycles==item.current_number_of_cycles)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.cycle_completed') }}</span>
              <span v-else-if="switchValue != 2 && item.strategyType == 4 && (item.stop_replenishment == 2 || item.clearance_sale == 2)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #72777E;margin-left: 10px">{{ $t('robot.cycle_stopped') }}</span>

              <div style="right: 0; top: 0; font-size: 14px; color: #AB54DB; font-weight: 400; position: absolute">
                <span v-if="(item.strategyType == 1) && switchValue != 2" style="font-weight: 400;color:#AB54DB" @click.stop="addBBPosition(item.strategyType, item)">{{ $t('robot.add_position') }}</span>
                <span v-if="(item.strategyType == 0) && item.state == 2 && robotInfo.state != 2 && switchValue != 2" style="font-weight: 400;color:#AB54DB" @click.stop="openStrategy(item.strategyType, item)">{{ $t('robot.start') }}</span>
                <span v-if="(item.strategyType == 3) && item.state == 0" style="font-weight: 400;color:#AB54DB" @click.stop="startAndStopThis(item, 2)">{{ $t('robot.pause') }}</span>
                <span v-if="(item.strategyType == 3) && item.state == 1" style="color:#52C41A;font-weight: 400;" @click.stop="startAndStopThis(item, 1)">{{ $t('robot.start') }}</span>
                <span v-if="(item.product_type == 'hedge') && item.state == 1" style="font-weight: 400;color:#AB54DB" @click.stop="pauseHedge(item, 2)">{{ $t('robot.pause') }}</span>
                <span v-if="(item.product_type == 'hedge') && item.state == 2" style="color:#52C41A;font-weight: 400;" @click.stop="startHedge(item, 1)">{{ $t('robot.start') }}</span>
                <span v-if="(item.product_type == 'Term') && item.state == 2" style="color:#52C41A;font-weight: 400;" @click.stop="changeTermStrategy(item, 1)">{{ $t('robot.add_position') }}</span>
                <span v-if="item.strategyType == 4 && switchValue !=2" style="font-weight: 400;color:#AB54DB" @click.stop="delet(item.strategyType, item)">{{ $t('robot.stop') }}</span>
                <span v-if="item.product_type != 'hedge' && item.strategyType != 4 && switchValue !=2" style="font-weight: 400;color:#AB54DB" @click.stop="delet(item.strategyType, item)">{{ $t('robot.delete') }}</span>
                <span v-if="item.product_type == 'hedge' && switchValue !=2" style="font-weight: 400;color:#AB54DB" @click.stop="delet(5, item)">{{ $t('robot.delete') }}</span>
                <span v-if="item.strategyType == 4 && switchValue ==2" style="font-weight: 400;color:#AB54DB" @click.stop="Restart(item.strategyType, item)">{{ $t('robot.restart') }}</span>
              </div>
            </div>
          </div>
          <span style="display:block;position:absolute;bottom:-14px;font-size:14px; color: #000613; line-height: 22px;">{{ $t('robot.total') }} <span>{{ totalRow }}</span> {{ $t('robot.strip') }}</span>
          <el-pagination
              v-if="switchValue == '2'"
              style="position:absolute;right:-10px;bottom:-28px"
              layout="prev, pager, next"
              small
              @current-change="changePage"
              :page-size="10"
              :current-page="pageNum"
              :hide-on-single-page="capitalListTotal<=0"
              :total="capitalListTotal">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <!--           弹出框              -->
    <!-- 添加全自动策略 -->
    <el-dialog
        class="dialog-mb dialog_phone_footer strategy sj_title"

        :visible.sync="dialogHuangjin"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        style="height: auto;overflow: scroll;-webkit-overflow-scrolling: touch;">
      <!--        :title="$t('robot.add')+$t('robot.golden_triangle_arbitrage')"-->
      <div slot="title">
        <span>{{$t('robot.add')+$t('robot.golden_triangle_arbitrage')}}</span>
        <tooltip style="position: fixed; top: 21px; left: 210px;" :content="'1.'+$t('robot.sj_tip') +'\n'+ '2.'+$t('robot.position_limit_description')" :width="'300px'" :imgWidth="'19px'"></tooltip>
      </div>
      <el-divider></el-divider>
<!--      <span class="sj_explain" :class="[language == 'English'?'sj_explain_english': '']" style="">{{ $t('robot.sj_tip') }}</span>-->
      <span>{{ $t('robot.select') }}<span v-if="robotType == 0">{{ $t('robot.binance') }}</span><span v-if="robotType == 1">{{ $t('robot.okx') }}</span>{{ $t('robot.space_currency') }}<span v-if="coinName">（{{coinName}}）</span></span>
      <el-select v-model="coinName" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" @change="getallSymbolsByCoin(1)" filterable ref='select1' @focus="closeReadOnly($event,1)" @hook:mounted="closeReadOnly($event,1)" @visible-change="closeReadOnly($event,1)" :placeholder="$t('robot.please_select')">
        <el-option v-for="(item) in allCoins" :key="item.coin_name" :label="item.coin_name" :value="item.coin_name">
        </el-option>
      </el-select>
      <span style="display:block;margin-top: 20px">{{ $t('robot.select') }}<span v-if="robotType == 0">{{ $t('robot.binance') }}</span><span v-if="robotType == 1">{{ $t('robot.okx') }}</span>{{ $t('robot.supportive') }}{{ $t('robot.transaction_pair') }}<span v-if="coinName"> （{{coinName}}⇀{{coinName2}}） </span></span>
      <el-select v-model="valueLin1" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" value-key="valueLin1" filterable :placeholder="$t('robot.please_select')" ref='select2' @focus="closeReadOnly($event,2)" @hook:mounted="closeReadOnly($event,2)" @visible-change="closeReadOnly($event,2)" @change="getallSymbolsByCoin2(1, $event)">
        <el-option v-for="(item) in allSymbolsByCoin" :key="item.target_coin+' / '+item.cost_coin" :label="item.target_coin+' / '+item.cost_coin" :value="item">
          <span :class="[item.target_coin == coinName?'coin_color_light':'coin_color_deep']">{{ item.target_coin }}</span>
          <span style="color: #C0C4C8"> / </span>
          <span :class="[item.target_coin != coinName?'coin_color_light':'coin_color_deep']">{{ item.cost_coin }}</span>
        </el-option>
      </el-select>
      <span style="display:block;margin-top: 20px">{{ $t('robot.select') }}<span v-if="robotType == 0">{{ $t('robot.binance') }}</span><span v-if="robotType == 1">{{ $t('robot.okx') }}</span>{{ $t('robot.supportive') }}{{ $t('robot.transaction_pair') }}<span v-if="coinName2"> （{{coinName2}}⇀{{coinName3}}）</span></span>
      <el-select v-model="valueLin2" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" filterable :placeholder="$t('robot.please_select')" ref='select3' @focus="closeReadOnly($event,3)" @hook:mounted="closeReadOnly($event,3)" @visible-change="closeReadOnly($event,3)" @change="getallSymbolsByCoin3($event)">
        <el-option v-for="item in allSymbolsByCoin2" :key="item.target_coin+' / '+item.cost_coin" :label="item.target_coin+' / '+item.cost_coin" :value="item">
          <span :class="[item.target_coin == coinName2?'coin_color_light':'coin_color_deep']">{{ item.target_coin }}</span>
          <span style="color: #C0C4C8"> / </span>
          <span :class="[item.target_coin != coinName2?'coin_color_light':'coin_color_deep']">{{ item.cost_coin }}</span>
        </el-option>
      </el-select>
      <span style="display:block;margin-top: 20px">{{ $t('robot.set_transaction_pair_position') }}</span>
      <div style="margin-top: 10px">
        <span class="sp-coin-name sj-sp-coin-name" style="color:#E3B936;">{{ coinName }}</span>
        <span class="sp-coin-name" style="color:#52C41A;margin: 0px 10px">⇀</span>
        <span class="sp-coin-name" style="color:#E3B936;">{{ coinName2 }}</span>
      </div>
      <el-input id="sj_input1" @input="checkinput($event, 'huangjinvalue1')" class="sj-sp-coin-name" v-model="huangjinvalue1" style="margin-top: 5px"></el-input>
<!--      <span v-if="coinName" id="SJ_coin_max_balance_1" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;">{{ $t('robot.position_set_tip') }} {{balance_1}}</span>-->
      <span v-if="coinName" id="SJ_coin_min_one" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;"></span>
      <span id="SJ_coin_balance_1" style="display: block"></span>
      <div style="margin-top: 10px">
        <span class="sp-coin-name sj-sp-coin-name" style="color:#E3B936;">{{ coinName2 }}</span>
        <span class="sp-coin-name" style="color:#52C41A;margin: 0px 10px">⇀</span>
        <span class="sp-coin-name" style="color:#E3B936;">{{ coinName3 }}</span>
      </div>
      <el-input id="sj_input2" @input="checkinput($event, 'huangjinvalue2')" class="sj-sp-coin-name" v-model="huangjinvalue2" style="margin-top: 5px"></el-input>
<!--      <span v-if="valueLin1" id="SJ_coin_max_balance_2" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;">{{ $t('robot.position_set_tip') }} {{balance_2}}</span>-->
      <span v-if="valueLin1" id="SJ_coin_min_two" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;"></span>
      <span id="SJ_coin_balance_2" style="display: block"></span>
      <div style="margin-top: 10px">
        <span class="sp-coin-name sj-sp-coin-name" style="color:#E3B936;">{{ coinName3 }}</span>
        <span class="sp-coin-name" style="color:#52C41A;margin: 0px 10px">⇀</span>
        <span class="sp-coin-name" style="color:#E3B936;">{{ coinName }}</span>
      </div>
      <el-input id="sj_input3" @input="checkinput($event, 'huangjinvalue3')" class="sj-sp-coin-name" v-model="huangjinvalue3" style="margin-top: 5px"></el-input>
<!--      <span v-if="valueLin2" id="SJ_coin_max_balance_3" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;">{{ $t('robot.position_set_tip') }} {{balance_3}}</span>-->
      <span v-if="valueLin2" id="SJ_coin_min_three" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;"></span>
      <span id="SJ_coin_balance_3" style="display: block"></span>
      <span slot="footer" class="dialog-footer" style="">

        <span class="error-tip" style="display: block;color: #FF4D4F;text-align: left;font-size: 14px;margin-bottom: 10px;"><span style="display: inline-block;vertical-align: middle;margin-right: 5px">*</span>{{ $t('robot.set_up_multiple_groups_golden') }}</span>
        <el-button class="go_and_open" type="primary" :loading="isLoadingSJ" style="width: 116px; text-align: center;background: transparent;color: #AB54DB" @click="dialogHuangjin = false">{{ $t('robot.cancel') }}</el-button>
        <el-button class="go_and_open" type="primary" :loading="isLoadingSJ" style="width: 116px; text-align: center;" @click="addHuangjin(2)">{{ $t('robot.save') }}</el-button>
<!--    <el-button class="go_and_open" type="primary" :loading="isLoadingSJ" style="width: 116px; text-align: center;" @click="addHuangjin(1)">{{ $t('robot.save_and_start') }}</el-button>-->
        <span class="error-tip" v-if="errorCode == '2415'" style="display: block;color: #FF4D4F;text-align: left;font-size: 14px;margin-top: 15px;"><span style="display: inline-block;vertical-align: middle;margin-right: 5px">*</span>{{ $t('robot.sj_error_tip') }}</span>
  </span>

    </el-dialog>

    <!--BB-->
    <el-dialog
        class="dialog-mb-ub dialog-pc-bb"

        :visible.sync="dialogBB"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        style="height: auto;overflow: unset;-webkit-overflow-scrolling: touch">
<!--      :title="$t('robot.add')+$t('robot.bb_trade')"-->
      <div slot="title">
        <span style="display: inline-block;line-height: 16px">{{$t('robot.add')+$t('robot.bb_trade')}}</span>
        <tooltip style="position: relative;display: inline-block" :content="$t('robot.position_limit_description')" :width="'300px'" :imgWidth="'19px'"></tooltip>
      </div>
      <el-divider></el-divider>
      <span>{{ $t('robot.select') }}<span v-if="robotType == 0">{{ $t('robot.binance') }}</span><span v-if="robotType == 1">{{ $t('robot.okx') }}</span>{{ $t('robot.space_currency') }}<span v-if="coinName"> （{{coinName}}）</span></span>
      <el-select v-model="coinName" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" filterable :placeholder="$t('robot.please_select')" ref='select4' @focus="closeReadOnly($event,4)" @hook:mounted="closeReadOnly($event,4)" @visible-change="closeReadOnly($event,4)" @change="getallSymbolsByCoin(2)">
        <el-option v-for="item in allCoins" :key="item.coin_name" :label="item.coin_name" :value="item.coin_name"></el-option>
      </el-select>
      <span style="margin-top: 20px;display: block">{{ $t('robot.select') }}<span v-if="robotType == 0">{{ $t('robot.binance') }}</span><span v-if="robotType == 5">{{ $t('robot.okx') }}</span>{{ $t('robot.supportive') }}{{ $t('robot.transaction_pair') }}<span v-if="coinName"> （{{coinName}}⇀{{coinName2}}） </span></span>
      <el-select v-model="valueLin3" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" filterable :placeholder="$t('robot.please_select')" ref='select5' @focus="closeReadOnly($event,5)" @hook:mounted="closeReadOnly($event,5)" @visible-change="closeReadOnly($event,5)" @change="getallSymbolsByCoin4($event)">
        <el-option v-for="item in allSymbolsByCoin" :key="item.target_coin+'/'+item.cost_coin" :label="item.target_coin+' / '+item.cost_coin" :value="item">
          <span :class="[item.target_coin == coinName2?'coin_color_light':'coin_color_deep']">{{ item.target_coin }}</span>
          <span style="color: #C0C4C8"> / </span>
          <span :class="[item.target_coin != coinName2?'coin_color_light':'coin_color_deep']">{{ item.cost_coin }}</span>
        </el-option>
      </el-select>
      <span style="display:block;margin-top: 20px">{{ $t('robot.set_transaction_pair_position') }}({{ $t('robot.please_fill_in_any_position') }})</span>
      <div style="display: inline-block;width: 100%">
        <div style="display: block; margin-top: 10px">
          <span class="sp-coin-name" style="display:inline-block;color:#E3B936;width: 48%;vertical-align: middle">{{ coinName }}</span>
          <div class="bb_side" style="display: inline-block;vertical-align: middle;height: 26px;margin:0 5px">
            <span class="sp-coin-name" style="color:#52C41A;">⇀</span>
            <span v-if="huangjinvalue6" class="sp-coin-name" style="display:block;color: #FF4D4F;transform: scale(-1.0);height: 6px;margin-top: 2px">⇀</span>
          </div>
          <span class="sp-coin-name" style="color:#E3B936;">{{ coinName2 }}</span>
        </div>
        <div style="display: block;position: relative">
          <div style="width: 48%;margin-top: 5px;display: inline-block;">
            <el-input @input="checkinput($event, 'huangjinvalue5')" id="bb_input1" v-model="huangjinvalue5" style="max-width: 100%;display: block;font-size: 13px;"></el-input>
<!--            <span v-if="coinName" id="SJ_coin_max_balance_4" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;">{{ $t('robot.position_set_tip') }} {{balance_4}}</span>-->
            <span v-if="coinName" id="BB_coin_min_one" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;"></span>
            <span id="BB_coin_balance_1" style="display: block"></span>
          </div>
          <div style="width: 48%;margin-top: 5px;display: inline-block;position: absolute;right: 0">
            <el-input @input="checkinput($event, 'huangjinvalue6')" id="bb_input2" v-model="huangjinvalue6" style="max-width: 100%;display: block;font-size: 13px;"></el-input>
<!--            <span v-if="valueLin3" id="SJ_coin_max_balance_5" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;">{{ $t('robot.position_set_tip') }} {{balance_5}}</span>-->
            <span v-if="valueLin3" id="BB_coin_min_two" style="display: block;margin-top:5px;color: #AB54DB;word-break: break-word;"></span>
            <span id="BB_coin_balance_2" style="display: block"></span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
<!--    <el-button type="primary" class="go_and_open" :loading="isLoadingBB" style="width: 116px" @click="addBB(2)">{{ $t('robot.save') }}</el-button>-->
<!--    <el-button type="primary" class="go_and_open" :loading="isLoadingBB" style="width: 116px" @click="addBB(1)">{{ $t('robot.save_and_start') }}</el-button>-->

        <el-button class="go_and_open" type="primary" :loading="isLoadingUB" style="width: 100%;" @click="addBB()">{{ $t('robot.sure') }}{{ $t('robot.add') }}</el-button>
        <span class="error-tip" v-if="coinMessage" style="display: block;color: #FF4D4F;text-align: center;font-size: 14px;margin-top: 15px;"><span style="display: inline-block;vertical-align: middle;margin-right: 5px">*</span>{{ coinMessage }}</span>
        <span class="error-tip" v-if="errorCode == '2415'" style="display: block;color: #FF4D4F;text-align: center;font-size: 14px;margin-top: 15px;"><span style="display: inline-block;vertical-align: middle;margin-right: 5px">*</span>{{ $t('robot.sj_error_tip') }}</span>
  </span>
    </el-dialog>

    <!--UB-->
    <el-dialog
        class="dialog-mb-ub strategy"
        :title="$t('robot.add')+$t('robot.ub_trade')"
        :visible.sync="dialogUB"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        style="height: auto;overflow: unset;-webkit-overflow-scrolling: touch">
      <tooltip style="position: absolute; top: 23px; left: 126px;" :content="$t('robot.position_limit_description')" :width="'300px'" :imgWidth="'19px'"></tooltip>
      <el-divider></el-divider>
      <span>{{ $t('robot.select') }}<span v-if="robotType == 0">{{ $t('robot.binance') }}</span><span v-if="robotType == 1">{{ $t('robot.okx') }}</span>{{ $t('robot.supportive') }}{{ $t('robot.transaction_pair') }}<span> （USDT-{{coinName}}） </span></span>
<!--      <el-select v-model="valueLin3" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" ref='select6' @focus="closeReadOnly($event,6)" @hook:mounted="closeReadOnly($event,6)" @visible-change="closeReadOnly($event,6)" filterable :placeholder="$t('robot.please_select')" @change="checkSymbol($event)">-->
<!--        <el-option v-for="(item, i) in allCoinsUb" :key="item.coin_name + i" :label="item.coin_name" :value="item">-->
<!--        </el-option>-->
<!--      </el-select>-->

      <el-select v-model="coinName" :popper-append-to-body="false" value-key="coin_name" style="width: 100%; margin-top: 10px" ref='select6' @focus="closeReadOnly($event,6)" @hook:mounted="closeReadOnly($event,6)" @visible-change="closeReadOnly($event,6)" filterable :placeholder="$t('robot.please_select')" @change="checkSymbol($event)">
        <el-option v-for="(item, i) in allCoinsUb" :key="item.coin_name" :label="item.coin_name" :value="item"></el-option>
      </el-select>


      <span style="display:block;margin-top: 20px">{{ $t('robot.set_transaction_pair_position') }}</span>
      <div style="margin-top: 10px">
        <span class="sp-coin-name sj-sp-coin-name" style="color:#E3B936;">USDT</span>
        <span class="sp-coin-name" style="color:#52C41A;margin: 0px 10px">⇀</span>
        <span class="sp-coin-name" style="color:#E3B936;">{{ coinName }}</span>
      </div>
<!--      <el-input @input="checkinput($event, 'huangjinvalue4')" id="ub_input" class="sj-sp-coin-name" v-model="huangjinvalue4" style="margin-top: 5px" :placeholder="$t('robot.position_amount_must_be') + '≥3000'"></el-input>-->
      <el-input @input="checkinput($event, 'huangjinvalue4')" id="ub_input" class="sj-sp-coin-name" v-model="huangjinvalue4" style="margin-top: 5px" :placeholder="robotType == 0?$t('robot.position_amount_must_be')+'≥3000':$t('robot.enter_the_bin_amount')"></el-input>
<!--      <span id="SJ_coin_max_balance_6" style="display: block;margin-top: 5px;color: #AB54DB;word-break: break-word;">{{ $t('robot.no_more_than_available_balance') }} ({{ coinBalance | roundEightFilter }} USDT)</span>-->
<!--      <span style="display:block;margin-top: 20px">{{ $t('robot.ub_tip') }}<span v-if="robotType == 0">{{ $t('robot.binance') }}</span><span v-if="robotType == 1">{{ $t('robot.okx') }}</span>{{ $t('robot.available_balance') }}</span>-->

      <!--      <div style="margin-top: 20px">-->
      <!--        <el-checkbox v-model="addUBchecked">點擊確認添加即視爲同意</el-checkbox>-->
      <!--        <a style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer" @click="showUsageProtocol()">《Felton量化平台用戶協議》</a>-->
      <!--      </div>-->
      <span slot="footer" class="dialog-footer">
    <el-button class="go_and_open" type="primary" :loading="isLoadingUB" style="width: 100%;" @click="addUB()">{{ $t('robot.sure') }}{{ $t('robot.add') }}</el-button>
        <span class="error-tip" v-if="coinMessage" style="display: block;color: #FF4D4F;text-align: center;font-size: 14px;margin-top: 15px;"><span style="display: inline-block;vertical-align: middle;margin-right: 5px">*</span>{{ coinMessage }}</span>
  </span>
    </el-dialog>

    <!--contract-->
    <el-dialog
        class="dialog-mb-ub strategy contract"
        :title="(changeType==0?$t('robot.add'):$t('robot.add_position'))+$t('robot.grid_contract_strategy')"
        :visible.sync="dialogContract"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        style="height: auto;overflow: unset;-webkit-overflow-scrolling: touch">
      <tooltip style="position: absolute; top: 23px;" :style="{left: language=='English'?'290px': '170px'}" :content="$t('robot.hedge_create_dialog_tip')" :width="'300px'" :imgWidth="'19px'"></tooltip>
      <el-divider></el-divider>
      <div>

        <div v-if="changeType==0 && isTrialVersion== 'singleVersion'" style="text-align: right">
          <span v-if="robotType != 0" style="display: inline-block; font-size: 14px; cursor: pointer; padding: 2px 6px; border-radius: 4px" :style="{color: futureType==1?'#fefefe':'#AB54DB',background: futureType==1?'#AB54DB':''}" @click="switchFutureType(1)">U本位</span>
          <span style="display: inline-block; font-size: 14px; margin-left: 20px; cursor: pointer; padding: 2px 6px; border-radius: 2px" :style="{color: futureType==2?'#fefefe':'#AB54DB',background: futureType==2?'#AB54DB':''}" @click="switchFutureType(2)">币本位</span>
        </div>

        <span style="display:inline-block;font-size: 14px; margin-top: 10px">{{$t('robot.select_hedge_coin')}} {{ baseCoin ? '（' + baseCoin + 'USDT' + $t('robot.perpetual') + '10X）':'' }}</span>
        <el-select v-if="changeType==0" v-model="baseCoin" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" filterable :placeholder="$t('robot.please_select')" ref='select9' @focus="closeReadOnly($event,9)" @hook:mounted="closeReadOnly($event,9)" @visible-change="closeReadOnly($event,9)" @change="selectCoin()">
          <el-option :style="{color: (item.coin_name=='XRP'||item.coin_name=='FITFI'||item.coin_name=='WLD'||item.coin_name=='AGLD'||item.coin_name=='API3'||item.coin_name=='ALPHA'||item.coin_name=='CRV'||item.coin_name=='LIT'||item.coin_name=='XEM'||item.coin_name=='INJ'||item.coin_name=='AVAX')?'#FF4D4F':''}" v-for="(item, index) in hedgeCoinList" :key="item.coin_name" :label="item.coin_name+((item.coin_name=='XRP'||item.coin_name=='FITFI'||item.coin_name=='WLD'||item.coin_name=='AGLD'||item.coin_name=='API3'||item.coin_name=='ALPHA'||item.coin_name=='CRV')?'  (风险偏高/收益高)':'')" :value="item.coin_name">
            <div style="display: flex; align-items: center; justify-content: space-between">
              <span style="display: inline-block" :style="{color: (item.coin_name=='XRP'||item.coin_name=='FITFI'||item.coin_name=='WLD'||item.coin_name=='AGLD'||item.coin_name=='API3'||item.coin_name=='ALPHA'||item.coin_name=='CRV'||item.coin_name=='LIT'||item.coin_name=='XEM'||item.coin_name=='INJ'||item.coin_name=='AVAX')?'#FF4D4F':''}">{{item.coin_name+((item.coin_name=='XRP'||item.coin_name=='FITFI'||item.coin_name=='WLD'||item.coin_name=='AGLD'||item.coin_name=='API3'||item.coin_name=='ALPHA'||item.coin_name=='CRV'||item.coin_name=='LIT'||item.coin_name=='XEM'||item.coin_name=='INJ'||item.coin_name=='AVAX')?'  (风险偏高/收益高)':'')}}</span>
              <img v-if="item.coin_name == 'FITFI'" style="width: 14px; height: 18px" src="../../assets/img/help/hot.png">
            </div>
<!--            <span :class="[item.coin_name == baseCoin?'coin_color_light':'coin_color_deep']">{{ item.coin_name }}</span>-->
          </el-option>
        </el-select>
        <el-input v-else class="sj-sp-coin-name" :value="baseCoin+'USDT ' +this.$t('robot.perpetual')+'10x'" :disabled="true" style="margin-top: 5px"></el-input>
      </div>
      <div style="margin-top: 20px">
<!--        <el-tooltip effect="dark" :content="$t('robot.funds_set_tip')" placement="top">-->
<!--          <span style="display:inline-block;font-size: 14px;padding-bottom: 1px; border-bottom: #72777E 1px dashed;">{{ $t('robot.input_grid_contract_amount') }}</span>-->
<!--        </el-tooltip>-->
        <span style="display:inline-block;font-size: 14px;">{{ $t('robot.input_grid_contract_amount') }}</span>
<!--        <span v-if="baseCoin" style="display: inline-block; color: #AB54DB; font-size: 14px; vertical-align: middle">{{isTrialVersion?'（上限1200USDT）':''}}</span>-->
<!--        <tooltip :content="$t('robot.add_contract_tip')" :width="'300px'" :imgWidth="'19px'" style="display: inline-block; margin-left: 20px"></tooltip>-->
      </div>
      <el-input @input="checkinput($event, 'contract_amount', 3)" id="ub_input" class="sj-sp-coin-name" v-model="contract_amount" style="margin-top: 5px" :placeholder="changeType==0&&baseCoin?'≥'+ hedgePosition +'USDT':$t('robot.enter_the_bin_amount')"></el-input>
<!--      <p v-if="showHedgeSpaceTip" style="color: #FF4D4F; margin: 0">{{ $t('robot.input_multiple') }}</p>-->
      <div style="margin-top: 5px; display: flex; justify-content: space-between">
        <div>
          <span style="font-size: 12px;color: #AB54DB;display: inline-block;vertical-align: middle" :style="{color: parseFloat(contract_amount)>parseFloat(coinBalance)?'#FF4D4F':'#AB54DB'}">{{ $t('robot.cannot_more_balance') }}</span>
          <tooltip style="margin-left: 10px;vertical-align: middle;" :style="{left: language=='English'?'290px': '170px'}" :content="hedgeBalanceTip" :width="'300px'" :imgWidth="'16px'"></tooltip>
        </div>
        <span id="hedge_coin_balance_1" style="font-size: 12px;color: #72777E;">{{ $t('robot.available_balance') }}：{{coinBalance | roundTwoFilter}} USDT</span>
      </div>
      <span v-if="coinBalance==0" style="display: block; color: #E12020; font-size:12px; text-align: left; margin-top:5px">{{robotType==1?'请确保交易所交易账户有可用资金 !': '请确保交易所合约账户有可用资金 !'}}</span>

<!--      <el-radio-group v-model="radio">-->
<!--        <el-radio :label="3">备选项</el-radio>-->
<!--        <el-radio :label="9">备选项</el-radio>-->
<!--      </el-radio-group>-->

      <div v-if="isTrialVersion == 'singleVersion'" style="margin-top: 20px">
        <span style="display: block; font-size: 14px; font-weight: 500; color: #1E2329">方向：</span>
        <el-radio-group v-model="direction" style="margin-top: 15px">
          <el-radio :label="1">开多</el-radio>
          <el-radio :label="2">开空</el-radio>
        </el-radio-group>
      </div>

      <el-collapse class="stop_less_style" accordion style="margin-top: 20px" v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <span style="color: #1E2329">止损设置：</span>
<!--            <tooltip style="margin-left: 0px" :style="{left: language=='English'?'290px': '170px'}" content="（默认参数最大回撤60%）" :width="'300px'" :imgWidth="'16px'"></tooltip>-->
          </template>
          <div style="margin-top: 15px">
            <el-radio-group v-model="stopLoss" style="display: flex;justify-content: space-between;">
              <div class="uni-list-cell uni-list-cell-pd" style="">
                <div style="display: inline-block;vertical-align: middle;">
                  <el-radio class="stoploss" :label="100" color="#BF5FC1" style="">止损</el-radio>
                </div>
                <tooltip style="margin-left: 10px;vertical-align: middle;" :style="{left: language=='English'?'290px': '170px'}" content="策略风险较高时会平掉部分持仓，平掉的浮亏会转为实亏。可能会发生多次止损，最大止损100%的持仓。全部止损可有效防止爆仓，运行时间越长止损后剩余的仓位越多。" :width="'300px'" :imgWidth="'16px'"></tooltip>
              </div>
              <div v-if="isTrialVersion != 'trialVersion' && isTrialVersion != 'singleVersion'" class="uni-list-cell uni-list-cell-pd" style="">
                <div style="display: inline-block;vertical-align: middle;">
                  <el-radio class="stoploss" :label="5" color="#BF5FC1" style="">部分止损</el-radio>
                </div>
                <tooltip style="margin-left: 10px;vertical-align: middle;" :style="{left: language=='English'?'290px': '170px'}" :content="$t('robot.loss_warn_tip1')" :width="'300px'" :imgWidth="'16px'"></tooltip>
              </div>
              <div v-if="isTrialVersion != 'trialVersion'" class="uni-list-cell uni-list-cell-pd">
                <div style="display: inline-block;vertical-align: middle;">
                  <el-radio class="stoploss" :label="0" color="#BF5FC1" style="">不止损</el-radio>
                </div>
                <tooltip style="margin-left: 10px;vertical-align: middle;" :style="{left: language=='English'?'290px': '170px'}" :content="$t('robot.loss_warn_tip2')" :width="'300px'" :imgWidth="'16px'"></tooltip>
              </div>
            </el-radio-group>
            <div v-if="stopLoss != 0">
              <div style="margin-top: 10px">
                <span style="display: inline-block; font-size: 14px; vertical-align: middle">补亏损比例设置：</span>
                <tooltip style="margin-left: 0px;vertical-align: middle;" :style="{left: language=='English'?'290px': '170px'}" content="根据用户设定的补亏损比例，用每笔盈利去补亏损。（默认补亏损比例70%）" :width="'300px'" :imgWidth="'16px'"></tooltip>
              </div>
              <div style="position: relative">
                <el-input class="sj-sp-coin-name" v-model="percentageValue" style="margin-top: 5px" @input="checkPercentage" placeholder="0~100"></el-input>
                <span style="display: inline-block; font-size: 14px; position: absolute; right: 10px; top: 50%; transform: translate(0, -30%)">%</span>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

      <div v-if="changeType==0 && isTrialVersion != 'singleVersion'">
        <div style="margin-top: 20px; position: relative">
          <span style="display: inline-block; font-size: 14px; vertical-align: middle">请选择风险等级：</span>
          <tooltip style="margin-left: 0px;vertical-align: middle;" :style="{left: language=='English'?'290px': '170px'}" content="风险等级越高收益和风险越高，风险等级越低收益越低越稳定；
                  若仓位足够则默认风险等级为70%，仓位越大可调的风险等级范围越大，仓位越小可调的风险等级越小；
                  当前策略风险等级设置后不可修改，如需修改需创建新的策略。" :width="'300px'" :imgWidth="'16px'"></tooltip>
<!--          <span style="display: inline-block; position: absolute; top: 50%; right: 0px; transform: translate(0, -50%)">{{positionProportion}}%</span>-->
          <span style="display: inline-block; position: absolute; top: 50%; right: 0px; transform: translate(0, -50%)" :style="{color: riskValue==1?'#2CC197':riskValue==2?'#FFC025':'#DC4D4D'}">{{riskName}}</span>
        </div>

        <div style="position: relative">
<!--          <el-slider v-model="positionProportion" :marks="marks" :format-tooltip="formatTooltip" :min="minPositionRatio" :max="maxPositionRatio" :disabled="baseCoin!='DOT' && parseFloat(contract_amount)==parseFloat(hedgePosition)" @change="sliderPositionPro"></el-slider>-->
<!--          <span style="position: absolute; left: 0; font-size: 14px; margin-top: -10px">低</span>-->
<!--          <span style="position: absolute; right: 0; font-size: 14px; margin-top: -10px">高</span>-->
          <div style="display: flex;justify-content: space-between; margin-top:-4px; position: relative; margin-top: 10px;">
            <div style="background: linear-gradient(90deg, #2CC197 0%, #FFC025 50%, #DC4D4D 100%) !important; height: 6px; width: 100%; position: absolute;top: 50%; transform: translate(0, -50%);"></div>
            <div v-for="(item, index) in hedgeChangeItem" class="change_item_bg" :style="{background: item.checked==true?'#bf5fc1': parseFloat(minPositionRatio)>parseFloat(item.value) || parseFloat(contract_amount) < parseFloat(hedgePosition) || parseFloat(contract_amount)<=parseFloat(hedgePositionRatio) || !contract_amount || !baseCoin?'#A3A3A3':'#fefefe', borderColor: item.checked?'#bf5fc1':'#A3A3A3'}" @click="getItem(item)"></div>
            <!-- <text style="font-size: 12px">低</text>
            <text style="font-size: 12px">中</text>
            <text style="font-size: 12px">高</text> -->
          </div>
          <div style="display: flex; justify-content: space-between; margin-top: 3px; width: 100%;">
            <span style="font-size: 12px; width: 32px; margin-left: -5px;">50%</span>
            <span style="font-size: 12px; width: 32px;">60%</span>
            <span style="font-size: 12px; width: 32px;">70%</span>
            <span style="font-size: 12px; width: 32px;">80%</span>
            <span style="font-size: 12px; width: 32px; margin-right: -12px;">100%</span>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
    <el-button class="go_and_open" type="primary" :loading="isLoadingUB" style="width: 100%;" @click="changeType == 0?addContract(1):modifyFuturesSpace(2)">{{ $t('robot.sure') }}{{ changeType == 0?$t('robot.add'):$t('robot.add_position') }}</el-button>
  </span>
    </el-dialog>

    <!-- 期现策略 -->
    <el-dialog
        class="dialog-mb-ub strategy Term"
        :title="(changeType==0?$t('robot.add'):$t('robot.add_position'))+'期现AI交易'"
        :visible.sync="dialogTerm"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        style="height: auto;overflow: unset;-webkit-overflow-scrolling: touch">
      <tooltip style="position: absolute; top: 23px;" :style="{left: language=='English'?'290px': '170px'}" :content="$t('robot.hedge_create_dialog_tip')" :width="'300px'" :imgWidth="'19px'"></tooltip>
      <el-divider></el-divider>
      <div>
        <span style="display:inline-block;font-size: 14px">{{$t('robot.select_hedge_coin')}} {{ baseCoin ? '（' + baseCoin + 'USDT）':'' }}</span>
        <el-select v-if="changeType==0" v-model="baseCoin" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" filterable :placeholder="$t('robot.please_select')" ref='select10' @focus="closeReadOnly($event,10)" @hook:mounted="closeReadOnly($event,10)" @visible-change="closeReadOnly($event,10)" @change="selectTermCoin()">
          <el-option v-for="(item, index) in hedgeCoinList" :key="item.coin_name" :label="item.coin_name" :value="item.coin_name"></el-option>
        </el-select>
        <el-input v-else class="sj-sp-coin-name" :value="baseCoin+'USDT '" :disabled="true" style="margin-top: 5px"></el-input>
      </div>
      <div style="margin-top: 20px">
        <span style="display:inline-block;font-size: 14px;">{{ $t('robot.input_grid_contract_amount') }}</span>
      </div>
      <el-input @input="checkinput($event, 'contract_amount', 3)" id="ub_input" class="sj-sp-coin-name" v-model="contract_amount" style="margin-top: 5px" :placeholder="changeType==0&&baseCoin?'≥'+ hedgePosition +'USDT':$t('robot.enter_the_bin_amount')"></el-input>
      <div style="margin-top: 5px; display: flex; justify-content: space-between">
        <div style="width: 66%;">
          <div v-if="robotType==0" style="line-height: 14px; margin-top: 5px;">
            <span v-if="((parseFloat(contract_amount)*0.3)>parseFloat(coinBalance))" style="color: #E12020; font-size: 12px;display: inline-block;vertical-align: middle;">币安U本位合约账户的资金需大于设置仓位的30%</span>
            <span v-if="((parseFloat(contract_amount)*0.7)>parseFloat(balanceBnSpot))" style="color: #E12020; font-size: 12px;display: inline-block;vertical-align: middle;">币安现货账户的资金需大于设置仓位的70%</span>
          </div>
          <span v-else style="font-size: 12px;color: #AB54DB;display: block;vertical-align: middle" :style="{color: parseFloat(contract_amount)>parseFloat(coinBalance)?'#FF4D4F':'#AB54DB'}">{{ $t('robot.cannot_more_balance') }}</span>
<!--          <tooltip style="margin-left: 10px;vertical-align: middle;" :style="{left: language=='English'?'290px': '170px'}" :content="hedgeBalanceTip" :width="'300px'" :imgWidth="'16px'"></tooltip>-->
        </div>
        <div>
          <div v-if="robotType==0">
            <span style="display: block; color: #97979D; font-size: 12px;vertical-align: middle;">合约{{changeType == 0?$t('robot.available'):$t('robot.balance')}}：{{coinBalance?coinBalance:'0.00'}}</span>
            <span style="display: block; color: #97979D; font-size: 12px;vertical-align: middle;">现货{{changeType == 0?$t('robot.available'):$t('robot.balance')}}：{{balanceBnSpot?balanceBnSpot:'0.00'}}</span>
          </div>
          <span v-else style="font-size: 12px;color: #72777E;">{{ $t('robot.available_balance') }}：{{coinBalance | roundTwoFilter}} USDT</span>
        </div>
      </div>
<!--      <span v-if="coinBalance==0" style="display: block; color: #E12020; font-size:12px; text-align: left; margin-top:5px">{{robotType==1?'请确保交易所交易账户有可用资金 !': '请确保交易所合约账户有可用资金 !'}}</span>-->

      <span slot="footer" class="dialog-footer">
    <el-button class="go_and_open" type="primary" :loading="isLoadingUB" style="width: 100%;" @click="changeType == 0?addTermHedging(1):modifyTermSpace(2)">{{ $t('robot.sure') }}{{ changeType==0?$t('robot.add'):$t('robot.add_position') }}</el-button>
  </span>
    </el-dialog>

    <!-- 添加半自动策略 -->
    <el-dialog
        class="dialog-mb-ub strategy"
        :visible.sync="dialogMartinGrid"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        style="height: auto;overflow: auto;-webkit-overflow-scrolling: touch">
      <div slot="title">
        <span style="display: inline-block;line-height: 21px">{{$t('robot.add')+$t('robot.grid_strategy')}}</span>
        <tooltip :content="$t('robot.content4')" :width="'300px'" :imgWidth="'19px'" style="position: relative;display: inline-block"></tooltip>
      </div>
      <el-divider></el-divider>
<!--      <tooltip :content="$t('robot.content4')" :width="'300px'" :imgWidth="'19px'" style="position: absolute; top: 22px; left: 130px;"></tooltip>-->
<!--      <el-radio-group v-model="createType" style="margin-bottom: 20px;width: 100%;height: 30px">-->
<!--        <el-radio-button style="width: 50%" disabled label="0">智能創建</el-radio-button>-->
<!--        <el-radio-button style="width: 50%" label="1">手動創建</el-radio-button>-->
<!--      </el-radio-group>-->
      <el-select v-model="valueLin0" :popper-append-to-body="false" style="width: 100%; margin-top: 0px;color: #1E2329" filterable ref='select7' @focus="closeReadOnly($event,7)" @hook:mounted="closeReadOnly($event,7)" @visible-change="closeReadOnly($event,7)" :placeholder="$t('robot.please_select')+(robotType==0?$t('robot.binance'):$t('robot.okx'))+$t('robot.space_currency')" @change="getallSymbolsByCoin(3)">
        <el-option v-for="item in allCoins" :key="item.coin_name" :label="item.coin_name" :value="item.coin_name"></el-option>
<!--        <el-option v-if="testUser == 'false'" :key="1" label="USDT" value="USDT"></el-option>-->
      </el-select>
      <el-select v-model="valueLin3" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" filterable ref='select8' @focus="closeReadOnly($event,8)" @hook:mounted="closeReadOnly($event,8)" @visible-change="closeReadOnly($event,8)" :placeholder="$t('robot.please_select')+(robotType==0?$t('robot.binance'):$t('robot.okx'))+$t('robot.supportive')+$t('robot.transaction_pair')" @change="getallSymbolsByCoin4($event, 1)">
        <el-option v-for="item in allSymbolsByCoin" :key="item.target_coin+'/'+item.cost_coin" :label="item.target_coin+' / '+item.cost_coin" :value="item">
          <span :class="[item.target_coin == coinName2?'coin_color_light':'coin_color_deep']">{{ item.target_coin }}</span>
          <span style="color: #C0C4C8"> / </span>
          <span :class="[item.target_coin != coinName2?'coin_color_light':'coin_color_deep']">{{ item.cost_coin }}</span>
        </el-option>
      </el-select>
      <span v-show="valueLin3" style="display: block;color: black;margin-top: 5px;">{{ $t('robot.transaction_tips') }}<span style="color: #AB54DB"> {{quoteCoin}}</span></span>
      <div v-if="createType == 0">
        <div style="width: 100%;margin-top: 20px;color: #72777E;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400">
          <div style="width: 50%;display: inline-block">
            <span style="display: block">7日網格年化回測：</span>
            <span style="display: block;margin-top: 7px">價格區間({{ coinName2 }})</span>
          </div>
          <div style="width: 50%;display: inline-block">
            <span style="display: block">網格數量：</span>
            <span style="display: block;margin-top: 7px">預期單網格利潤率：({{ coinName2 }})</span>
          </div>
        </div>
        <div style="margin-top: 10px">
          <span v-if="coinName2" class="sp-coin-name sj-sp-coin-name" style="color:#E3B936;">{{ coinName2 }}</span>
          <span v-if="coinName2" class="sp-coin-name" style="color:#52C41A;margin: 0px 10px">⇀</span>
          <span class="sp-coin-name" style="color:#E3B936;">{{ coinName }}</span>
        </div>
        <div style="position:relative;"><!-- 投入金额 -->
          <el-input type="number" @input="checkinput($event, 'huangjinvalue4')" id="ub_input" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue4" style="margin-top: 5px;" placeholder="下單金額必須 ≥3000"></el-input>
          <span style="position:absolute;left: 10px;top: 18%;">投入金額</span>
          <span style="position:absolute;right: 10px;top: 22%;">{{ coinName2 }}</span>
          <el-slider v-model="amountValue" :marks="marks">
          </el-slider>
        </div>
        <div style="margin-top: 20px">
          <span>開啓馬丁策略：</span>
          <el-switch v-model="value_sw_md" active-value="0" inactive-value="1" @change="changeSwitch($event, value_sw_md)"> </el-switch>
          <div style="width: 100%;margin-top: 10px;color: #72777E;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400">
            <div style="display: inline-block; width: 50%;">
              <span style="display: block">跌多少加倉：1%</span>
              <span style="display: block;margin-top: 7px">最大加倉次數：8</span>
            </div>
            <div style="display: inline-block; width: 50%;">
              <span style="display: block">賺多少止盈：1%</span>
              <span style="display: block;margin-top: 7px">加倉倍投倍數：2.0</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="createType == 1">
        <div style="width: 100%;margin-top: 15px;color: #72777E;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400">
          <span style="display: block;color: #1E2329">1.{{ $t('robot.grid_section1') }}</span>
          <div style="position:relative;color: #72777E">
            <el-input @input="checkinput($event, 'huangjinvalue1', 2)" id="ub_input" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue1" style="margin-top: 5px;"></el-input>
            <span style="position:absolute;left: 10px;top: 32%;">{{ $t('robot.interval_lowest_price') }}</span>
            <span style="position:absolute;right: 10px;top: 34%;">{{ quoteCoin }}</span>
          </div>
          <div style="position:relative;color: #72777E">
            <el-input @input="checkinput($event, 'huangjinvalue2', 2)" id="ub_input" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue2" style="margin-top: 5px;"></el-input>
            <span style="position:absolute;left: 10px;top: 32%;">{{ $t('robot.interval_maximum_price') }}</span>
            <span style="position:absolute;right: 10px;top: 34%;">{{ quoteCoin }}</span>
          </div>
          <span v-show="wran_amount" style="display: none;color: #FF4D4F">{{ $t('robot.interval_tip') }}</span>
        </div>
        <div style="position:relative;width: 100%;margin-top: 15px;color: #72777E;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400">
          <span style="display: block;color: #1E2329">2.{{ $t('robot.grid_section2') }}</span>
          <div style="display:inline-block;position:relative;width: 76%"><!-- 網格數量 -->
            <el-input @input="checkinput($event, 'huangjinvalue3', 2)" id="ub_input" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue3" style="margin-top: 6px;" placeholder="2~150"></el-input>
            <el-tooltip placement="top" style="position:absolute;left: 10px;top: 32%;">
              <span slot="content" style="display: inline-block;width:300px">{{ $t('robot.content3') }}</span>
              <span style="color: #72777E;border-bottom: #72777E 1px dashed">{{ $t('robot.grid_num') }}</span>
            </el-tooltip>

            <span style="position:absolute;right: 10px;top: 34%;color: #72777E">{{ $t('robot.grid') }}</span>
          </div>
          <el-select class="ratioMode" v-model="ratioMode" style="display:inline-block;position:absolute;width: 23%;right: 0;top: 25px" filterable @change="getallSymbolsByCoin(3)">
            <el-option :key="0" :label="$t('robot.equal_difference')" value="0"></el-option>
            <el-option disabled :key="1" :label="$t('robot.equal_ratio')" value="1"></el-option>
          </el-select>
          <el-tooltip placement="top">
            <span slot="content" style="display: inline-block;width:300px">{{ $t('robot.grid_tip') }}</span>
            <span style="display: block;width: fit-content;">
              <span style="display: inline-block;margin-top: 5px;width: fit-content;border-bottom: #72777E 1px dashed;margin-right: 10px">{{ $t('robot.Estimated_ratio') }} <span style="color: #1E2329">{{ profitMargin?profitMargin:'--' }}</span></span>
              <span style="display:inline-block;">{{ratioMode==0?$t('robot.equal_difference'):$t('robot.equal_ratio')}}：{{ interval }}</span>
            </span>
          </el-tooltip>
          <span v-show="robotType == 0 && small && small<0.01" style="display: none;color: #FF4D4F">{{ $t('robot.grid_ratio_tip1') }}</span>
          <span v-show="robotType == 1 && small && small<0" style="display: none;color: #FF4D4F">{{ $t('robot.grid_ratio_tip2') }}</span>
        </div>
        <div style="position:relative;width: 100%;margin-top: 15px;color: #72777E;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400">
          <span style="display: block;color: #1E2329">3.{{ $t('robot.grid_section3') }}</span>
          <div style="position: relative">
            <el-select class="section3_select" v-model="inputCoin" :popper-append-to-body="false" style="width: 100%; margin-top: 5px;color: #1E2329;" placeholder="" @change="checkinputforSelect()">
              <el-option v-if="quoteCoin" :key="1" :label="quoteCoin" value="1"></el-option>
              <el-option v-if="coinName2" :key="2" :label="coinName2" value="2"></el-option>
              <el-option v-if="coinName2" :key="3" :label="quoteCoin + ' + ' + coinName2" value="3"></el-option>
            </el-select>
            <el-tooltip v-if="inputCoin" placement="top" style="position:absolute;left: 10px;top: 28%;">
              <span slot="content" style="display: inline-block;width:300px">{{ inputCoin == 3?$t('robot.input_together'):$t('robot.only_input') }}{{ inputCoin == 1?quoteCoin:inputCoin == 2?coinName2: quoteCoin+ ' + ' +coinName2}}</span>
              <span style="color: #72777E; border-bottom: dashed 1px">{{ $t('robot.input_coin') }}</span>
            </el-tooltip>
            <span v-else style="color: #72777E;position:absolute;left: 10px; top: 30%;">{{ $t('robot.input_coin') }}</span>
          </div>

          <div v-if="inputCoin == 1 || inputCoin == 3">
            <div style="position: relative">
              <el-input id="wran_single_grid" @input="checkinput($event, 'huangjinvalue4', 2)" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue4" style="margin-top: 5px;" :placeholder="$t('robot.input_amount_holder')"></el-input>
              <span style="position:absolute;left: 10px;top: 34%;color: #72777E">{{ $t('robot.input_amount') }}</span>
              <span style="position:absolute;right: 10px;top: 34%;color: #72777E">{{ quoteCoin }}</span>
            </div>
            <span v-show="robotType == 0 && huangjinvalue4 && parseFloat(huangjinvalue4)<$options.filters['roundEightFilter'](selectCoinItem.min_notional * huangjinvalue3 *1.5)" style="display: block;position:relative;margin-top:5px;color: #FF4D4F">{{ $t('robot.input_amount_tip1') }}{{ (selectCoinItem.min_notional * huangjinvalue3 * 1.5) | roundEightFilter }} {{ quoteCoin }}</span>
            <span v-show="robotType == 1 && selectCoinItem && huangjinvalue4 && parseFloat(huangjinvalue4)< $options.filters['roundEightFilter'](selectCoinItem.min_qty*huangjinvalue2*huangjinvalue3)" style="display: block;position:relative;margin-top:5px;color: #FF4D4F">{{ $t('robot.input_amount_tip1') }}{{ (selectCoinItem.min_qty * huangjinvalue2*huangjinvalue3) | roundEightFilter }} {{ quoteCoin }}</span>
            <span v-show="parseFloat(huangjinvalue4) > parseFloat(coinBalance)" style="display: block;position:relative;margin-top:5px;color: #FF4D4F">{{ $t('robot.input_amount_tip2') }}</span>
            <div style="position:relative;">
              <el-slider v-model="amountValue" :marks="marks" :format-tooltip="formatTooltip" @change="changeValue"></el-slider>
              <span style="display: block;position:absolute;top: 26px;left: -4px">0%</span><span style="display: inline-block;position:absolute; right: -4px;top: 26px">100%</span>
            </div>
            <span style="display:block;margin-top: 15px">{{ $t('robot.available') }}：<span v-if="quoteCoin=='USDT'" style="color: #1E2329">{{ coinBalance | roundEightFilter }} {{ quoteCoin }}</span><span v-else style="color: #1E2329">{{ coinBalance | roundEightFilter }} {{ quoteCoin }}</span></span>
          </div>

          <div v-if="inputCoin == 2 || inputCoin == 3">
            <div style="position: relative">
              <el-input id="wran_single_grid2" @input="checkinput($event, 'huangjinvalue5', 2)" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue5" style="margin-top: 5px;" :placeholder="$t('robot.input_amount_holder')"></el-input>
              <span style="position:absolute;left: 10px;top: 34%;color: #72777E">{{ $t('robot.input_amount') }}</span>
              <span style="position:absolute;right: 10px;top: 34%;color: #72777E">{{ coinName2 }}</span>
            </div>
            <span v-show="robotType == 0 && huangjinvalue5 && parseFloat(huangjinvalue5)<$options.filters['roundEightFilter'](selectCoinItem.min_qty_result * huangjinvalue3 *1.5)" style="display: block;position:relative;margin-top:5px;color: #FF4D4F">{{ $t('robot.input_amount_tip1') }}{{ ((this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5) | roundEightFilter }} {{ coinName2 }}</span>
            <span v-show="robotType == 1 && selectCoinItem && huangjinvalue5 && parseFloat(huangjinvalue5)<$options.filters['roundEightFilter'](selectCoinItem.min_qty*huangjinvalue3)" style="display: block;position:relative;margin-top:5px;color: #FF4D4F">{{ $t('robot.input_amount_tip1') }}{{ (this.selectCoinItem.min_qty * this.huangjinvalue3) | roundEightFilter }} {{ coinName2 }}</span>
            <span v-show="parseFloat(huangjinvalue5) > parseFloat(coinBalance2)" style="display: block;position:relative;margin-top:5px;color: #FF4D4F">{{ $t('robot.input_amount_tip2') }}</span>
            <div style="position:relative;">
              <el-slider v-model="amountValue2" :marks="marks" :format-tooltip="formatTooltip" @change="changeValue2"></el-slider>
              <span style="display: block;position:absolute;top: 26px;left: -4px">0%</span><span style="display: inline-block;position:absolute; right: -4px;top: 26px">100%</span>
            </div>
            <span style="display:block;margin-top: 15px">{{ $t('robot.available') }}：<span v-if="coinName2=='USDT'" style="color: #1E2329">{{ coinBalance2 | roundEightFilter }} {{ coinName2 }}</span><span v-else style="color: #1E2329">{{ coinBalance2 | roundEightFilter }} {{ coinName2 }}</span></span>
          </div>

        </div>
<!--        <div style="margin-top: 10px">-->
<!--          <span v-if="coinName2" class="sp-coin-name sj-sp-coin-name" style="color:#E3B936;">{{ quoteCoin }}</span>-->
<!--          <span v-if="coinName2" class="sp-coin-name" style="color:#52C41A;margin: 0px 10px">⇀</span>-->
<!--          <span class="sp-coin-name" style="color:#E3B936;">{{ coinName2 }}</span>-->
<!--        </div>-->
<!--        <el-divider></el-divider>-->
<!--        <span style="display: block;position: relative;cursor: pointer" @click="openMdList">止盈止損<i :class="[showMd?'el-icon-arrow-up':'el-icon-arrow-down']" style="position:absolute;right: 0;transition: 0.5s"></i></span>-->
        <div style="margin-top: 10px;">
          <el-collapse-transition>
            <div v-show="showMd">
              <div style="display:inline-block;position:relative;width: 100%"><!-- 投入金额 -->
                <el-input type="number" @input="checkinput($event, 'huangjinvalue4')" id="ub_input" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue4" style="margin-top: 5px;" placeholder="2~150"></el-input>
                <span style="position:absolute;left: 10px;top: 32%;">止盈價格</span>
                <span style="position:absolute;right: 10px;top: 34%;">{{quoteCoin}}</span>
              </div>
              <div style="display:inline-block;position:relative;width: 100%"><!-- 投入金额 -->
                <el-input type="number" @input="checkinput($event, 'huangjinvalue4')" id="ub_input" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue4" style="margin-top: 5px;" placeholder="2~150"></el-input>
                <span style="position:absolute;left: 10px;top: 32%;">止損價格</span>
                <span style="position:absolute;right: 10px;top: 34%;">{{ quoteCoin }}</span>
              </div>
            </div>
          </el-collapse-transition>
        </div>

<!--        <div style="margin-top: 20px">-->
<!--          <span>馬丁策略：</span>-->
<!--          <el-switch v-model="value_sw_md" active-value="0" inactive-value="1" @change="changeSwitch($event, value_sw_md)"> </el-switch>-->
<!--          <div style="width: 100%;margin-top: 10px;color: #72777E;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400">-->
<!--              <el-collapse-transition>-->
<!--                <div v-show="showMd">-->
<!--                  <div style="display:inline-block;position:relative;width: 100%">&lt;!&ndash; 投入金额 &ndash;&gt;-->
<!--                    <el-input type="number" @input="checkinput($event, 'huangjinvalue4')" id="ub_input" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue4" style="margin-top: 5px;" placeholder="2~150"></el-input>-->
<!--                    <span style="position:absolute;left: 10px;top: 32%;">跌多少加倉</span>-->
<!--                  </div>-->
<!--                  <div style="display:inline-block;position:relative;width: 100%">&lt;!&ndash; 投入金额 &ndash;&gt;-->
<!--                    <el-input type="number" @input="checkinput($event, 'huangjinvalue4')" id="ub_input" class="sj-sp-coin-name md-in-amount" v-model="huangjinvalue4" style="margin-top: 5px;" placeholder="2~150"></el-input>-->
<!--                    <span style="position:absolute;left: 10px;top: 32%;">賺多少止盈</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </el-collapse-transition>-->

<!--            <div style="display: inline-block; width: 50%;">-->
<!--              <span style="display: block">跌多少加倉：1%</span>-->
<!--              <span style="display: block;margin-top: 7px">最大加倉次數：8</span>-->
<!--            </div>-->
<!--            <div style="display: inline-block; width: 50%;">-->
<!--              <span style="display: block">賺多少止盈：1%</span>-->
<!--              <span style="display: block;margin-top: 7px">加倉倍投倍數：2.0</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="dialogMartinGrid = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="addGridVerify()">{{ $t('robot.create_strategy') }}</el-button>
  </span>
    </el-dialog>
    <!-- 下單二次確認彈出框 -->
    <el-dialog
        class="dialog-second"
        :title="$t('robot.second_confirm')"
        :visible.sync="dialogMartinGridSecond"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="1000px"
        style="height: auto;overflow: unset;padding-top: 0px">
      <el-divider></el-divider>

      <span>{{ quoteCoin }}<span style="display:inline-block;vertical-align:middle;font-size: 18px;margin-top:0px;">⇀</span>{{ coinName2 }}</span><span style="background-color: #F4F8FF; color: #AB54DB; margin-left: 5px; padding: 3px 8px;">{{ $t('robot.grid_strategy') }}</span>

      <div class="" style="margin-top: 12px">
        <div style="display:inline-block;width: 100%;height:90px;color: #72777E">
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;">{{ $t('robot.basic_settings') }}</span>
          <div style="display: block;margin-top: 0px;width: unset">
            <div style="display: inline-block;width: unset">
              <span style="display: inline-block">{{ $t('robot.Price_range') }}：</span>
              <span style="color: #1E2329">{{ huangjinvalue1 }}-{{ huangjinvalue2 }} {{ quoteCoin }}</span>
            </div>
            <div class="dialog-one" style="text-align: left;margin-top: 8px;display: inline-block;width: unset;margin-left: 30px">
              <span style="display: inline-block">{{ $t('robot.grid_mode') }}：</span>
              <span style="color: #1E2329">{{ ratioMode==0?$t('robot.equal_difference'):$t('robot.equal_ratio') }}</span>
            </div>
            <div class="dialog-Second">
              <div style="text-align: left;display: inline-block">
                <span style="display: inline-block">{{ $t('robot.number_of_grids') }}：</span>
                <span style="color: #1E2329">{{ huangjinvalue3 }}</span>
              </div>
              <div style="text-align: left;margin-top: 8px;display: inline-block;margin-left: 30px">
                <span style="display: inline-block">{{ $t('robot.input_amount') }}：</span>
                <span v-if="huangjinvalue4 && huangjinvalue5" style="color: #1E2329">{{ huangjinvalue4 }} {{ quoteCoin }}、{{ huangjinvalue5 }} {{ coinName2 }}</span>
                <span v-else style="color: #1E2329">{{ huangjinvalue4?huangjinvalue4:huangjinvalue5 }} {{ huangjinvalue4?quoteCoin:coinName2 }}</span>
              </div>
            </div>

          </div>
        </div>
<!--        <div style="display:inline-block;width: 43%;height:110px;color: #72777E">-->
<!--          <span style="display:block;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;">基礎設置</span>-->
<!--          <div style="display: inline-block;margin-top: 14px;width: unset">-->
<!--            <div style="display: block;width: unset">-->
<!--              <span style="display: inline-block">價格區間：</span>-->
<!--              <span style="color: #1E2329">203.00-213.00 U</span>-->
<!--            </div>-->
<!--            <div style="text-align: left;margin-top: 8px;display: block;width: unset">-->
<!--              <span style="display: inline-block">網格模式：</span>-->
<!--              <span style="color: #1E2329">等差</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="display: inline-block;margin-top: 14px;margin-left: 20px;width: unset">-->
<!--            <div style="text-align: left">-->
<!--              <span style="display: inline-block">網格數量：</span>-->
<!--              <span style="color: #1E2329">10</span>-->
<!--            </div>-->
<!--            <div style="text-align: left;margin-top: 8px">-->
<!--              <span style="display: inline-block">投入金額：</span>-->
<!--              <span style="color: #1E2329">0.096385 LTC</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="display:inline-flex;width: 18%;height:110px;border-left: 1px solid #EAECEF;justify-content: center; vertical-align: top;color: #72777E">-->
<!--          <div>-->
<!--            <span style="display:block;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;">止盈止損</span>-->
<!--            <div style="display:block;width:auto;margin-top: 14px;padding-right: 0;text-align: left">-->
<!--              <span>止盈價格：</span>-->
<!--              <span style="color: #1E2329">&#45;&#45;</span>-->
<!--            </div>-->
<!--            <div style="display:block;width:auto;margin-top: 8px;padding-right: 0;text-align: left">-->
<!--              <span>止損價格：</span>-->
<!--              <span style="color: #1E2329">&#45;&#45;</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="display:inline-block;width: 38%;height:110px;border-left:1px solid #EAECEF;vertical-align: top">-->
<!--          <span style="display:block;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;margin-left: 20px">馬丁策略</span>-->
<!--          <div style="display: inline-block;margin-top: 14px;margin-left: 20px;color: #72777E">-->
<!--            <div style="text-align: left;display: block;width: unset;">-->
<!--              <span>跌多少加倉：</span>-->
<!--              <span style="color: #1E2329">1%</span>-->
<!--            </div>-->
<!--            <div style="margin-top: 8px;display: block;width: unset;text-align:left;padding-right: 0">-->
<!--              <span>最大加倉次數：</span>-->
<!--              <span style="color: #1E2329">8</span>-->
<!--            </div>-->
<!--            <div style="margin-top: 8px;display: block;width: unset;">-->
<!--              <span>觸發價：</span>-->
<!--              <span style="color: #1E2329">&#45;&#45;</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="display: inline-block;margin-top: 14px;margin-left: 20px;color: #72777E">-->
<!--            <div >-->
<!--              <span>賺多少止盈：</span>-->
<!--              <span style="color: #1E2329">1.5%</span>-->
<!--            </div>-->
<!--            <div style="margin-top: 8px">-->
<!--              <span>加倉倍投倍數：</span>-->
<!--              <span style="color: #1E2329">10</span>-->
<!--            </div>-->
<!--            <div style="margin-top: 8px">-->
<!--              <span>漲破多少不再加倉：</span>-->
<!--              <span style="color: #1E2329">94428.7</span>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
      </div>

<!--      <div style="width: 100%;background-color: #F4F8FF;margin-top: 20px">-->
<!--      <span style="display:inline-block;padding: 10px;color: #AB54DB">投入網格的資金會從手動交易賬戶中隔離，請注意手動交易的倉位風險。</span>-->
<!--      </div>-->

      <el-checkbox v-model="isNoAsk" class="links remember"  text-color="#2233de" style="width: auto;margin-top: 20px" @change="noAsk">{{ $t('robot.donot_prompt') }}</el-checkbox>

      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="dialogMartinGridSecond = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" :loading="isLoadingGRID" @click="addGrid()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>

    <el-dialog
        class="dialog-second"
        :title="(robotInfo.state == 2?(this.deleteType ==3?this.$t(`robot.are_you_sure_del_grid`):this.$t(`robot.are_you_sure_del_martin`)):(deleteType == 3 ? $t('robot.delete')+$t('robot.grid') : $t('robot.stop')+$t('robot.martin'))+$t('robot.strategy'))"
        :visible.sync="dialogStopState"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="600px"
        style="height: auto;overflow: unset;padding-top: 0px">
      <el-divider></el-divider>

      <span v-if="robotInfo.state != 2" class="del_grid_span" style="display:inline-block;background-color: #F4F8FF;color: #AB54DB; padding: 10px">{{ deleteType == 3 ? $t('robot.del_grid_tip') : $t('robot.stop_martin_tip') }}</span>
<!--      <span v-else style="display:inline-block;margin-top: 10px">{{ deleteType == 3 ? $t('robot.del_grid_tip') : $t('robot.stop_martin_tip') }}</span>-->

      <div v-if="robotInfo.state != 2" class="" style="margin-top: 30px">
        <el-radio style="display: block" v-model="stopState" label="3">{{ deleteType == 3?$t('robot.delete'):$t('robot.stop') }}{{ $t('robot.strategy_cancel_order') }}</el-radio>
        <span v-if="deleteType == 3" style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio1') }}</span>
        <span v-if="deleteType == 4" style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio2') }}</span>
        <span v-if="stopState == '3'" style="display:block;color: #FF4D4F;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio2_tip') }}</span>
      </div>

      <div v-if="robotInfo.state != 2" class="" style="margin-top: 20px">
        <el-radio v-model="stopState" label="2">{{ deleteType == 3?$t('robot.delete'):$t('robot.stop') }}{{ $t('robot.strategy_no_cancellation') }}</el-radio>
        <span v-if="deleteType == 3" style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_no_cancellation_radio1') }}</span>
        <span v-if="deleteType == 4" style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_no_cancellation_radio2') }}</span>
      </div>

      <span v-if="robotInfo.state == 2" style="display:block;color: #606266;margin-top: 6px;">{{ deleteType == 3?$t('robot.del_grid_tip_stop'):$t('robot.stop_martin_tip_stop') }}</span>

      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="dialogStopState = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="delGrid()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>

    <!--添加上限提醒-->
    <el-dialog
        class="dialog-mb-ub"
        :visible.sync="dialogLimit1"
        width="600px"
        center
        style="height: auto;margin-top:80px;overflow: unset;">
      <img style="display: block;margin: 0 auto" src="../../assets/img/robot/hint.png">
      <span style="display:block;font-size: 25px;text-align: center;font-family: PingFangTC-Medium, PingFangTC;font-weight: 500;color: rgba(0, 0, 0, 0.85)">{{ $t('robot.transaction_pair')+$t('robot.quantity_transfinite') }}</span>
      <span v-if="clickRobotType == 'BB'" style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{ $t('robot.spot_limit_tip') }}</span>
      <span v-if="clickRobotType == 'grid'" style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{ $t('robot.semi_limit_tip') }}</span>
      <span v-if="clickRobotType == 'martin'" style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{ $t('robot.semi_limit_tip') }}</span>
      <span v-if="clickRobotType == 'hedge'" style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{ $t('robot.futures_limit_tip') }}</span>
<!--      <span v-if="chargeStyle == 3" style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{semiOrAuto == 0?$t('robot.quantity_transfinite_auto3'):$t('robot.quantity_transfinite_semi3')}}</span>-->
<!--      <span v-if="chargeStyle == 2" style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{semiOrAuto == 0?$t('robot.quantity_transfinite_auto2'):$t('robot.quantity_transfinite_semi2')}}</span>-->
<!--      <span v-if="chargeStyle == 1" style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{semiOrAuto == 0?$t('robot.quantity_transfinite_auto1'):$t('robot.quantity_transfinite_semi1')}}</span>-->
<!--      <span v-if="chargeStyle == 0" style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{semiOrAuto == 0?$t('robot.quantity_transfinite_auto0'):$t('robot.quantity_transfinite_semi0')}}</span>-->

      <span slot="footer" class="dialog-footer">
<!--    <el-button v-if="chargeStyle == 1"@click="dialogLimit1 = false">{{ $t('robot.next_time') }}</el-button>-->
    <el-button @click="dialogLimit1 = false">{{ $t('robot.i_know') }}</el-button>
<!--    <el-button v-if="chargeStyle == 1" class="go_and_open" @click="$router.push('grant_member')">{{ $t('robot.open_svip') }}</el-button>-->
  </span>
    </el-dialog>
    <!--svip提醒-->
    <el-dialog
        class="dialog-mb-ub"
        :visible.sync="dialogLimit2"
        width="600px"
        center
        style="height: auto;margin-top:80px;overflow: unset;">
      <img style="display: block;margin: 0 auto" src="../../assets/img/robot/hint.png">
      <span style="display:block;font-size: 25px;text-align: center;font-family: PingFangTC-Medium, PingFangTC;font-weight: 500;color: rgba(0, 0, 0, 0.85)">{{ $t('robot.sj_trade_tip') }}</span>
      <span style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{ $t('robot.sj_trade_content') }}</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogLimit2 = false">{{ $t('robot.next_time') }}</el-button>
    <el-button class="go_and_open" @click="$router.push('grant_member')">{{ $t('robot.open_svip') }}</el-button>
  </span>
    </el-dialog>
    <!--开通svip提醒-->
    <el-dialog
        class="dialog-mb-ub"
        :visible.sync="dialogLimit3"
        width="480px"
        center
        style="height: auto;margin-top:80px;overflow: unset;">
      <img style="display: block; width: 120px; margin: 0 auto" src="../../assets/img/robot/service.svg">
      <span style="display:block;font-size: 25px;text-align: center;font-family: PingFangTC-Medium, PingFangTC;font-weight: 500;color: rgba(0, 0, 0, 0.85)">{{ $t('robot.open_svip') }}</span>
      <span style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: rgba(0, 0, 0, 0.45);">{{ $t('robot.open_svip_tip') }}</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogLimit3 = false">{{ $t('robot.i_know') }}</el-button>
  </span>
    </el-dialog>

    <el-dialog
        class="dialog-mb-ub"
        :visible.sync="dialogLimit4"
        width="480px"
        center
        style="height: auto;margin-top:80px;overflow: unset;">
      <span style="display:block;font-size: 16px;margin-top:20px;text-align: center;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;word-break: break-word;">{{ $t('robot.test_is_full_tip') }}</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogLimit4 = false">{{ $t('robot.i_know') }}</el-button>
  </span>
    </el-dialog>

    <!-- 暂停策略 -->
    <el-dialog
        class="details_dialog"
        :title="$t('robot.pause') + $t('robot.grid_contract_strategy')"
        :visible.sync="dialogHePauseState"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="600px"
        style="height: auto;overflow: unset;padding-top: 0px">
      <el-divider></el-divider>
      <div style="padding: 0 20px">
        <span class="del_grid_span" style="display:inline-block;background-color: #F4F8FF;color: #AB54DB; padding: 10px; width: -webkit-fill-available;">{{ $t('robot.pause_contract_tip') }}</span>
        <span style="display:inline-block;margin: 20px 0">{{ $t('robot.liquidation_risk_tip') }}</span>
        <el-checkbox style="display: block;" v-model="check_he_pause"><span style="display: inline-block;word-break: break-word;white-space: break-spaces;">{{ $t('robot.pause_risk_tip')}}</span></el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="dialogHePauseState = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="pauseContract()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>

    <!-- BB修改仓位 -->
    <el-dialog
        class="edit_bb_dialog"
        :title="$t('robot.add_position_long')"
        :visible.sync="BBaddPosition"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        style="height: auto;overflow: unset;padding-top: 0px">
      <el-divider></el-divider>
      <div class="edit_bb_all" style="">
        <div v-if="detailsItem.space" style="width: 100%;display: block">
          <span>{{detailsItem.coin_one}}<span v-if="detailsItem.space">({{ $t('robot.old') }}：{{ detailsItem.space }})</span></span>
          <div style="display: inline-block;float: right" v-if="!detailsItem.space_two">
            <span class="sp-coin-name" style="color:#52C41A;">⇀</span>
            <span style="margin-left: 10px">{{detailsItem.coin_two}}<span v-if="detailsItem.space_two">({{ $t('robot.old') }}：{{ detailsItem.space_two }})</span></span>
          </div>
          <el-input v-if="detailsItem.space" style="margin-top: 10px" @input="checkinput($event, 'huangjinvalue1')" class="sj-sp-coin-name" v-model="huangjinvalue1" :placeholder="detailsItem.coin_one + $t('robot.trading_position')"></el-input>
          <span v-if="detailsItem.space && (!huangjinvalue1 || parseFloat(huangjinvalue1)>=BBminPositionOne)" id="coin_one" style="display: block;color: #AB54DB;margin-top: 5px"></span>
          <span v-else-if="detailsItem.space && huangjinvalue1 && parseFloat(huangjinvalue1)<BBminPositionOne" style="display: block;color: #FF4D4F;margin-top: 5px"></span>
<!--          <span v-else-if="detailsItem.space && huangjinvalue1 && parseFloat(huangjinvalue1)<BBminPositionOne" style="display: block;color: #FF4D4F;margin-top: 5px">{{ $t('robot.position_amount_must_be')+'≥'+ BBminPositionOne}}</span>-->
        </div>
        <div v-if="detailsItem.space && detailsItem.space_two" class="bb_side" style="display: block;vertical-align: middle;height: 26px;margin:0 5px;width: fit-content">
          <span class="sp-coin-name" style="color:#52C41A;">⇀</span>
          <span class="sp-coin-name" style="display:block;color: #FF4D4F;transform: scale(-1.0);height: 6px;margin-top: 2px">⇀</span>
        </div>
        <div v-if="detailsItem.space_two" style="width: 100%;display: block">
          <div v-if="!detailsItem.space">
            <span>{{detailsItem.coin_one}}<span v-if="detailsItem.space">({{ $t('robot.old') }}：{{ detailsItem.space }})</span></span>
            <span v-if="huangjinvalue2" class="sp-coin-name" style="display:block;color: #FF4D4F;transform: scale(-1.0);height: 6px;margin-top: 2px">⇀</span>
          </div>
          <span>{{detailsItem.coin_two}}<span v-if="detailsItem.space_two">({{ $t('robot.old') }}：{{ detailsItem.space_two }})</span></span>
          <el-input v-if="detailsItem.space_two" style="margin-top: 10px" @input="checkinput($event, 'huangjinvalue2')" class="sj-sp-coin-name" v-model="huangjinvalue2" :placeholder="detailsItem.coin_two + $t('robot.trading_position')"></el-input>
          <span v-if="detailsItem.space_two && (!huangjinvalue2 || parseFloat(huangjinvalue2)>=BBminPositionTwo)" id="coin_two" style="display: block;color: #AB54DB;margin-top: 5px"></span>
          <span v-else-if="detailsItem.space_two && huangjinvalue2 && parseFloat(huangjinvalue2)<BBminPositionTwo" style="display: block;color: #FF4D4F;margin-top: 5px"></span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="BBaddPosition = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="BBaddPositionConfirm()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>

    <!-- 删除BB交易 -->
    <el-dialog
        class="edit_bb_dialog"
        :title="$t('robot.del') + $t('robot.bb_trade')"
        :visible.sync="delBbStrategy"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="600px"
        style="height: auto;overflow: unset;padding-top: 0px">

      <el-divider></el-divider>
      <div style="padding: 0 0px">
        <div v-if="robotInfo.state != 2" class="" style="margin-top: 20px">
          <el-radio style="display: block" v-model="stopState" label="3">{{ $t('robot.delete') }}{{ $t('robot.strategy_cancel_order') }}</el-radio>
          <span style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio1') }}</span>
          <span v-if="stopState == '3'" style="display:block;color: #FF4D4F;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio2_tip') }}</span>
        </div>

        <div v-if="robotInfo.state != 2" class="" style="margin-top: 20px">
          <el-radio v-model="stopState" label="2">{{ $t('robot.delete') }}{{ $t('robot.strategy_no_cancellation') }}</el-radio>
          <span style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_no_cancellation_radio1') }}</span>
        </div>

        <span v-if="robotInfo.state == 2" style="display:block;color: #606266;margin-top: 6px;">{{ $t('robot.delete_sj_for_closed_bot') }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="delBbStrategy = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="delBbNew()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>

    <!-- 删除期现交易 -->
    <el-dialog
        class="edit_bb_dialog"
        :title="$t('robot.del') + $t('robot.futures_spot_strategy')"
        :visible.sync="delTermDialog"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="600px"
        style="height: auto;overflow: unset;padding-top: 0px">

      <el-divider></el-divider>
      <div style="padding: 0 0px">
        <div v-if="robotInfo.state != 2" class="" style="margin-top: 20px">
          <el-radio v-model="stopTermState" label="1">{{ $t('robot.delete') }}{{ $t('robot.strategy_no_cancellation') }}</el-radio>
          <span style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_no_cancellation_radio1') }}</span>
        </div>

        <div v-if="robotInfo.state != 2" class="" style="margin-top: 20px">
          <el-radio style="display: block" v-model="stopTermState" label="2">{{ $t('robot.delete') }}{{ $t('robot.strategy_cancel_order') }}</el-radio>
          <span style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio1') }}</span>
          <span v-if="stopTermState == '2'" style="display:block;color: #FF4D4F;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio2_tip') }}</span>
        </div>

        <span v-if="robotInfo.state == 2" style="display:block;color: #606266;margin-top: 6px;">{{ $t('robot.delete_sj_for_closed_bot') }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="delTermDialog = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="deleteTermTrue()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>

    <!-- 合约确认框 -->
    <el-dialog
        class="risk_dialog"
        :visible.sync="riskDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        width="630px"
        style="height: auto;overflow: unset;padding-top: 0px">

      <div style="padding: 0 0px; width: 93%; margin: 0 auto">
<!--        <img style="display: block;margin: 0 auto" src="../../assets/img/robot/risk_icon.png">-->
        <span style="font-weight: 500; margin: 0 auto; font-size: 18px; text-align: center; display: block; color: rgba(0, 0, 0, 0.85)">{{ $t('robot.hedging_contract_trading_risk_title') }}</span>
        <ul style="font-size: 15px; line-height: 26px; margin-top: 30px">
          <li style="list-style: disc">{{ $t('robot.warn1') }}</li>
          <li style="list-style: disc">{{ $t('robot.warn2') }}</li>
          <li style="list-style: disc">{{ $t('robot.warn3') }}</li>
          <li style="list-style: disc; color: #E12020;">极端情况可能会出现爆仓情况，比如自己动资金/订单、交易所卡顿、拔网线、极端行情（黑天鹅）</li>
          <li style="list-style: disc; color: #E12020;">极端情况可能会出现止损失效，比如交易所卡顿、拔网线、极端行情（黑天鹅）</li>
        </ul>
        <div style="margin-top: 20px; display: flex;">
          <el-checkbox style="display: inline-block;vertical-align:top;" v-model="iKnowRisk"></el-checkbox>
          <span style="display: inline-block; margin-left: 10px; vertical-align: middle">{{ $t('robot.warn4') }}<span class="risl_dialog_xieyi" style="" @click="gotoUserUsageProtocol()">{{ $t('robot.Click_view') }}{{ $t('robot.UPlus_Smart_furures_Trading_Agreement') }}</span></span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align: center;">
    <el-button class="go_and_open" type="primary" style="display: block; text-align: center; width: 80%;margin: 10px auto 0;font-size: 16px" @click="gotoCreate()">{{ $t('robot.enter_trade') }}</el-button>
    <p class="" type="primary" style="display: block; text-align: center; width: fit-content; border-bottom: 1px #1E2329 solid; font-size: 14px;margin: 10px auto;cursor: pointer" @click="riskDialog = false">{{ $t('robot.no_trade_leave') }}</p>
  </span>
    </el-dialog>

    <el-dialog
        class="dialog-mb-ub"
        :title="$t('robot.minimum_position')"
        :visible.sync="dialogMinPosition"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="clearMinPositionData"
        width="600px"
        style="height: auto;overflow: unset;-webkit-overflow-scrolling: touch">
      <el-divider></el-divider>
      <div style="">
        <div style="display: flex; justify-content: space-between; align-items: center">
          <div>
            <span style="display: inline-block; font-size: 16px; cursor: pointer" :style="{color: minPositionRobotType==0?'#AB54DB':''}" @click="setMinpositionType(0)">Binance</span>
            <span style="display: inline-block; font-size: 16px; margin-left: 20px; cursor: pointer" :style="{color: minPositionRobotType==1?'#AB54DB':''}" @click="setMinpositionType(1)">Okx</span>
          </div>
          <div>
            <div>
              <span style="display: inline-block; font-size: 15px; cursor: pointer" :style="{color: isTrialVersion != 'singleVersion'?'#AB54DB':''}" @click="setTypeForSpeed(1)">普通版</span>
              <span style="display: inline-block; font-size: 15px; margin-left: 20px; cursor: pointer" :style="{color: isTrialVersion == 'singleVersion'?'#AB54DB':''}" @click="setTypeForSpeed(2)">单向版</span>
            </div>
            <div v-if="isTrialVersion == 'singleVersion'" style="text-align: center; margin-top: 5px">
              <span v-if="change_robot_type == 1" style="display: inline-block; font-size: 15px; margin-right: 15px; cursor: pointer" :style="{color: futureType==1?'#AB54DB':''}" @click="setTypeForHedge(1)">U本位</span>
              <span style="display: inline-block; font-size: 15px; cursor: pointer" :style="{color: futureType==2?'#AB54DB':''}" @click="setTypeForHedge(2)">币本位</span>
            </div>
          </div>
        </div>
<!--        <span style="display:inline-block;font-size: 14px; margin-top: 20px">{{$t('robot.select_hedge_coin')}} {{ baseCoin ? '（' + baseCoin + 'USDT' + '）':'' }}</span>-->
<!--        <el-select v-if="hedgeType == 2" v-model="baseCoin" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" filterable :placeholder="$t('robot.please_select')" ref='select9' @focus="closeReadOnly($event,9)" @hook:mounted="closeReadOnly($event,9)" @visible-change="closeReadOnly($event,9)" @change="selectCoin()">-->
<!--          <el-option v-for="(item, index) in hedgeCoinList" :key="item.coin_name" :label="item.coin_name" :value="item.coin_name"></el-option>-->
<!--        </el-select>-->
<!--        <el-select v-if="hedgeType == 1" v-model="baseCoin" :popper-append-to-body="false" style="width: 100%; margin-top: 10px" filterable :placeholder="$t('robot.please_select')" ref='select9' @focus="closeReadOnly($event,9)" @hook:mounted="closeReadOnly($event,9)" @visible-change="closeReadOnly($event,9)" @change="selectTermCoin()">-->
<!--          <el-option v-for="(item, index) in hedgeCoinList" :key="item.coin_name" :label="item.coin_name" :value="item.coin_name"></el-option>-->
<!--        </el-select>-->
<!--        <el-input v-else class="sj-sp-coin-name" :value="baseCoin+'USDT ' +this.$t('robot.perpetual')+'10x'" :disabled="true" style="margin-top: 5px"></el-input>-->
        <div style="margin-bottom: 20px; margin-top: 10px">
          <el-table v-if="hedgeCoinList && hedgeCoinList.length>0" :data="hedgeCoinList" border height="700" :default-sort = "{prop: 'position', order: 'ascending'}" style="">
            <el-table-column
                prop="coin"
                label="交易币种">
            </el-table-column>
            <el-table-column
                prop="position"
                sortable
                label="最小仓位">
            </el-table-column>
          </el-table>
          <span v-else style="font-size: 16px; width: 100%; display: block; text-align: center; line-height: 60px; border: 1px #ffffff solid; border-radius: 8px; height: 60px;">暂无更多数据</span>
<!--          <span v-if="hedgeType==1 && robotType == 1" style="display: block; font-size: 14px; margin-top: 12px;">* 推荐币种：FITFI、WLD、AAVE</span>-->
        </div>
      </div>
<!--      <span style="font-size: 14px; display: block; margin: 20px 0 0 2px">{{$t('robot.input_min_position')}}：{{hedgePosition}}USDT</span>-->
<!--      <span slot="footer" class="dialog-footer" style="margin-top: 50px">-->
<!--    <el-button class="go_and_open" type="primary" :loading="isLoadingUB" style="width: 100%;" @click="dialogMinPosition = false">{{ $t('robot.i_know') }}</el-button>-->
<!--  </span>-->
    </el-dialog>

    <el-dialog
        class="dialog-mb-ub"
        :title="$t('robot.minimum_position')"
        :visible.sync="dialogShowTermPosition"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        style="height: auto;overflow: unset;-webkit-overflow-scrolling: touch">
      <el-divider></el-divider>
      <div style="margin-bottom: 20px">
        <div style="display: flex; justify-content: space-between; align-items: center">
          <div>
            <span style="display: inline-block; font-size: 16px; cursor: pointer" :style="{color: minPositionRobotType==0?'#AB54DB':''}" @click="setMinpositionTypeForTerm(0)">Binance</span>
            <span style="display: inline-block; font-size: 16px; margin-left: 20px; cursor: pointer" :style="{color: minPositionRobotType==1?'#AB54DB':''}" @click="setMinpositionTypeForTerm(1)">Okx</span>
          </div>
          <div>
            <span style="display: inline-block; font-size: 16px; cursor: pointer" :style="{color: minPositiontermType==2?'#AB54DB':''}" @click="setTypeForTerm(2)">普通版</span>
            <span style="display: inline-block; font-size: 16px; margin-left: 20px; cursor: pointer" :style="{color: minPositiontermType==3?'#AB54DB':''}" @click="setTypeForTerm(3)">闪电版</span>
            <span style="display: inline-block; font-size: 16px; margin-left: 20px; cursor: pointer" :style="{color: minPositiontermType==4?'#AB54DB':''}" @click="setTypeForTerm(4)">BTC 版</span>
          </div>
        </div>
        <el-table v-if="tableData && tableData.length>0" :data="tableData" border height="700" :default-sort = "{prop: 'position', order: 'ascending'}" style="margin-top: 10px">
          <el-table-column
              prop="coin"
              label="交易币种">
          </el-table-column>
          <el-table-column
              prop="position"
              sortable
              label="最小仓位">
          </el-table-column>
        </el-table>
        <span v-else style="font-size: 16px; width: 100%; display: block; text-align: center; line-height: 60px; border: 1px #ffffff solid; border-radius: 8px; height: 60px;">暂无更多数据</span>
<!--        <span v-if="hedgeType==1 && robotType == 1" style="display: block; font-size: 14px; margin-top: 12px;">* 推荐币种：FITFI、WLD、AAVE</span>-->
      </div>
    </el-dialog>

    <el-dialog
        class="risk_dialog"
        :visible.sync="reducePosition"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        width="630px"
        style="height: auto;overflow: unset;padding-top: 0px">
      <div style="padding: 0 0px; width: 93%; margin: 0 auto">
        <img style="display: block;margin: 0 auto" src="../../assets/img/robot/warnandbg_icon.png">
        <div style="padding: 20px 40px 30px;">
          <span style="display: inline-block; font-weight: 700;font-size: 16px; color:#DC4D4D; line-height: 24px;">请注意！<span style="font-weight: 400; font-size: 14px; color: #1E2329">减仓会提高策略风险，尤其浮亏较大的时候，可能会发生止损产生实亏。</span></span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align: center;">
    <el-button class="go_and_open" type="primary" style="display: block; text-align: center; width: 80%;margin: 10px auto 0;font-size: 16px" @click="reducePositionMenu()">确认修改</el-button>
    <p class="" type="primary" style="display: block; text-align: center; width: fit-content; border-bottom: 1px #1E2329 solid; font-size: 14px;margin: 10px auto;cursor: pointer" @click="reducePosition = false">再想想</p>
  </span>
    </el-dialog>

    <Martin_add_dialog @updateList="getUserStrategyListAll" ref="MartinAddDialogRef"></Martin_add_dialog>
  </div>
</template>

<script>
import {
  addSupremeHedge,
  checkSymbolDuplicate,
  delBb, deleteSupremeHedge,
  delGrid, delMartinStrategy,
  delSj,
  delUb,
  getAddBb,
  getAddGrid,
  getAddSj,
  getAddUb,
  getAllCoins, getAllCoinsUb,
  getAllSymbolsByCoin,
  getAllSymbolsByCoin2,
  getBb, getCoinBalance,
  getGrid,
  getGridStrategyHistoryList, getIsTestUser, getIsModuleTestUser, getMartinStrategy,
  getSj,
  getSymbolCount, getFuturesMinSpaceAll,
  getTickerPrice, addTermHedging, modify_term_hedging, deleteTermHedging, minimumOrderListTerm,
  getUb, getUserStrategyListAll, modifyBb, startAutomaticStrategy,getFuturesMinSpace, minimum_spot_hedging,
  startOrPauseGridStrategy, startOrPauseMartinStrategy, startSupremeHedge, stopSupremeHedge, modifyFuturesSpace
} from "../../server/robot";
import termokxlist from "./coinlist/term_coin_list/okx_list.json"
import termbnlist from "./coinlist/term_coin_list/bn_list.json"
import Tooltip from "../common/tooltip";
import {Base64} from "js-base64";
import Martin_add_dialog from "./martin/Martin_add_dialog";
import {MartinAddDialogMixin} from "../../mixin/mixin";
import {Loading, Message} from "element-ui";
import {add, multiply} from "../../plugin/math";
// import mathjs from "mathjs";
import * as mathjs from "mathjs"

export default {
  name: "Robot_strategy",
  components:{
    Tooltip,
    Martin_add_dialog
  },
  mixins:[MartinAddDialogMixin],
  inject:['updataState'],
  data() {
    return {
      // content1: this.$t('robot.content1'),
      // content3: this.$t('robot.content3'),
      // content4: this.$t('robot.content4'),
      switchValue: '1', // 1當前  2歷史
      strategyList: [],
      strategyList_bn: [],
      strategyList_ok: [],
      selectValue: '全部策略類型',
      selectValues: '',
      celueTree: [{id:0, value: '全部策略類型'},{id: 1,value: '全自動策略',treeList: [{id: 0,value: '黃金三角',},{id: 1,value: 'BB',},{id: 2,value: 'UB',},]},{id: 2,value: '半自動策略',treeList: [{id: 0,value: '網格',}]}],
      defaultProps: {
        children: "treeList",
        label: "value",
      },
      // 策略弹出框
      dialogHuangjin: false,
      dialogUB: false,
      dialogBB: false,
      dialogContract: false,
      dialogTerm: false,
      dialogMartinGrid: false,
      dialogMartinGridSecond: false,
      dialogStopState: false,
      dialogHePauseState: false,
      BBaddPosition: false,
      check_he_pause: false,
      deleteType:3,
      addUBchecked: true, // 添加UB选框
      dialogLimit1: false, //上限提示框
      dialogLimit2: false, //上限提示框
      dialogLimit3: false, //开通svip提示
      dialogLimit4: false,
      semiOrAuto: 0, // 0全自動，1半自動
      isNoAsk: false, // 二次確認框是否顯示
      stopState: '3', // 2:不撤單  3：撤單
      stopTermState: 1, // 1:删除不撤单 2：删除并撤单
      allCoins: [], // 獲取所有幣種
      allCoinsUb: [], // 獲取Ub下所有幣種
      allSymbolsByCoin: [], //根据币种获取交易对
      allSymbolsByCoin2: [], //根据币种获取交易对
      delGridItem: {}, //刪除網格臨時存儲
      delHedgeItem: {}, //刪除合约臨時存儲
      delTermItem: {}, //删除期现临时存储
      delBBItem:{}, //刪除BB臨時存儲
      addHuangjinNum: 0,
      addBBNum: 0,
      addUBNum: 0,
      surplusNum:3,
      semiSurplusNum:8,
      // tradingVolume: 0, //已填加的交易对数量 自動
      // tradingVolumeOfSemi: 0, //已填加的交易对数量 手動

      spotFullCount: 0,
      spotFullUsable: 0,
      swapCount: 0,
      swapUsable: 0,
      spotSemiCount: 0,
      spotSemiUsable: 0,

      APIstatus:'',
      // addJiaoyiSj: '添加交易',
      // addJiaoyiUb: '添加交易',
      // addJiaoyiBb: '添加交易',
      coinName: '', // 币种名称
      coinName2: '', // 交易对中币种名称
      coinName3: '', // 交易对中币种名称
      valueLin0: '',
      valueLin1: '',
      valueLin2: '',
      valueLin3: '',
      quoteCoin: '',
      inputCoin: '',
      coin_min_one: 0,
      coin_min_two: 0,
      coin_min_three: 0,
      selectCoinItem:{},
      huangjinvalue1: '',
      huangjinvalue2: '',
      huangjinvalue3: '',
      huangjinvalue4: '',
      huangjinvalue5: '',
      huangjinvalue6: '',
      contract_amount: '',
      balance_1: '',
      balance_2: '',
      balance_3: '',
      balance_4: '',
      balance_5: '',
      balance_6: '',
      balance_7: '',
      balanceBnSpot: '',
      girdList:[],
      profitMargin: '', //利潤率
      interval: 0, //間隔數
      small: '', //最小利潤率
      createType: 1, //0：智能創建 1：手動創建
      ratioMode: '0', // 0等差 1等比
      robotType: '', // 0：Binance 1：okex
      chargeStyle: 0, // 是否为1 vip，如果2 为 svip
      showMd: false,
      APIkEY: '',
      userInfo: {},
      robotInfos: {},
      amountValue: 0,
      amountValue2: 0,
      wran_amount: false,
      value_sw_md: 0, // 0:開啓 1：關閉
      marks: {
        0: '',
        25: '',
        50: '',
        75: '',
        100: ''
      },
      pageNum: 1,
      capitalListTotal: 10,
      totalRow: 0,
      isMobile: false,
      testUser:false, //是否為公測用戶
      module: 'futures_btc',
      changes:0,
      changeImage: require('../../assets/img/robot/robot_strategy/concise.svg'),
      GridStrategyHistoryList: [],
      coinBalance: 0,
      coinBalance2: 0,
      isLoadingSJ: false,
      isLoadingBB: false,
      isLoadingUB: false,
      isLoadingGRID: false,
      strategyLoading: false,
      requestTimes: 0,
      showCheck: false,
      checkAll: false,
      isIndeterminate: false,
      checkList:[],
      autoStrategyList: [],
      selectAutoStrategyList: [],
      count: 0,
      errorCode: '',
      language: '',
      mouseShow: false,
      coinMessage: '',
      ubMinValue: 0, // UB最小值  校验
      detailsItem: {},
      BBminPositionOne: '',
      BBminPositionTwo: '',
      delBbStrategy: false,
      stopLoss: 100,
      stopSurplus: 0,
      stopLossList: [{checked: false, type: 1, value: '1%'}, {checked: false, type: 2, value: '2%'}, {checked: false, type: 3, value: '3%'}, {checked: false, type: 4, value: '4%'}, {checked: true, type: 5, value: '5%'}, {checked: false, type: 6, value: '6%'}, {checked: false, type: 7, value: '7%'}, {checked: false, type: 8, value: '8%'}],
      stopSurplusList: [{checked: false, type: 20, value: '20%'}, {checked: false, type: 50, value: '50%'}, {checked: false, type: 100, value: '100%'}, {checked: false, type: 200, value: '200%'}, {checked: false, type: 300, value: '300%'}, {checked: false, type: 400, value: '400%'}],
      riskDialog: false,
      showHedgeSpaceTip: false,
      iKnowRisk: false,
      // items:[{name:this.$t('robot.stop_loss'), value: '100'}, {name:'部分止损', value: '5'}, {name: this.$t('robot.not_stop_loss'), value: '0'}],
      // current: 0,
      hedgeCoinList: [{coin_name: 'DOT'}, {coin_name: 'BTC'}, {coin_name: 'ETH'}],
      // hedgeCoinList: [{coin_name: 'DOT'}, {coin_name: 'BTC'}],
      baseCoin: '',
      hedgePosition: '20000',
      hedgePositionRatio: 800,
      hedgeBalanceTip: '',
      hedgeDetailItem: {},
      changeType: 0,
      changeItem: {},
      instType: '', // 1现货(交易)账户，2合约账户，
      futuresType: '',//合约类型，1U本位，2币本位
      balanceType: 0,
      percentageValue: 70,
      positionProportion: 100,
      minPositionRatio: 1,
      maxPositionRatio: 100,
      hedgeChangeItem: [{id: 1, value: 50, checked: false}, {id: 2, value: 60, checked: false}, {id: 3, value: 70, checked: true}, {id: 4, value: 80, checked: false}, {id: 5, value: 100, checked: false}],
      riskName: '中',
      riskValue: 2,
      isTrialVersion: '',
      clickRobotType: '',
      dialogMinPosition: false,
      minPositionRobotType: '',
      hedgePauseItem: {},
      reducePosition: false,
      hedgeType: 1,
      dialogShowTermPosition: false,
      tableData: [],
      change_robot_type: 1,
      termType: 2,
      minPositiontermType: 2,
      delTermDialog: false,
      activeNames: [],
      direction: 1,
      futureType: ''
    }
  },
  props:{
    name: {type: String, required: true},
    userInfos:{type: Object, required: true},
    robotInfo:{type: Object, required: true},
    // surplusNumIn:{type: Number, required: true},
    // semiSurplusNumIn:{type: Number, required: true},
    APIstatusA:{type: String, required: true}
  },

  watch: {
    name(val) {
      this.APIkEY = val
    },
    // surplusNumIn(val){
    //   this.surplusNum = val
    // },
    // semiSurplusNumIn(val) {
    //   this.semiSurplusNum = val
    // },
    APIstatusA(val){
      this.APIstatus = val
    },
    userInfos(val){
      this.userInfo = val
      this.chargeStyle = this.userInfo.charge_style2
      if (this.chargeStyle == 1) {
        this.surplusNum = 3
        this.semiSurplusNum = 8
      } else if(this.chargeStyle == 2){
        this.surplusNum = 8
        this.semiSurplusNum = 12
      } else if(this.chargeStyle == 3){
        this.surplusNum = 12
        this.semiSurplusNum = 20
      } else if(this.chargeStyle == 0) {
        this.surplusNum = 1
        this.semiSurplusNum = 3
      }
    },
    robotInfo(val){
      this.robotInfos = val
      this.robotType = this.robotInfos.robot_type
      this.getAllCoin();
      this.getUserStrategyList()
      // if (this.robotType == 0){
      //   this.getUserStrategyListAll()
      // } else {
      //   this.getUserStrategyListAll_ok()
      // }
      this.switchValue = '1'
    },
  },

  created() {
    this.$cookies.config(60*60*24*30*3, '')
    if (this.$util._isMobile()){
      if (this.$util._isMobile()[0] == 'iPad'){
        this.isMobile = false
        return;
      }
      this.isMobile = true;
    } else {
      this.isMobile = false
    }
  },

  activated() {
    if (this.robotType === '') return
    if (this.switchValue == '2'){
      this.requestTimes = 10
      this.getTickerPrice(10)
    }else{
      this.requestTimes = 10
      if(this.robotType == 0){
        this.getUserStrategyListAll(10)
      }else {
        this.getUserStrategyListAll_ok(10)
      }
    }
    this.cancelCheck()
  },

  mounted() {
    this.language = localStorage.getItem('language')
    if (this.$cookies.get('noAsk')){
      let noAsk = this.$cookies.get('noAsk');
      if (Boolean(noAsk) == true) {
        this.dialogMartinGridSecond = false;
      }
    }
    if(this.$cookies.get('changes')){
      this.changes = this.$cookies.get('changes')
    }
    if (this.changes == 0) {
      this.changeImage = require('../../assets/img/robot/robot_strategy/concise.svg')
    } else {
      this.changeImage = require('../../assets/img/robot/robot_strategy/details.svg')
    }
    this.APIkEY = this.name
    // this.userInfo = this.userInfos
    this.chargeStyle = this.userInfo.charge_style2
    this.initData()
    // this.getIsModuleTestUser()
  },

  methods: {
    initData(){
      var stopSurplus = {}
      stopSurplus.checked = true
      stopSurplus.type = 0
      stopSurplus.value = this.$t('robot.not_stop_surplus')
      this.stopSurplusList.push(stopSurplus)

      var stopLoss = {}
      stopLoss.checked = false
      stopLoss.type = 0
      stopLoss.value = this.$t('robot.not_stop_loss')
      this.stopLossList.push(stopLoss)

      // this.getAllCoin();
      this.getSymbolCount();
      // if (this.strategyList.length == 0){
      //   this.getUserStrategyListAll();
      // }
    },

    formatTooltip(val){
      return val+'%'
    },

    switchCelue(){
      this.strategyList = []
      if (this.switchValue == 1){
        this.getUserStrategyList()
        // this.getUserStrategyListAll()
      }else {
        this.getTickerPrice();
      }
    },

    handleNodeClick(data, node, nodeData) {
      this.selectValue = data.value
      this.selectValues = data.value
      this.$refs.selectblur.blur();
    },

    checkinput(value, name, num){       // 通过正则过滤小数点后两位
      let inputValue =
          ("" + value) // 第一步：转成字符串
              .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              // .replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,10})/g)[0] || "" // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      if(parseInt(inputValue)>9007199254740991){
        inputValue = 9007199254740991
      }
      switch (name) {
        case 'huangjinvalue1':
          this.huangjinvalue1 = inputValue
          break;
        case 'huangjinvalue2':
          this.huangjinvalue2 = inputValue
          break;
        case 'huangjinvalue3':
          this.huangjinvalue3 = num==2?parseInt(inputValue):inputValue
          break;
        case 'huangjinvalue4':
          this.huangjinvalue4 = inputValue
          break;
        case 'huangjinvalue5':
          this.huangjinvalue5 = inputValue
          break;
        case 'huangjinvalue6':
          this.huangjinvalue6 = inputValue
          break;
        case 'contract_amount':
          this.contract_amount = inputValue
          break;
      }
      if (num == 2){
        if (this.huangjinvalue1 && this.huangjinvalue2 && this.huangjinvalue3){
          let intervalValue = (this.huangjinvalue2 - this.huangjinvalue1)/this.huangjinvalue3
          this.small = (intervalValue - (parseFloat(this.huangjinvalue2)+parseFloat(this.huangjinvalue2)-intervalValue)*0.001)/(this.huangjinvalue2-intervalValue)
          let max = (intervalValue - (parseFloat(this.huangjinvalue1) + parseFloat(this.huangjinvalue1)+intervalValue)*0.001)/this.huangjinvalue1
          this.profitMargin = (this.small*100).toFixed(2)+'%' + '~' + (max*100).toFixed(2)+'%'
        }else{
          this.profitMargin = ''
        }
        if (this.huangjinvalue1 && this.huangjinvalue2){
          if (parseFloat(this.huangjinvalue1) >= parseFloat(this.huangjinvalue2)) {
            this.wran_amount = true
          }else{
            this.wran_amount = false
          }
        }else{
          this.wran_amount = false
        }
        if(!this.huangjinvalue3){
          this.huangjinvalue3 = ''
        }
        if (this.huangjinvalue3){
          if (this.robotType == 0) {
            // if (this.inputCoin == 1){
            //   document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + 15 * this.huangjinvalue3
            // } else if(this.inputCoin == 2){
            //   document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + 15 * this.huangjinvalue3
            // } else if(this.inputCoin == 3){
            //   document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + 15 * this.huangjinvalue3
            //   document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + 15 * this.huangjinvalue3
            // }

            if (this.inputCoin == 1){
              document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter']((this.selectCoinItem.min_notional)*this.huangjinvalue3*1.5)
            } else if(this.inputCoin == 2){
              document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter']((this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5)
            } else if(this.inputCoin == 3){
              document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter']((this.selectCoinItem.min_notional)*this.huangjinvalue3*1.5)
              document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter']((this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5)
            }

            // document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + 15 * this.huangjinvalue3
            // if (this.inputCoin == 2 || this.inputCoin == 3){
            //   document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + 15 * this.huangjinvalue3
            // }
          } else {
            if (this.inputCoin == 1){
              document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue2*this.huangjinvalue3)
            } else if(this.inputCoin == 2){
              document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue3)
            } else if(this.inputCoin == 3){
              document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue2*this.huangjinvalue3)
              document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue3)
            }


            // document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + (this.selectCoinItem.min_qty * this.huangjinvalue2*this.huangjinvalue3)
            // if (this.inputCoin == 2 || this.inputCoin == 3){
            //   document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + (this.selectCoinItem.min_qty * this.huangjinvalue3)
            // }
          }
        }
        if (this.coinBalance != 0) {
          this.amountValue = parseInt(this.huangjinvalue4 / this.coinBalance * 100)
        }

        if (this.coinBalance2 != 0) {
          this.amountValue2 = parseInt(this.huangjinvalue5 / this.coinBalance2 * 100)
        }
        if (this.huangjinvalue1 && this.huangjinvalue2 && this.huangjinvalue3) {
          this.interval = this.$options.filters['roundEightFilter'](((parseFloat(this.huangjinvalue2)-parseFloat(this.huangjinvalue1))/parseFloat(this.huangjinvalue3)).toFixed(12))
        }else{
          this.interval = 0
        }
      }
      if(num == 3){
        if (!this.contract_amount){
          this.showHedgeSpaceTip = false
        }
        if(this.contract_amount >= 300){
          if((this.contract_amount % 300) != 0){
            this.showHedgeSpaceTip = true
          } else {
            this.showHedgeSpaceTip = false
          }
        }

        var position = value
        if(position){
          var value1 = Math.ceil(parseFloat(this.hedgePositionRatio)/parseFloat(position) * 100)
          // this.minPositionRatio = value1>100?100:value1==100?1:value1
          this.minPositionRatio = value1>100?100:value1
          // this.positionProportion = value1<70?70:value1>=100?100:value1
          this.positionProportion = value1<70?70:value1>70&&value1<=80?80:value1>80&&value1<=100?100:value1>=100?100:value1

          console.log('asdqwe2qe12qeqwa', this.positionProportion)

          if(this.positionProportion == 100){
            for (var i = 0; i < this.hedgeChangeItem.length; i++) {
              if(this.hedgeChangeItem[i].value == this.positionProportion){
                this.hedgeChangeItem[i].checked = true
              } else {
                this.hedgeChangeItem[i].checked = false
              }
            }
          } else {
            for (var i = 0; i < this.hedgeChangeItem.length; i++) {
              if(this.hedgeChangeItem[i].value == this.positionProportion){
                this.hedgeChangeItem[i].checked = true
              } else {
                this.hedgeChangeItem[i].checked = false
              }
            }
          }
          console.log('qweqweqweqweqwe', this.positionProportion)
          if(parseFloat(this.positionProportion) < 60){
            this.riskName = '低'
            this.riskValue = 1
          } else if(parseFloat(this.positionProportion) >= 60 && parseFloat(this.positionProportion) <= 80) {
            this.riskName = '中'
            this.riskValue = 2
          } else if(parseFloat(this.positionProportion) > 80) {
            this.riskName = '高'
            this.riskValue = 3
          }
        }
      }
    },

    checkinputforSelect(){
      if (this.huangjinvalue3){
        this.timer = setTimeout(() => {
          if (this.robotType == 0) {
            if (this.inputCoin == 1){
              document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter']((this.selectCoinItem.min_notional)*this.huangjinvalue3*1.5)
            } else if(this.inputCoin == 2){
              document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter']((this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5)
            } else if(this.inputCoin == 3){
              document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter']((this.selectCoinItem.min_notional)*this.huangjinvalue3*1.5)
              document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter']((this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5)
            }
          } else {
            if (this.inputCoin == 1){
              document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue2*this.huangjinvalue3)
            } else if(this.inputCoin == 2){
              document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue3)
            } else if(this.inputCoin == 3){
              document.getElementById('wran_single_grid').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue2*this.huangjinvalue3)
              document.getElementById('wran_single_grid2').placeholder = this.$t('robot.order_amount_must_be')+'≥' + this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue3)
            }
          }
        }, 1)
      }
    },
    gotoVip(){
      if (this.APIstatus){
        this.$message.warning(this.APIstatus)
        return
      }
      this.$router.push('grant_member')
    },
    openAddDialog1(){
      // if (!this.APIkEY){
      //   this.$message.warning(this.APIstatus)
      //   return;
      // }
      this.errorCode = ''
      if (!this.isNoApi()){
        return;
      }
      this.semiOrAuto = 0;
      if(this.chargeStyle == 1){
        this.dialogLimit2 = true;
        return;
      }else if(this.chargeStyle == 2 || this.chargeStyle == 3){
        if ((this.surplusNum - this.tradingVolume) < 3){
          this.dialogLimit1 = true;
          return;
        }
      }
      this.coinName= '';
      this.coinName2= '';
      this.coinName3= '';
      this.valueLin1 = '';
      this.valueLin2 = '';
      this.valueLin3 = '';
      this.huangjinvalue1 = '';
      this.huangjinvalue2 = '';
      this.huangjinvalue3 = '';
      this.allSymbolsByCoin= [];
      this.allSymbolsByCoin2 = [];
      this.dialogHuangjin = true
      this.timer = setTimeout(() => {
        document.getElementById('sj_input1').placeholder=this.$t('robot.enter_content')
        document.getElementById('sj_input2').placeholder=this.$t('robot.enter_content')
        document.getElementById('sj_input3').placeholder=this.$t('robot.enter_content')
        document.getElementById('SJ_coin_balance_1').innerText = ''
        document.getElementById('SJ_coin_balance_2').innerText = ''
        document.getElementById('SJ_coin_balance_3').innerText = ''
      }, 1)
    },
    openAddDialog2(){
      // if (!this.APIkEY){
      //   this.$message.warning(this.APIstatus)
      //   return;
      // }
      this.clickRobotType = 'BB'
      this.errorCode = ''
      if (!this.isNoApi()){
        return;
      }
      // if (this.robotInfo.is_exclusive != 1){
      //   this.$message.warning('非平台专属用户，不可添加现货交易！')
      //   return
      // }
      this.semiOrAuto = 0;
      if (this.checkJiaoyidui()){
        this.dialogLimit1 = true;
        return;
      }
      this.coinName= '';
      this.coinName2= '';
      this.coinName3= '';
      this.valueLin1 = '';
      this.valueLin2 = '';
      this.valueLin3 = '';
      this.huangjinvalue5 = '';
      this.huangjinvalue6 = '';
      this.allSymbolsByCoin= [];
      this.coinMessage = '';
      if(this.robotInfo.robot_type == 0){
        this.instType = '1'
        this.futuresType = ''
      } else {
        this.instType = ''
        this.futuresType = ''
      }
      this.dialogBB = true
      this.timer = setTimeout(() => {
        document.getElementById('bb_input1').placeholder=this.$t('robot.position_currency_position')
        document.getElementById('bb_input2').placeholder=this.$t('robot.transaction_currency_position')
        document.getElementById('BB_coin_balance_1').innerText = ''
        document.getElementById('BB_coin_balance_2').innerText = ''
      }, 1)
    },
    openAddDialog3(){
      // if (!this.APIkEY){
      //   this.$message.warning(this.APIstatus)
      //   return;
      // }
      if (!this.isNoApi()){
        return;
      }
      // if (this.robotInfo.is_exclusive != 1){
      //   this.$message.warning('非平台专属用户，不可添加现货交易！')
      //   return
      // }
      this.semiOrAuto = 0;
      if (this.checkJiaoyidui()){
        this.dialogLimit1 = true;
        return;
      }
      this.coinName= '';
      this.coinName2= '';
      this.coinName3= '';
      this.huangjinvalue4 = '';
      this.coinMessage = '';
      this.timer = setTimeout(() => {
        if (this.robotType == 1){
          document.getElementById('ub_input').placeholder = this.$t('robot.enter_the_bin_amount')
        }
      })
      this.getAllCoinsUb()
      this.dialogUB = true;
      this.getCoinBalance('USDT');
    },
    openAddDialog4(isTrialVersion){
      // if (isTrialVersion == 'speedVersion') {
      //   this.$message.warning('闪电版策略正在升级中，请先选择其他版本策略！')
      //   return;
      // }
      if (!this.isNoApi()){
        return;
      }
      this.clickRobotType = 'hedge'
      this.semiOrAuto = 0;
      if (this.checkJiaoyiduiSwap()){
        this.dialogLimit1 = true;
        return;
      }
      this.coinName= '';
      this.coinName2= '';
      this.coinName3= '';
      this.huangjinvalue4 = '';
      this.getAllCoinsUb()
      this.contract_amount = ''
      if (isTrialVersion == 'singleVersion') {
        this.stopLoss = 0
      } else {
        this.stopLoss = 100
      }
      this.percentageValue = 70
      this.futureType = ''
      if (isTrialVersion == 'trialVersion'){
        this.isTrialVersion = 'trialVersion'
        this.activeNames = []
        this.futureType = ''
        this.hedgeCoinList = [{coin_name: 'ICP'}, {coin_name: 'AGLD'}, {coin_name: 'API3'}, {coin_name: 'ALPHA'}, {coin_name: 'CRV'}, {coin_name: 'BNB'}, {coin_name: 'TON'}, {coin_name: 'CRO'}, {coin_name: 'FIL'}]
      } else if(isTrialVersion == 'speedVersion'){
        this.isTrialVersion = 'speedVersion'
        this.activeNames = []
        this.futureType = ''
        this.getCoinList()
      } else if(isTrialVersion == 'singleVersion'){
        this.isTrialVersion = 'singleVersion'
        this.activeNames = ['1']
        if (this.robotType == 0) {
          this.futureType = 2
        } else {
          this.futureType = 1
        }
        this.getCoinList()
      } else {
        this.isTrialVersion = ''
        this.getCoinList()
      }
      for (var i = 0; i < this.stopLossList.length; i++) {
        if(this.stopLoss == this.stopLossList[i].type){
          this.stopLossList[i].checked = true
        } else {
          this.stopLossList[i].checked = false
        }
      }
      this.stopSurplus = 0
      this.showHedgeSpaceTip = false
      this.iKnowRisk = false
      // if(this.$cookies.get('iKnowRisk') && this.$cookies.get('iKnowRisk') == 1){
      //   this.dialogContract = true;
      // } else {
      //   this.riskDialog = true;
      // }
      this.riskDialog = true;
      this.baseCoin = ''
      this.hedgePosition = ''
      this.changeType = 0
      // if(this.robotType == 0){
      //   this.baseCoin = 'DOT'
      //   this.hedgePosition = '800'
      // } else {
      this.baseCoin = ''
      this.hedgePosition = ''
      this.positionProportion = 100
      this.hedgeChangeItem = [{id: 1, value: 50, checked: false}, {id: 2, value: 60, checked: false}, {id: 3, value: 70, checked: true}, {id: 4, value: 80, checked: false}, {id: 5, value: 100, checked: false}]
      this.riskName = '中'
      this.riskValue = 2
        // this.hedgeCoinList = [{coin_name: 'DOT'}, {coin_name: 'BTC'}]
      // }
      if(this.robotType == 0){
        this.hedgeBalanceTip = this.$t('robot.hedge_balance_tip_b')
      } else if(this.robotType == 1){
        this.hedgeBalanceTip = this.$t('robot.hedge_balance_tip_o')
      }
      if(this.robotInfo.robot_type == 0){
        this.instType = '2'
        this.futuresType = '1'
      } else {
        this.instType = ''
        this.futuresType = ''
      }
      this.getFuturesMinSpace()
      this.getCoinBalance('USDT');
      // this.dialogLimit4 = true
    },
    changeStrategy(item){ // 修改仓位
      this.changeItem = item
      this.changeType = 1
      this.changeStatus = 0
      this.type = 'center'
      this.changeRed = false
      this.balanceType = 0
      this.percentageValue = item.loss_transfer_rate
      this.contract_amount = item.space
      this.baseCoin = item.coin_two
      console.log(this.baseCoin)
      this.stopLoss = parseInt(item.stop_loss)
      // for (let i = 0; i < this.items.length; i++) {
      //   if (this.items[i].value === this.stopLoss) {
      //     this.current = i;
      //     break;
      //   }
      // }
      if(this.robotInfo.robot_type == 0){
        this.instType = '2'
        this.futuresType = '1'
      } else {
        this.instType = ''
        this.futuresType = ''
      }
      this.getFuturesMinSpace()
      this.getCoinBalance('USDT');
      this.dialogContract = true
    },
    changeTermStrategy(item){ // 修改仓位
      this.changeItem = item
      this.changeType = 1
      this.changeStatus = 0
      this.type = 'center'
      this.changeRed = false
      this.balanceType = 0
      // this.percentageValue = item.loss_transfer_rate
      this.contract_amount = item.totalSpace
      this.baseCoin = item.coin_two
      console.log(this.baseCoin)
      // this.stopLoss = parseInt(item.stop_loss)
      // for (let i = 0; i < this.items.length; i++) {
      //   if (this.items[i].value === this.stopLoss) {
      //     this.current = i;
      //     break;
      //   }
      // }
      // if(this.robotInfo.robot_type == 0){
      //   this.instType = '2'
      //   this.futuresType = '1'
      // } else {
      //   this.instType = ''
      //   this.futuresType = ''
      // }
      this.getFuturesMinSpace()
      this.getCoinBalance('USDT');
      this.dialogTerm = true
    },
    openAddDialog5(){
      if (!this.isNoApi()){
        return;
      }
      this.termType = 2
      this.clickRobotType = 'hedge'
      this.semiOrAuto = 0;
      // if (this.checkJiaoyiduiSwap()){
      //   this.dialogLimit1 = true;
      //   return;
      // }
      this.coinName= '';
      this.coinName2= '';
      this.coinName3= '';
      this.huangjinvalue4 = '';
      this.contract_amount = ''

      this.stopSurplus = 0
      this.baseCoin = ''
      this.hedgePosition = ''
      this.changeType = 0
      this.balanceType = 1
      if(this.robotType == 0){
        this.hedgeBalanceTip = this.$t('robot.hedge_balance_tip_b')
      } else if(this.robotType == 1){
        this.hedgeBalanceTip = this.$t('robot.hedge_balance_tip_o')
      }
      if(this.robotType == 0) {
        this.instType = 2
        this.getCoinBalanceSpot('USDT')
      } else {
        this.instType = ''
      }
      this.getCoinBalance('USDT');
      this.getCoinList()
      if (this.robotType == 1) {
        this.hedgeCoinList = termokxlist
      } else if(this.robotType == 0) {
        this.hedgeCoinList = termbnlist
      }
      this.dialogTerm = true
    },
    openAddDialog6(){
      if (!this.isNoApi()){
        return;
      }
      this.termType = 3
      this.clickRobotType = 'hedge'
      this.semiOrAuto = 0;
      // if (this.checkJiaoyiduiSwap()){
      //   this.dialogLimit1 = true;
      //   return;
      // }
      this.coinName= '';
      this.coinName2= '';
      this.coinName3= '';
      this.huangjinvalue4 = '';
      this.contract_amount = ''

      this.stopSurplus = 0
      this.baseCoin = ''
      this.hedgePosition = ''
      this.changeType = 0
      this.balanceType = 1
      if(this.robotType == 0){
        this.hedgeBalanceTip = this.$t('robot.hedge_balance_tip_b')
      } else if(this.robotType == 1){
        this.hedgeBalanceTip = this.$t('robot.hedge_balance_tip_o')
      }
      if(this.robotType == 0) {
        this.instType = 2
        this.getCoinBalanceSpot('USDT')
      } else {
        this.instType = ''
      }
      this.getCoinBalance('USDT');
      this.getCoinList()
      if (this.robotType == 1) {
        this.hedgeCoinList = termokxlist
      } else if(this.robotType == 0) {
        this.hedgeCoinList = termbnlist
      }
      this.dialogTerm = true
    },
    openAddDialog7(){
      if (!this.isNoApi()){
        return;
      }
      this.termType = 4
      this.clickRobotType = 'term'
      this.semiOrAuto = 0;
      // if (this.checkJiaoyiduiSwap()){
      //   this.dialogLimit1 = true;
      //   return;
      // }
      this.coinName= '';
      this.coinName2= '';
      this.coinName3= '';
      this.huangjinvalue4 = '';
      this.contract_amount = ''

      this.stopSurplus = 0
      this.baseCoin = ''
      this.hedgePosition = ''
      this.changeType = 0
      this.balanceType = 1
      if(this.robotType == 0){
        this.hedgeBalanceTip = this.$t('robot.hedge_balance_tip_b')
      } else if(this.robotType == 1){
        this.hedgeBalanceTip = this.$t('robot.hedge_balance_tip_o')
      }
      if(this.robotType == 0) {
        this.instType = 2
        this.getCoinBalanceSpot('USDT')
      } else {
        this.instType = ''
      }
      this.getCoinBalance('USDT');
      this.getCoinList()
      if (this.robotType == 1) {
        this.hedgeCoinList = termokxlist
      } else if(this.robotType == 0) {
        this.hedgeCoinList = termbnlist
      }
      this.dialogTerm = true
    },
    openAddDialogGrid() {
      // if (!this.APIkEY) {
      //   this.$message.warning(this.APIstatus)
      //   return;
      // }
      if (!this.isNoApi()){
        return;
      }
      // if (this.robotInfo.is_exclusive != 1){
      //   this.$message.warning('非平台专属用户，不可添加现货交易！')
      //   return
      // }
      // this.dialogLimit4 = true;
      this.clickRobotType = 'grid'
      this.semiOrAuto = 1;
      if (this.checkJiaoyiduiSemi()) {
        this.dialogLimit1 = true;
        return;
      }
      this.quoteCoin = ''
      this.coinName2 = ''
      this.valueLin0 = ''
      this.valueLin3 = ''
      this.huangjinvalue5 = ''
      this.huangjinvalue4 = ''
      this.huangjinvalue3 = ''
      this.huangjinvalue2 = ''
      this.huangjinvalue1 = ''
      this.small = ''
      this.profitMargin = ''
      this.interval = 0
      if(this.robotType == 0){
        this.instType = '1'
        this.futuresType = ''
      } else {
        this.instType = ''
        this.futuresType = ''
      }
      this.dialogMartinGrid = true
      this.amountValue = 0
      this.coinBalance = ''
      this.coinBalance2 = ''
      this.inputCoin = ''
      this.timer = setTimeout(() => {
        if (this.quoteCoin || this.coinName2)
        document.getElementById('wran_single_grid').placeholder = this.$t('robot.input_amount_holder')
      }, 1)
      // this.getAllSymbolsByCoin1(this.quoteCoin)
      // this.getCoinBalance(this.quoteCoin);
    },
    openAddDialogMartin(){
      if (!this.isNoApi()){
        return;
      }
      // if (this.robotInfo.is_exclusive != 1){
      //   this.$message.warning('非平台专属用户，不可添加现货交易！')
      //   return
      // }
      this.clickRobotType = 'martin'
      this.semiOrAuto = 1;
      if(this.robotType == 0){
        this.instType = '1'
        this.futuresType = ''
      } else {
        this.instType = ''
        this.futuresType = ''
      }
      if (this.checkJiaoyiduiSemi()) {
        this.dialogLimit1 = true;
        return;
      }
      sessionStorage.setItem('robotType', this.robotType)
      this.handleShowMartinAddDialog()
    },
    openAddDialogContract(){
      // if (!this.isNoApi()){
      //   return;
      // }

      this.$message.warning('该策略正在开发中，敬请期待！')
    },

    isNoApi(){
      if (this.robotInfo.bind_api != 1){
        // this.$message.warning(this.APIstatus)
        Message({
          type: "warning",
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: `<a style="display: block; border-bottom: 1px solid;color: unset;width: fit-content; margin: 0px;cursor:pointer;" href="/user/safe">${ this.APIstatus }</a>`
        });
        return false;
      }
      return true
    },

    checkJiaoyidui(){
      // if(this.chargeStyle == 1){
      //   if(this.tradingVolume >= 3){
      //     return true;
      //   }
      // }else if (this.chargeStyle == 2){
      //   if(this.tradingVolume >= 8){
      //     return true;
      //   }
      // }else if (this.chargeStyle == 3){
      //   if(this.tradingVolume >= 12){
      //     return true;
      //   }
      // }else if (this.chargeStyle == 0){
      //   if(this.tradingVolume >= 1){
      //     return true;
      //   }
      // }
      if(this.spotFullUsable <= 0){
        return true
      }
    },
    checkJiaoyiduiSwap(){
      if(this.swapUsable <= 0){
        return true
      }
    },
    checkJiaoyiduiSemi(){
      if(this.chargeStyle == 1){
        if(this.spotSemiUsable <= 0){
          return true;
        }
      }else if (this.chargeStyle == 2){
        if(this.tradingVolumeOfSemi >= 12){
          return true;
        }
      }else if (this.chargeStyle == 3){
        if(this.tradingVolumeOfSemi >= 20){
          return true;
        }
      }else if (this.chargeStyle == 0){
        if(this.tradingVolumeOfSemi >= 3){
          return true;
        }
      }
    },
    // vip提示
    openSvip(){
      this.dialogLimit3 = true;
      this.stopBubbling(event)
    },
    stopBubbling(e) {
      e = window.event || e;
      if (e.stopPropagation) {
        e.stopPropagation();      //阻止事件 冒泡传播
      } else {
        e.cancelBubble = true;   //ie兼容
      }
    },
    selectStopLoss(item){ // 合约选择止损比例
      item.checked = true
      this.stopLoss = item.type
      for (var i = 0; i < this.stopLossList.length; i++) {
        if (this.stopLossList[i].type != item.type){
          this.stopLossList[i].checked = false
        }
      }
    },
    selectStopSurplus(item){
      return;
      item.checked = true
      this.stopSurplus = item.type
      for (var i = 0; i < this.stopSurplusList.length; i++) {
        if (this.stopSurplusList[i].type != item.type){
          this.stopSurplusList[i].checked = false
        }
      }
    },
    // 添加黄金三角
    async addHuangjin(num) {
      if (this.coinName == "" || this.coinName == null) {
        if (this.robotType == 0){

        }
        this.$message.warning(this.$t('robot.select')+(this.robotType == 0?this.$t('robot.binance'): this.$t('robot.okx')) + this.$t('robot.space_currency'));
        return
      }
      if (!this.coinName2) {
        this.$message.warning(this.$t('robot.please_select')+ this.coinName + this.$t('robot.is_deal_pair'));
        return
      }
      if (!this.coinName3) {
        this.$message.warning(this.$t('robot.please_select') + this.coinName2 + this.$t('robot.is_deal_pair'));
        return
      }
      if (!this.huangjinvalue1) {
        this.$message.warning(this.$t('robot.enter') + this.coinName + this.$t('robot.is_trading_position'));
        return
      }
      if (!this.huangjinvalue2) {
        this.$message.warning(this.$t('robot.enter') + this.coinName2 + this.$t('robot.is_trading_position'));
        return
      }
      if (!this.huangjinvalue3) {
        this.$message.warning(this.$t('robot.enter') + this.coinName3 + this.$t('robot.is_trading_position'));
        return
      }
      if (this.huangjinvalue1 <= 0) {
        this.$message.warning(this.coinName + this.$t('robot.position_amount_must_be')+'>0');
        return
      }
      if (this.huangjinvalue2 <= 0) {
        this.$message.warning(this.coinName2 + this.$t('robot.position_amount_must_be')+'>0');
        return
      }
      if (this.huangjinvalue3 <= 0) {
        this.$message.warning(this.coinName3 + this.$t('robot.position_amount_must_be')+'>0');
        return
      }
      if (parseFloat(this.huangjinvalue1) < this.coin_min_one){
        this.$message.warning(this.coinName + this.$t('robot.position_amount_must_be')+ '≥' + this.coin_min_one)
        return
      }
      if(parseFloat(this.huangjinvalue2) < this.coin_min_two){
        this.$message.warning(this.coinName2 + this.$t('robot.position_amount_must_be')+ '≥' + this.coin_min_two)
        return
      }
      if (parseFloat(this.huangjinvalue3) < this.coin_min_three){
        this.$message.warning(this.coinName3 + this.$t('robot.position_amount_must_be') + '≥' + this.coin_min_three)
        return
      }
      // if(!this.balance_1 || this.balance_1 == '0.00' || this.balance_1 == 0){
      //   this.$message.warning(this.coinName+'的可用余额为0，无法创建交易')
      //   return
      // }
      // if(!this.balance_2 || this.balance_2 == '0.00' || this.balance_2 == 0){
      //   this.$message.warning(this.coinName2+'的可用余额为0，无法创建交易')
      //   return
      // }
      // if(!this.balance_3 || this.balance_3 == '0.00' || this.balance_3 == 0){
      //   this.$message.warning(this.coinName3+'的可用余额为0，无法创建交易')
      //   return
      // }
      this.isLoadingSJ = true;
      const res4 = await getAddSj(this.coinName, this.coinName2, this.coinName3, this.huangjinvalue1, this.huangjinvalue2, this.huangjinvalue3, num)
      if (res4.success) {
        // this.$message.success(this.$t('robot.golden_triangle_arbitrage') + this.$t('robot.added_success') + '，' + this.$t('robot.go_to_strategy_list_enable'))
        // await this.getUserStrategyListAll();/
        this.getUserStrategyList()
        await this.getSymbolCount();
        this.dialogHuangjin = false
        this.coinName = '';
        this.coinName2 = '';
        this.coinName3 = '';
        this.valueLin1 = '';
        this.valueLin2 = '';
        this.huangjinvalue1 = ''
        this.huangjinvalue2 = ''
        this.huangjinvalue3 = ''
        this.huangjinvalue4 = ''
        this.huangjinvalue5 = ''
        this.huangjinvalue6 = ''
        this.isLoadingSJ = false;
        this.$confirm(this.$t('robot.save_success') +'，'+ this.$t('robot.go_to_strategy_list_enable'),{
          confirmButtonText: this.$t('robot.i_know'),
          showCancelButton: false
        }).then(async _ => {})
          .catch(_ => {});
      } else {
        this.$message.error(res4.message)
        // this.dialogHuangjin = false

        // this.coinName = '';
        // this.coinName2 = '';
        // this.coinName3 = ''
        // this.valueLin1 = '';
        // this.valueLin2 = '';
        // this.huangjinvalue1 = ''
        // this.huangjinvalue2 = ''
        // this.huangjinvalue3 = ''
        // this.huangjinvalue4 = ''
        // this.huangjinvalue5 = ''
        // this.huangjinvalue6 = ''
        this.errorCode = res4.statusCode
        this.isLoadingSJ = false;
      }
    },
    //添加UB
    async addUB() {
      if (!this.coinName) {
        this.$message.warning(this.$t('robot.please_select')+(this.robotType == 0?this.$t('robot.binance'): this.$t('robot.okx')) + this.$t('robot.supported_transaction_currency'));
        return
      }
      if (!this.huangjinvalue4) {
        this.$message.warning(this.$t('robot.enter_the_bin_amount'));
        return
      }
      if (this.robotType == 0 && this.huangjinvalue4 < 3000) {
        this.$message.warning(this.$t('robot.position_amount_must_be')+'≥3000');
        return
      }

      if (this.robotType == 1 && parseFloat(this.huangjinvalue4) < this.ubMinValue){
        this.$message.warning(this.$t('robot.position_amount_must_be')+'≥' + this.ubMinValue);
        return
      }
      // if(!this.balance_6 || this.balance_6 == '0.00' || this.balance_6 == 0){
      //   this.$message.warning('USDT的可用余额为0，无法创建交易')
      //   return
      // }
      // if (!this.addUBchecked) {
      //   this.$message.warning('請先同意並勾選鳳泉量化平臺用戶協議');
      //   return
      // }
      this.isLoadingUB = true
      const res4 = await getAddUb(this.coinName, this.huangjinvalue4)
      if (res4.success) {
        this.isLoadingUB = false;
        this.$message.success(this.$t('robot.ub')+ this.$t('robot.added_success'));
        this.dialogUB = false
        this.coinName = '';
        this.huangjinvalue4 = '';
        // await this.getUserStrategyListAll();
        this.getUserStrategyList()
        await this.getSymbolCount();
      } else {
        this.isLoadingUB = false;
        this.$message.error(res4.message)
        this.dialogUB = false
        this.coinName = '';
        this.huangjinvalue4 = '';
      }
    },
    // 添加BB
    async addBB(num) {
      if (!this.coinName) {
        this.$message.warning(this.$t('robot.please_select')+(this.robotType == 0?this.$t('robot.binance'): this.$t('robot.okx')) + this.$t('robot.supported_transaction_currency'));
        return
      }
      if (!this.coinName2) {
        this.$message.warning(this.$t('robot.please_select')+(this.robotType == 0?this.$t('robot.binance'): this.$t('robot.okx')) + this.$t('robot.supported_transaction_pairs'));
        return
      }



      // if (!this.huangjinvalue5) {
      //   this.$message.warning(this.$t('robot.enter') + this.coinName + this.$t('robot.is_trading_position'));
      //   return
      // }


      if ((!this.huangjinvalue5 || this.huangjinvalue5<=0) && (!this.huangjinvalue6 || this.huangjinvalue6 <=0)){
        this.$message.warning(this.$t('robot.please_fill_in_any_position'))
        return
      }

      if ((this.huangjinvalue5) && parseFloat(this.huangjinvalue5) < this.coin_min_one){
        this.$message.warning(this.coinName + this.$t('robot.position_amount_must_be') + '≥' +this.coin_min_one)
        return
      }
      if ((this.huangjinvalue6) && parseFloat(this.huangjinvalue6) < this.coin_min_two){
        this.$message.warning(this.coinName2 + this.$t('robot.position_amount_must_be') + '≥' +this.coin_min_two)
        return
      }


      // if (this.huangjinvalue5 <= 0) {
      //   this.$message.warning(this.coinName + this.$t('robot.position_amount_must_be')+'>0');
      //   return
      // }
      //
      // if (this.huangjinvalue6 <= 0) {
      //   this.$message.warning(this.coinName2 + this.$t('robot.position_amount_must_be')+'>0');
      //   return
      // }

      // if(!this.balance_4 || this.balance_4 == '0.00' || this.balance_4 == 0){
      //   this.$message.warning(this.coinName+'的可用余额为0，无法创建交易')
      //   return
      // }
      // if(!this.balance_5 || this.balance_5 == '0.00' || this.balance_5 == 0){
      //   this.$message.warning(this.coinName3+'的可用余额为0，无法创建交易')
      //   return
      // }
      this.isLoadingBB = true;
      const res4 = await getAddBb(this.coinName, this.coinName2, this.huangjinvalue5, this.huangjinvalue6,num)
      if (res4.success) {
        this.$message.success(this.$t('robot.bb') + this.$t('robot.added_success')+'！')
        this.dialogBB = false
        this.coinName = '';
        this.coinName2 = '';
        this.valueLin3 = '';
        this.huangjinvalue5 = '';
        this.huangjinvalue6 = '';
        this.isLoadingBB = false
        // await this.getUserStrategyListAll();
        this.getUserStrategyList()
        await this.getSymbolCount();
      } else {
        this.isLoadingBB = false
        this.$message.error(res4.message)
        // this.coinName = '';
        // this.coinName2 = '';
        // this.valueLin3 = '';
        // this.huangjinvalue5 = '';
        // this.huangjinvalue6 = '';
        this.errorCode = res4.statusCode
        // this.dialogBB = false
      }
    },

    gotoUserUsageProtocol(){
      this.$router.push({name: 'transactionAgreement'})
    },

    async gotoCreate(){
      if(this.iKnowRisk == true){
        this.dialogContract = true
        this.riskDialog = false
        this.balanceType = 1
        // this.$cookies.config(60*60*24*30, '')
        // this.$cookies.set('iKnowRisk', 1)
      } else {
        this.$message.warning(this.$t('robot.enter_trade_tip'))
      }


      // const res = await addSupremeHedge(this.robotType, this.contract_amount, this.stopLoss, this.stopSurplus)
      // if (!res) return
      // if(res.success){
      //   this.dialogContract = false
      //   this.riskDialog = false
      //   this.getUserStrategyList()
      //   await this.getSymbolCount();
      //   this.$message.success(this.$t('robot.grid_contract_strategy') + this.$t('robot.added_success')+'！')
      // } else {
      //   this.$message.error(res.message)
      // }
    },

    checkPercentage(value){
      let inputValue =
          ("" + value) // 第一步：转成字符串
              .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              // .replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\d{0,10})/g)[0] || "" // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      if(parseInt(inputValue)>100){
        inputValue = 100
      }
      this.percentageValue = inputValue
    },

    sliderPositionPro(e, num){
      if(!e) {
        this.positionProportion = 100
      } else {
        this.positionProportion = e
      }
      // if(num == 2){
      // 	this.getFuturesMinSpace()
      // }
    },

    minRatio(e){
      var position = e.detail.value
      if(position){
        var value = Math.ceil(parseFloat(this.hedgePosition)/parseFloat(position) * 100)
        this.minPositionRatio = value>100?100:value
        this.positionProportion = value<70?70:value>100?100:value
      }
    },

    getItem(item){
      if(parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
        return
      }

      if(parseFloat(this.minPositionRatio)>parseFloat(item.value)){
        return
      }
      if(!this.contract_amount || !this.baseCoin || parseFloat(this.contract_amount) <= parseFloat(this.hedgePositionRatio)) {
        return
      }
      item.checked = true
      for (var i = 0; i < this.hedgeChangeItem.length; i++) {
        if(item.id == this.hedgeChangeItem[i].id){
          this.hedgeChangeItem[i].checked = true
        } else {
          this.hedgeChangeItem[i].checked = false
        }
      }
      if(item.checked == true){
        this.positionProportion = item.value
      }
      if(parseFloat(this.positionProportion) < 60){
        this.riskName = '低'
        this.riskValue = 1
      } else if(parseFloat(this.positionProportion) >= 60 && parseFloat(this.positionProportion) <= 80) {
        this.riskName = '中'
        this.riskValue = 2
      } else if(parseFloat(this.positionProportion) > 80) {
        this.riskName = '高'
        this.riskValue = 3
      }
    },

    // 添加网格合约
    async addContract(num){
      // if(!this.baseCoin){
      //   this.$message.warning(this.$t('robot.please_select_currency'))
      //   return
      // }
      // if (!this.contract_amount){
      //   this.$message.warning(this.$t('robot.enter_the_bin_amount'))
      //   return
      // }
      // if(this.baseCoin == 'FITFI' && (parseFloat(this.contract_amount)>2000)){
      //   this.$message.warning('FITFI仓位不能超过2000 USDT')
      //   return
      // }
      // if(this.baseCoin == 'ICP' && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
      //   this.$message.warning(this.$t('robot.position_amount_must_be')+'≥'+this.hedgePosition)
      //   return
      // }
      // if(this.baseCoin == 'DOT' && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
      //   this.$message.warning(this.$t('robot.position_amount_must_be')+'≥'+this.hedgePosition)
      // 	return
      // }
      // if(this.baseCoin == 'BTC' && this.robotType == 0 && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
      //   this.$message.warning(this.$t('robot.position_amount_must_be')+'≥'+this.hedgePosition)
      // 	return
      // }
      // if(this.baseCoin == 'BTC' && this.robotType == 1 && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
      //   this.$message.warning(this.$t('robot.position_amount_must_be')+'≥'+this.hedgePosition)
      // 	return
      // }
      // if(this.baseCoin == 'ETH' && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
      //   this.$message.warning(this.$t('robot.position_amount_must_be')+'≥' + this.hedgePosition)
      //   return
      // }
      // if ((parseFloat(this.contract_amount)-5) > parseFloat(this.coinBalance)){
      //   this.$message.warning(this.$t('robot.funds_cannot_more_balance'))
      //   return
      // }
      if(!this.percentageValue){
        this.percentageValue = 0
      }
      var speed = 0
      if(this.isTrialVersion == 'speedVersion'){
        speed = 1
      } else if(this.isTrialVersion == 'singleVersion') {
        speed = 2
      } else {
        speed = 0
      }
      if(this.isTrialVersion != 'singleVersion') {
        this.direction = ''
      }
      const res = await addSupremeHedge(this.robotType, this.contract_amount, this.stopLoss, this.stopSurplus, num, this.baseCoin, this.percentageValue, this.positionProportion, speed, this.direction, this.futureType)
      if (!res) return
      if(res.success){
        this.dialogContract = false
        this.getUserStrategyList()
        await this.getSymbolCount();
        this.$message.success(this.$t('robot.grid_contract_strategy') + this.$t('robot.added_success')+'！')
      } else {
        this.$message.error(res.message)
      }
    },
    // 添加期现策略
    addTermHedging(){
      if(!this.baseCoin){
        this.$message.warning(this.$t('robot.please_select_currency'))
        return
      }
      if (!this.contract_amount){
        this.$message.warning(this.$t('robot.enter_the_bin_amount'))
        return
      }

      if (this.robotType == 1) {
        if ((parseFloat(this.contract_amount)-5) > parseFloat(this.coinBalance)){
          this.$message.warning(this.$t('robot.funds_cannot_more_balance'))
          return
        }
      } else {
        if ((parseFloat(this.contract_amount)*0.3) > parseFloat(this.coinBalance)){
          this.$message.warning('币安U本位合约账户资金不能低于仓位的30%')
          return
        }

        if ((parseFloat(this.contract_amount)*0.7) > parseFloat(this.balanceBnSpot)){
          this.$message.warning('币安现货账户资金不能低于仓位的70%')
          return
        }
      }

      if(parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
        this.$message.warning(this.$t('robot.position_amount_must_be')+'≥' + this.hedgePosition)
        return
      }

      addTermHedging(this.baseCoin, this.contract_amount, this.robotType, this.termType).then(res=>{
        if(!res) return
        if(res.success) {
          this.$message.success('操作成功')
          var timer113 = setTimeout(()=>{
            this.dialogTerm = false
            this.getUserStrategyList()
            this.getSymbolCount()
            clearTimeout(timer113)
          },1500)
        } else {
          this.$message.error(res.message)
        }
      })
    },

    modifyTermSpace(){
      if(!this.baseCoin){
        this.$message.warning(this.$t('robot.please_select_currency'))
        return
      }
      if (!this.contract_amount){
        this.$message.warning(this.$t('robot.enter_the_bin_amount'))
        return
      }

      if (this.robotType == 1) {
        if ((parseFloat(this.contract_amount)-5) > parseFloat(this.coinBalance)){
          this.$message.warning(this.$t('robot.funds_cannot_more_balance'))
          return
        }
      } else {
        if ((parseFloat(this.contract_amount)*0.3) > parseFloat(this.coinBalance)){
          this.$message.warning('币安U本位合约账户资金不能低于仓位的30%')
          return
        }

        if ((parseFloat(this.contract_amount)*0.7) > parseFloat(this.balanceBnSpot)){
          this.$message.warning('币安现货账户资金不能低于仓位的70%')
          return
        }
      }

      if(parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
        this.$message.warning(this.$t('robot.position_amount_must_be')+'≥' + this.hedgePosition)
        return
      }

      var params = {}
      params.space = this.contract_amount
      params.robot_type = this.robotType
      params.version = this.changeItem.old_new
      params.coin = this.changeItem.coin_two
      modify_term_hedging(params).then(res=>{
        if(!res) return
        if(res.success) {
          this.$message.success('操作成功')
          var timer113 = setTimeout(()=>{
            this.dialogTerm = false
            this.getUserStrategyList()
            this.getSymbolCount()
            clearTimeout(timer113)
          },1500)
        } else {
          this.$message.error(res.message)
        }
      })
    },

    modifyFuturesSpace(){
      if (!this.contract_amount){
        this.$message.warning(this.$t('robot.enter_the_bin_amount'))
        return
      }
      if(this.baseCoin == 'FITFI' && (parseFloat(this.contract_amount)>2000)){
        this.$message.warning('FITFI仓位不能超过2000 USDT')
        return
      }
      if(this.baseCoin == 'DOT' && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
        this.$message.warning(this.$t('robot.position_amount_must_be')+'≥'+this.hedgePosition)
      	return
      }
      if(this.baseCoin == 'BTC' && this.robotType == 0 && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
        this.$message.warning(this.$t('robot.position_amount_must_be')+'≥'+this.hedgePosition)
      	return
      }
      if(this.baseCoin == 'BTC' && this.robotType == 1 && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
        this.$message.warning(this.$t('robot.position_amount_must_be')+'≥'+this.hedgePosition)
      	return
      }
      if(this.baseCoin == 'ETH' && parseFloat(this.contract_amount) < parseFloat(this.hedgePosition)){
        this.$message.warning(this.$t('robot.position_amount_must_be')+'≥' + this.hedgePosition)
        return
      }
      if ((this.spaceOne != this.changeItem.space) && parseFloat(this.contract_amount)-5 > parseFloat(this.coinBalance)){
        this.$message.warning(this.$t('robot.cannot_more_balance'))
      	return
      }
      if(!this.percentageValue){
        this.percentageValue = 0
      }
      if(parseFloat(this.contract_amount) < parseFloat(this.changeItem.space)){
        this.reducePosition = true
        return
      }
      this.modifyFuturesSpaceConfirm()
    },

    reducePositionMenu(){
      this.modifyFuturesSpaceConfirm()
    },

    modifyFuturesSpaceConfirm(){
      modifyFuturesSpace(this.robotType, this.contract_amount, this.changeItem.sid, this.stopLoss, this.percentageValue, this.positionProportion).then(res =>{
        if(!res) return
        if(res.success){
          if (this.reducePosition == true){
            this.reducePosition = false
          }
          this.$message.warning(this.$t('robot.add_position_success'))
          var timer4 = setTimeout(()=>{
            this.getUserStrategyList()
            this.getSymbolCount();
            clearTimeout(timer4)
          },500)
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    // 添加網格
    addGridVerify(){
      if (this.inputCoin == 1){
        this.huangjinvalue5 = ''
      }else if(this.inputCoin == 2){
        this.huangjinvalue4 = ''
      }
      if (!this.quoteCoin){
        this.$message.warning(this.$t('robot.grid_hokder'))
        return;
      }
      if (!this.coinName2){
        this.$message.warning(this.$t('robot.grid_buy_holder'))
        return;
      }
      if (!this.huangjinvalue1){
        this.$message.warning(this.$t('robot.enter')+this.$t('robot.interval_lowest_price'))
        return;
      }
      if (!this.huangjinvalue2){
        this.$message.warning(this.$t('robot.enter')+this.$t('robot.interval_maximum_price'))
        return;
      }
      if (parseFloat(this.huangjinvalue1) >= parseFloat(this.huangjinvalue2)){
        this.$message.warning(this.$t('robot.interval_tip'))
        return;
      }
      if (!this.huangjinvalue3){
        this.$message.warning(this.$t('robot.enter')+this.$t('robot.number_of_grids'))
        return;
      }
      if (parseFloat(this.huangjinvalue3) < 2){
        this.$message.warning(this.$t('robot.number_of_grids_cannot_be_less_than')+'2')
        return;
      }
      if (parseFloat(this.huangjinvalue3) > 150){
        this.$message.warning(this.$t('robot.number_of_grids_cannot_be_more_than')+'150')
        return;
      }
      if (this.inputCoin == 3){
        if(!this.huangjinvalue4 && !this.huangjinvalue5){
          this.$message.warning(this.$t('robot.enter')+this.$t('robot.input_amount'))
          return;
        }
      }else {
        if (this.inputCoin == 1){
          if (!this.huangjinvalue4){
            this.$message.warning(this.$t('robot.enter')+ this.quoteCoin + this.$t('robot.input_amount'))
            return;
          }
        } else {
          if (!this.huangjinvalue5){
            this.$message.warning(this.$t('robot.enter') + this.coinName2 + this.$t('robot.input_amount'))
            return;
          }
        }
      }

      if (this.robotType == 0 && parseFloat(this.small) < 0.01){
        this.$message.warning(this.$t('robot.single_grid_profit_margin_must_be_greater_than')+'1%')
        return;
      }
      if (this.robotType == 1 && parseFloat(this.small) < 0){
        this.$message.warning(this.$t('robot.single_grid_profit_margin_must_be_greater_than')+'0%')
        return;
      }
      if(this.huangjinvalue4 && parseFloat(this.huangjinvalue4) > parseFloat(this.coinBalance)){
        this.$message.warning(this.$t('robot.input_amount_and_available_amount'))
        return;
      }
      if(this.huangjinvalue5 && parseFloat(this.huangjinvalue5) > parseFloat(this.coinBalance2)){
        this.$message.warning(this.$t('robot.input_amount_and_available_amount'))
        return;
      }
      // if(this.huangjinvalue4 && this.robotType == 0 && (parseFloat(this.huangjinvalue4) < 15*this.huangjinvalue3)){
      //   this.$message.warning(this.$t('robot.input_amount_tip1')+(15*this.huangjinvalue3) + this.quoteCoin)
      //   return;
      // }
      // if(this.huangjinvalue4 && this.robotType == 1 && (parseFloat(this.huangjinvalue4) < this.selectCoinItem.min_qty*this.huangjinvalue2*this.huangjinvalue3)){
      //   this.$message.warning(this.$t('robot.input_amount_tip1')+(this.selectCoinItem.min_qty*this.huangjinvalue2*this.huangjinvalue3) + this.quoteCoin)
      //   return;
      // }
      // if(this.huangjinvalue4 && this.robotType == 0 && (parseFloat(this.huangjinvalue4) < (this.selectCoinItem.min_notional)*this.huangjinvalue3*1.5)){
      //   this.$message.warning(this.$t('robot.input_amount_tip1')+((this.selectCoinItem.min_notional)*this.huangjinvalue3*1.5) + this.quoteCoin)
      //   return;
      // }
      // if(this.huangjinvalue4 && this.robotType == 1 && (parseFloat(this.huangjinvalue4) < this.selectCoinItem.min_qty*this.huangjinvalue2*this.huangjinvalue3)){
      //   this.$message.warning(this.$t('robot.input_amount_tip1')+(this.selectCoinItem.min_qty*this.huangjinvalue2*this.huangjinvalue3) + this.quoteCoin)
      //   return;
      // }
      if(this.huangjinvalue4 && this.robotType == 0 && (parseFloat(this.huangjinvalue4) < this.$options.filters['roundEightFilter']((this.selectCoinItem.min_notional)*this.huangjinvalue3*1.5))){
        this.$message.warning(this.$t('robot.input_amount_tip1')+this.$options.filters['roundEightFilter']((this.selectCoinItem.min_notional)*this.huangjinvalue3*1.5) + this.quoteCoin)
        return;
      }
      if(this.huangjinvalue4 && this.robotType == 1 && (parseFloat(this.huangjinvalue4) < this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty*this.huangjinvalue2*this.huangjinvalue3))){
        this.$message.warning(this.$t('robot.input_amount_tip1')+this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty*this.huangjinvalue2*this.huangjinvalue3) + this.quoteCoin)
        return;
      }

      // if(this.huangjinvalue5 && this.robotType == 0 && (parseFloat(this.huangjinvalue5) < (this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5)){
      //   this.$message.warning(this.$t('robot.input_amount_tip1')+ ((this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5) + this.quoteCoin)
      //   return;
      // }
      // if(this.huangjinvalue5 && this.robotType == 1 && (parseFloat(this.huangjinvalue5) < this.selectCoinItem.min_qty*this.huangjinvalue2*this.huangjinvalue3)){
      //   this.$message.warning(this.$t('robot.input_amount_tip1')+(this.selectCoinItem.min_qty*this.huangjinvalue2*this.huangjinvalue3) + this.quoteCoin)
      //   return;
      // }
      if(this.huangjinvalue5 && this.robotType == 0 && (parseFloat(this.huangjinvalue5) < this.$options.filters['roundEightFilter']((this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5))){
        this.$message.warning(this.$t('robot.input_amount_tip1')+ this.$options.filters['roundEightFilter']((this.selectCoinItem.min_qty_result)*this.huangjinvalue3*1.5) + this.coinName2)
        return;
      }
      if(this.huangjinvalue5 && this.robotType == 1 && (parseFloat(this.huangjinvalue5) < this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue3))){
        this.$message.warning(this.$t('robot.input_amount_tip1')+this.$options.filters['roundEightFilter'](this.selectCoinItem.min_qty * this.huangjinvalue3) + this.coinName2)
        return;
      }
      this.dialogMartinGridSecond = true
    },
    async addGrid() {
      this.isLoadingGRID = true;
      const res = await getAddGrid(this.coinName2, this.quoteCoin, this.huangjinvalue1, this.huangjinvalue2, this.huangjinvalue3, this.huangjinvalue5, this.huangjinvalue4, this.ratioMode, this.robotType);
      if (!res) return;
      if (res.success) {
        this.$message.success(this.$t('robot.grid_strategy')+this.$t('robot.added_success'));
        this.quoteCoin = '';
        this.coinName2 = '';
        this.huangjinvalue1 = '';
        this.huangjinvalue2 = '';
        this.huangjinvalue3 = '';
        this.huangjinvalue4 = '';
        this.dialogMartinGrid = false;
        this.dialogMartinGridSecond = false;
        this.isLoadingGRID = false;
        // await this.getUserStrategyListAll();
        this.getUserStrategyList()
        await this.getSymbolCount();
      } else {
        this.isLoadingGRID = false;
        this.$message.error(res.message)
      }
    },

    getUserStrategyList(){
      if(this.robotType == 0){
        this.getUserStrategyListAll()
      } else{
        this.getUserStrategyListAll_ok()
      }
      this.cancelCheck()
    },

    // 策略列表
    async getUserStrategyListAll(num){
      if (this.requestTimes != 10){
        this.strategyLoading = true;
      }
      this.strategyList = []
      this.autoStrategyList = []
      const res = await getUserStrategyListAll(num,this.robotType,false)
      if (!res) return;
      if(res.success){
        this.strategyLoading = false
        if (!res.data) return
        this.strategyList = res.data
        if (this.robotInfo.state == 1 && this.strategyList.length == 0){
          this.updataState(this.$t('robot.dormant'))
        }
        this.totalRow = res.data.length
        for (let i=0; i<this.strategyList.length; i++){
          this.strategyList[i].rank = this.strategyList.length-i
          if(this.strategyList[i].product_type == 'grid'){
            this.strategyList[i].strategyType = 3
            this.strategyList[i].coin_one = this.strategyList[i].coin.split('-')[1]
            this.strategyList[i].coin_two = this.strategyList[i].coin.split('-')[0]
          } else if(this.strategyList[i].product_type == 'sj'){
            this.strategyList[i].strategyType = 0
          } else if(this.strategyList[i].product_type == 'bb'){
            this.strategyList[i].strategyType = 1
          } else if(this.strategyList[i].product_type == 'ub'){
            this.strategyList[i].strategyType = 2
          } else if(this.strategyList[i].product_type == 'martin'){
            this.strategyList[i].strategyType = 4
          } else if (this.strategyList[i].product_type == 'hedge'){
            this.strategyList[i].strategyType = 5
          } else if (this.strategyList[i].product_type == 'Term'){
            this.strategyList[i].strategyType = 6
            if (this.strategyList[i].uid_b) {
              this.strategyList[i].b_uid = this.strategyList[i].uid_b
            }
            if (this.strategyList[i].uid_b2) {
              this.strategyList[i].b2_uid = this.strategyList[i].uid_b2
            }
            if (this.strategyList[i].uid_c) {
              this.strategyList[i].c_uid = this.strategyList[i].uid_c
            }
          }
          this.strategyList[i].robotType = this.strategyList[i].robot_type=='bn'?0:this.strategyList[i].robot_type=='ok'?1:2
          if (this.isMobile && (this.strategyList[i].strategyType == 3 || this.strategyList[i].strategyType == 4)){
            // this.strategyList[i].add_time = this.$util.formatDate_no_year(new Date(this.strategyList[i].add_time),'-')
            // this.strategyList[i].add_time_short = this.strategyList[i].add_time
            var timearr = this.strategyList[i].add_time.replace(" ", ":").replace(/\:/g, "-").split("-");
            this.strategyList[i].add_time_short  = ""+timearr[1] + "-" + timearr[2] + " " + timearr[3] + ":" + timearr[4]
          }

        }
        this.strategyList_bn = this.strategyList
        await this.getUserStrategyListAll_Y()
      }else {
        this.strategyLoading = false
        this.$message.warning(res.message)
        await this.getUserStrategyListAll_Y()
      }
    },

    async getUserStrategyListAll_Y(){
      this.strategyList = []
      const res = await getUserStrategyListAll(10,this.robotType,true)
      if (!res) return;
      if(res.success){
        if (!res.data) return
        this.strategyList = res.data
        this.totalRow = res.data.length
        for (let i=0; i<this.strategyList.length; i++){
          this.strategyList[i].rank = this.strategyList.length-i
          if(this.strategyList[i].product_type == 'grid'){
            this.strategyList[i].strategyType = 3
            this.strategyList[i].coin_one = this.strategyList[i].coin.split('-')[1]
            this.strategyList[i].coin_two = this.strategyList[i].coin.split('-')[0]
          } else if(this.strategyList[i].product_type == 'sj'){
            this.strategyList[i].strategyType = 0
          } else if(this.strategyList[i].product_type == 'bb'){
            this.strategyList[i].strategyType = 1
          } else if(this.strategyList[i].product_type == 'ub'){
            this.strategyList[i].strategyType = 2
          } else if(this.strategyList[i].product_type == 'martin'){
            this.strategyList[i].strategyType = 4
          } else if (this.strategyList[i].product_type == 'hedge'){
            this.strategyList[i].strategyType = 5
          } else if (this.strategyList[i].product_type == 'Term'){
            this.strategyList[i].strategyType = 6
            if (this.strategyList[i].uid_b) {
              this.strategyList[i].b_uid = this.strategyList[i].uid_b
            }
            if (this.strategyList[i].uid_b2) {
              this.strategyList[i].b2_uid = this.strategyList[i].uid_b2
            }
            if (this.strategyList[i].uid_c) {
              this.strategyList[i].c_uid = this.strategyList[i].uid_c
            }
          }
          this.strategyList[i].robotType = this.strategyList[i].robot_type=='bn'?0:this.strategyList[i].robot_type=='ok'?1:2
          if (this.isMobile && (this.strategyList[i].strategyType == 3 || this.strategyList[i].strategyType == 4)){
            var timearr = this.strategyList[i].add_time.replace(" ", ":").replace(/\:/g, "-").split("-");
            this.strategyList[i].add_time_short  = ""+timearr[1] + "-" + timearr[2] + " " + timearr[3] + ":" + timearr[4]
          }
          this.strategyList[i].checked = false
          // if (this.strategyList[i].strategyType == 0 || this.strategyList[i].strategyType == 1 || this.strategyList[i].strategyType == 2) {
          if ((this.strategyList[i].strategyType == 0) && this.strategyList[i].state == 2) {
            this.autoStrategyList.push(this.strategyList[i])
            this.strategyList[i].isNotChecked = false
          } else {
            this.strategyList[i].isNotChecked = true
          }
        }
        this.strategyList_bn = this.strategyList
      }else {
        this.$message.warning(res.message)
      }
    },

    // 策略列表 ok
    async getUserStrategyListAll_ok(num){
      if (this.requestTimes != 10){
        this.strategyLoading = true;
      }
      this.strategyList = []
      this.autoStrategyList = []
      const res = await getUserStrategyListAll(num,this.robotType,false)
      if (!res) return;
      if(res.success){
        this.strategyLoading = false
        if (!res.data) return
        this.strategyList = res.data
        if (this.robotInfo.state == 1 && this.strategyList.length == 0){
          this.updataState(this.$t('robot.dormant'))
        }
        this.totalRow = res.data.length
        for (let i=0; i<this.strategyList.length; i++){
          this.strategyList[i].rank = this.strategyList.length-i
          if(this.strategyList[i].product_type == 'grid'){
            this.strategyList[i].strategyType = 3
            this.strategyList[i].coin_one = this.strategyList[i].coin.split('-')[1]
            this.strategyList[i].coin_two = this.strategyList[i].coin.split('-')[0]
          } else if(this.strategyList[i].product_type == 'sj'){
            this.strategyList[i].strategyType = 0
          } else if(this.strategyList[i].product_type == 'bb'){
            this.strategyList[i].strategyType = 1
          } else if(this.strategyList[i].product_type == 'ub'){
            this.strategyList[i].strategyType = 2
          } else if(this.strategyList[i].product_type == 'martin'){
            this.strategyList[i].strategyType = 4
          } else if (this.strategyList[i].product_type == 'hedge'){
            this.strategyList[i].strategyType = 5
          } else if (this.strategyList[i].product_type == 'Term'){
            this.strategyList[i].strategyType = 6
            if (this.strategyList[i].uid_b) {
              this.strategyList[i].b_uid = this.strategyList[i].uid_b
            }
            if (this.strategyList[i].uid_b2) {
              this.strategyList[i].b2_uid = this.strategyList[i].uid_b2
            }
            if (this.strategyList[i].uid_c) {
              this.strategyList[i].c_uid = this.strategyList[i].uid_c
            }
          }
          this.strategyList[i].robotType = this.strategyList[i].robot_type=='bn'?0:this.strategyList[i].robot_type=='ok'?1:2
          if (this.isMobile && (this.strategyList[i].strategyType == 3 || this.strategyList[i].strategyType == 4)){
            // this.strategyList[i].add_time = this.$util.formatDate_no_year(new Date(this.strategyList[i].add_time),'-')
            // this.strategyList[i].add_time_short = this.strategyList[i].add_time
            var timearr = this.strategyList[i].add_time.replace(" ", ":").replace(/\:/g, "-").split("-");
            this.strategyList[i].add_time_short  = ""+timearr[1] + "-" + timearr[2] + " " + timearr[3] + ":" + timearr[4]
          }
        }
        this.strategyList_ok = this.strategyList
        await this.getUserStrategyListAll_Y_ok()
      }else {
        this.strategyLoading = false
        this.$message.warning(res.message)
        await this.getUserStrategyListAll_Y_ok()
      }
    },

    async getUserStrategyListAll_Y_ok(){
      this.strategyList = []
      const res = await getUserStrategyListAll(10,this.robotType,true)
      if (!res) return;
      if(res.success){
        if (!res.data) return
        this.strategyList = res.data
        this.totalRow = res.data.length
        for (let i=0; i<this.strategyList.length; i++){
          this.strategyList[i].rank = this.strategyList.length-i
          if(this.strategyList[i].product_type == 'grid'){
            this.strategyList[i].strategyType = 3
            this.strategyList[i].coin_one = this.strategyList[i].coin.split('-')[1]
            this.strategyList[i].coin_two = this.strategyList[i].coin.split('-')[0]
          } else if(this.strategyList[i].product_type == 'sj'){
            this.strategyList[i].strategyType = 0
          } else if(this.strategyList[i].product_type == 'bb'){
            this.strategyList[i].strategyType = 1
          } else if(this.strategyList[i].product_type == 'ub'){
            this.strategyList[i].strategyType = 2
          } else if(this.strategyList[i].product_type == 'martin'){
            this.strategyList[i].strategyType = 4
          } else if (this.strategyList[i].product_type == 'hedge'){
            this.strategyList[i].strategyType = 5
          } else if (this.strategyList[i].product_type == 'Term'){
            this.strategyList[i].strategyType = 6
            if (this.strategyList[i].uid_b) {
              this.strategyList[i].b_uid = this.strategyList[i].uid_b
            }
            if (this.strategyList[i].uid_b2) {
              this.strategyList[i].b2_uid = this.strategyList[i].uid_b2
            }
            if (this.strategyList[i].uid_c) {
              this.strategyList[i].c_uid = this.strategyList[i].uid_c
            }
          }
          this.strategyList[i].robotType = this.strategyList[i].robot_type=='bn'?0:this.strategyList[i].robot_type=='ok'?1:2
          if (this.isMobile && (this.strategyList[i].strategyType == 3 || this.strategyList[i].strategyType == 4)){
            var timearr = this.strategyList[i].add_time.replace(" ", ":").replace(/\:/g, "-").split("-");
            this.strategyList[i].add_time_short  = ""+timearr[1] + "-" + timearr[2] + " " + timearr[3] + ":" + timearr[4]
          }
          this.strategyList[i].checked = false
          // if (this.strategyList[i].strategyType == 0 || this.strategyList[i].strategyType == 1 || this.strategyList[i].strategyType == 2) {
          if ((this.strategyList[i].strategyType == 0) && this.strategyList[i].state == 2) {
            this.autoStrategyList.push(this.strategyList[i])
            this.strategyList[i].isNotChecked = false
          } else {
            this.strategyList[i].isNotChecked = true
          }
        }
        this.strategyList_ok = this.strategyList
      }else {
        this.$message.warning(res.message)
      }
    },

    compare(p){ //这是比较函数
      return function (m, n) {
        var a = m[p];
        var b = n[p];
        return new Date(b) - new Date(a); //升序
      }
    },
    delet(strategyType, item){
      console.log('qweqwe12312312312312qwe', item)
      switch (strategyType) {
        case 0:
          this.deleteSj(item.group_id)
          break;
        case 1:
          this.stopState = '3'
          this.delBBItem = item
          this.delBb(item.coin)
          break;
        case 2:
          this.delUb(item.id)
          break;
        case 3:
          this.stopState = '3'
          this.deleteType = item.strategyType
          this.dialogStopState = true;
          this.delGridItem = item;
          break;
        case 4:
          this.stopState = '3'
          this.deleteType = item.strategyType
          this.dialogStopState = true;
          this.delGridItem = item;
          break;
        case 5:
          this.delHedgeItem = item;
          this.deleteHedge()
          break;
        case 6:
          this.stopTermState = '1'
          this.delTermItem = item;
          this.deleteTerm()
          break;
      }
    },

    async addBBPosition(num, item){ // 添加BB仓位
      this.huangjinvalue1 = ''
      this.huangjinvalue2 = ''
      this.detailsItem = item
      this.BBaddPosition = true
      const res3 = await getAllSymbolsByCoin(item.coin_one)
      if (!res3) return
      if (res3.success) {
        this.allSymbolsByCoin = res3.data
        var bbitem = {}
        for (var i=0; i<this.allSymbolsByCoin.length; i++){
          if(((item.coin_one + '-' + item.coin_two) == (this.allSymbolsByCoin[i].cost_coin + '-' +this.allSymbolsByCoin[i].target_coin)) || ((item.coin_one + '-' + item.coin_two) == (this.allSymbolsByCoin[i].target_coin + '-' +this.allSymbolsByCoin[i].cost_coin))){
            bbitem = this.allSymbolsByCoin[i]
          }
        }
        var coin = item.coin_one + '-' + item.coin_two
        var coin2 = item.coin_two + '-' + item.coin_one
        if (item.space){
          this.BBminPositionOne = this.getMinPosition(bbitem, coin)
          document.getElementById('coin_one').innerText = this.$t('robot.position_amount_must_be') + '≥' + this.getMinPosition(bbitem, coin)
        }
        if (item.space_two){
          this.BBminPositionTwo = this.getMinPosition(bbitem, coin2)
          document.getElementById('coin_two').innerText = this.$t('robot.position_amount_must_be') + '≥' + this.getMinPosition(bbitem, coin2)
        }
      } else {
        this.$message.error(res3.message)
      }
    },

    BBaddPositionConfirm(){
      if (this.detailsItem.space && !this.huangjinvalue1){
        this.$message.warning(this.$t('robot.enter') + this.detailsItem.coin_one + this.$t('robot.is_trading_position'));
        return;
      }
      if (this.detailsItem.space_two && !this.huangjinvalue2){
        this.$message.warning(this.$t('robot.enter') + this.detailsItem.coin_two + this.$t('robot.is_trading_position'));
        return;
      }
      if (this.detailsItem.space && parseFloat(this.huangjinvalue1)<this.BBminPositionOne){
        this.$message.warning(this.detailsItem.coin_one + this.$t('robot.position_amount_must_be')+'≥'+this.BBminPositionOne)
        return;
      }
      if (this.detailsItem.space_two && parseFloat(this.huangjinvalue2)<this.BBminPositionTwo){
        this.$message.warning(this.detailsItem.coin_two+this.$t('robot.position_amount_must_be')+'≥'+this.BBminPositionTwo)
        return;
      }

      // if (this.detailsItem.space && parseFloat(this.huangjinvalue1)<this.detailsItem.space){
      //   this.$message.warning(this.$t('robot.more_initial_amount'))
      //   return;
      // }
      // if (this.detailsItem.space_two && parseFloat(this.huangjinvalue2)<this.detailsItem.space_two){
      //   this.$message.warning(this.$t('robot.more_initial_amount'))
      //   return;
      // }

      modifyBb(this.detailsItem.coin_one, this.detailsItem.coin_two, this.huangjinvalue1, this.huangjinvalue2).then(res =>{
        if (!res) return
        if(res.success){
          this.$message.success(this.$t('robot.add_position_success'))
          this.BBaddPosition = false;
          this.getUserStrategyList()
        } else {
          this.$message.warning(res.message)
        }
      })


    },

    async Restart(strategyType, item) {
      const res = await getMartinStrategy(item.strategy_uid)
      if (!res) return
      if (res.success) {
        if (res.data){
          var details = res.data
          delete details.strategy_uid
          // sessionStorage.setItem('robotType', this.robotType)
          // this.handleShowMartinAddDialog(2, details);
          sessionStorage.setItem('robotType', this.robotType)
          sessionStorage.setItem('alertDetails', JSON.stringify(details));
          this.handleShowMartinAddDialog(2, JSON.stringify(details))
        }
      } else {
        this.$message.error(res.message)
      }
    },

    pauseHedge(item){
      this.hedgePauseItem = item
      this.dialogHePauseState = true
    },

    async pauseContract(){ // 暂停合约
      if (!this.check_he_pause){
        this.$message.warning(this.$t('robot.select_risk_tip'))
        return
      }
      const res = await stopSupremeHedge(this.robotType, this.hedgePauseItem.sid)
      if (!res) return
      if (res.success){
        this.$message.success(this.$t('robot.strategy') + this.$t('robot.paused'))
        this.dialogHePauseState = false
        this.getUserStrategyList()
      } else {
        this.$message.error(res.message)
      }
    },

    async  startHedge(item){ // 开启合约
      const res = await startSupremeHedge(this.robotType, item.sid)
      if (!res) return
      if(res.success){
        this.$message.success(this.$t('robot.strategy') + this.$t('robot.turned_on'))
        this.getUserStrategyList()
      } else {
        this.$message.error(res.message)
      }
    },

    async deleteHedge(){
      this.$alert((this.robotInfo.state == 2?`<span style="display: block;margin-top: 5px;color: #606266;line-height: 16px">${this.$t(`robot.delete_sj_for_closed_bot`)}</span>`: `<span style="display: block;margin-top: 5px;color: #606266;line-height: 16px">${this.$t(`robot.del_hedge`)}</span>`), '', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true
      }).then(async _ => {
        const res = await deleteSupremeHedge(this.robotType, this.delHedgeItem.sid)
        this.delHedgeItem = {}
        if (res.success) {
          this.$message.success(this.$t('robot.del_success'))
          // await this.getUserStrategyListAll();
          this.getUserStrategyList()
          await this.getSymbolCount();
        } else {
          this.$message.error(res.message)
        }
      }).catch(_ => {});
    },

    // 删除期现策略
    deleteTerm(){
      this.delTermDialog = true
    },
    // 删除期现策略
    async deleteTermTrue(){
      if (this.robotInfo.state == 2){
        this.stopTermState = '1'
      }
      const res = await deleteTermHedging(this.delTermItem.coin_two, this.robotType, this.delTermItem.old_new, this.stopTermState)
      this.delTermItem = {}
      if (res.success) {
        this.$message.success(this.$t('robot.del_success'))
        // await this.getUserStrategyListAll();
        this.delTermDialog = false
        this.getUserStrategyList()
        await this.getSymbolCount();
      } else {
        this.$message.error(res.message)
      }
    },

    // 刪除黃金三角
    deleteSj: function (id) {
      // this.$confirm(this.$t('robot.are_you_sure_del_sj'))
      //     .then(async _ => {
      //       const res = await delSj(id)
      //       if (res.success) {
      //         this.$message.success(this.$t('robot.del_success'))
      //         // await this.getUserStrategyListAll();
      //         this.getUserStrategyList()
      //         await this.getSymbolCount();
      //       } else {
      //         this.$message.error(res.message)
      //       }
      //     })
      //     .catch(_ => {});
      // this.$alert(`<span style="display: block">${this.$t(`robot.are_you_sure_del_sj`)}</span>` + (this.robotInfo.state == 2?`<span style="display: block;margin-top: 5px;color: #FF4D4F;line-height: 16px">机器人关闭状态删除策略将不执行撤单，如需撤单至交易所手动撤单</span>`: ''), '', {
      this.$alert((this.robotInfo.state == 2?`<span style="display: block;margin-top: 5px;color: #606266;line-height: 16px">${this.$t(`robot.delete_sj_for_closed_bot`)}</span>`: `<span style="display: block;margin-top: 5px;color: #606266;line-height: 16px">${this.$t(`robot.delete_sj_for_started_bot`)}</span>`), '', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true
      }).then(async _ => {
        const res = await delSj(id)
        if (res.success) {
          this.$message.success(this.$t('robot.del_success'))
          // await this.getUserStrategyListAll();
          this.getUserStrategyList()
          await this.getSymbolCount();
        } else {
          this.$message.error(res.message)
        }
      }).catch(_ => {});
    },
    delBb(coin) {
      this.delBbStrategy = true

      // this.$confirm(this.$t('robot.are_you_sure_del_bb'))
      //     .then(async _ => {
      //       const res = await delBb(coin)
      //       if (res.success) {
      //         this.$message.success(this.$t('robot.del_success'))
      //         // await this.getUserStrategyListAll();
      //         this.getUserStrategyList()
      //         await this.getSymbolCount();
      //       } else {
      //         this.$message.error(res.message)
      //       }
      //     })
      //     .catch(_ => {});
    },

    delBbNew(){
      if (this.robotInfo.state == 2){
        this.stopState = '2'
      }

      delBb(this.delBBItem.coin, this.stopState).then(res => {
        if(!res) return
        if(res.success){
          this.$message.success(this.$t('robot.del_success'))
          this.delBbStrategy = false
          this.getUserStrategyList()
          this.getSymbolCount();
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    delUb(id) {
      this.$confirm(this.$t('robot.are_you_sure_del_ub'))
          .then(async _ => {
            const res = await delUb(id)
            if (res.success) {
              this.$message.success(this.$t('robot.del_success'))
              // await this.getUserStrategyListAll();
              this.getUserStrategyList()
              await this.getSymbolCount();
            } else {
              this.$message.error(res.message)
            }
          })
          .catch(_ => {});
    },
    async delGrid(){
      let res = {};
      if (this.robotInfo.state == 2){
        this.stopState = '2'
      }
      if (this.delGridItem.strategyType == 3){
        res = await delGrid(this.delGridItem.strategy_uid, this.stopState)
      } else if (this.delGridItem.strategyType == 4){
        res = await delMartinStrategy(this.delGridItem.strategy_uid, this.stopState)
      }
      if (res.success) {
        this.$message.success(this.$t('robot.del_success'))
        this.dialogStopState = false;
        // await this.getUserStrategyListAll();
        this.getUserStrategyList()
        await this.getSymbolCount();
      } else {
        this.dialogStopState = false;
        this.$message.error(res.message)
      }

      // this.$confirm('確定刪除這條網格策略嗎？')
      //     .then(async _ => {
      //
      //     })
      //     .catch(_ => {});
    },
    startAndStopThis(item, num){
      if(num == 1){
        if (item.strategyType == 3) {
          this.startOrPauseGridStrategy(item.strategy_uid, num);
        } else if (item.strategyType == 4) {
          this.startOrPauseMartinStrategy(item.strategy_uid, num);
        }
      }else {
        if (item.strategyType == 4) {
          if (item.stop_replenishment == 2 || item.clearance_sale == 2){
            this.delTips(item);
            return;
          }
        }
        this.$confirm((item.strategyType == 4?this.$t('robot.you_sure_you_suspend_strategy_martin'):this.$t('robot.you_sure_you_suspend_strategy_grid')))
            .then(async _ => {
              if (item.strategyType == 3) {
                await this.startOrPauseGridStrategy(item.strategy_uid, num);
              } else if (item.strategyType == 4) {
                await this.startOrPauseMartinStrategy(item.strategy_uid, num);
              }
            })
            .catch(_ => {});
      }
      this.stopBubbling(event)
    },
    delTips(item){
      let msg = '';
      if (item.stop_replenishment == 2){
        msg=this.$t('robot.policy_will_stop_completed')
      }
      if (item.clearance_sale == 2){
        msg=this.$t('robot.policy_will_stop_clearance_sale')
      }
      this.$confirm(msg,{
        confirmButtonText: this.$t('robot.i_know'),
        showCancelButton:false
      }).then(async _ => {
      }).catch(_ => {});
    },

    // 网格
    async startOrPauseGridStrategy(uid, action) {
      const res = await startOrPauseGridStrategy(uid, action)
      if (!res) return;
      if (res.success) {
        if (action == 1) {
          this.$message.success(this.$t('robot.turned_on'))
        } else {
          this.$message.success(this.$t('robot.paused'))
        }
        // await this.getUserStrategyListAll();
        this.getUserStrategyList()
      } else {
        this.$message.error(res.message)
      }
    },

    // 马丁
    async startOrPauseMartinStrategy(uid, action){
      const res = await startOrPauseMartinStrategy(uid, action)
      if (!res) return;
      if (res.success) {
        if (action == 1) {
          this.$message.success(this.$t('robot.turned_on'))
        } else {
          this.$message.success(this.$t('robot.paused'))
        }
        // await this.getUserStrategyListAll();
        this.getUserStrategyList()
      } else {
        this.$message.error(res.message)
      }
    },

    async getAllCoin() {
      const res2 = await getAllCoins()
      if (res2 == null) {
        return;
      }
      if (res2.success) {
        this.allCoins = res2.data
      } else {
        this.$message.error(res2.message)
      }
    },
    async getAllCoinsUb() {
      const res2 = await getAllCoinsUb()
      if (res2 == null) {
        return;
      }
      if (res2.success) {
        this.allCoinsUb = res2.data
      } else {
        this.$message.error(res2.message)
      }
    },
    async getallSymbolsByCoin(num) {
      if (num == 1){
        document.getElementById('sj_input1').placeholder = this.coinName + this.$t('robot.trading_position')
        document.getElementById('sj_input2').placeholder = this.$t('robot.enter_content')
        document.getElementById('sj_input3').placeholder = this.$t('robot.enter_content')
        await this.getCoinBalance(this.coinName, '1_1')
        document.getElementById('SJ_coin_balance_2').innerText = ''
        document.getElementById('SJ_coin_balance_3').innerText = ''
      } else if(num == 2){
        document.getElementById('bb_input1').placeholder = this.coinName + this.$t('robot.trading_position')
        document.getElementById('bb_input2').placeholder = this.$t('robot.transaction_currency_position')
        await this.getCoinBalance(this.coinName, '2_1')
        document.getElementById('BB_coin_balance_2').innerText = ''
      } else if(num == 3){
        this.inputCoin = '1';
        this.amountValue = 0;
        this.amountValue2 = 0;
        await this.getAllSymbolsByCoin1(this.valueLin0)
      }
      this.valueLin1 = '';
      this.valueLin2 = '';
      this.quoteCoin = '';
      this.coinName2 = '';
      this.coinName3 = '';
      this.valueLin3 = '';
      this.huangjinvalue1 = '';
      this.huangjinvalue2 = '';
      this.huangjinvalue3 = '';
      this.huangjinvalue4 = '';
      this.huangjinvalue5 = '';
      this.inputCoin = ''
      if(num != 3){
        await this.getAllSymbolsByCoin1(this.coinName)
      }
    },
    async getAllSymbolsByCoin1(coinName) {
      const res3 = await getAllSymbolsByCoin(coinName)
      if (!res3) return
      if (res3.success) {
        this.allSymbolsByCoin = res3.data
        for (var i=0; i<this.allSymbolsByCoin.length; i++){
          if(parseFloat(this.allSymbolsByCoin[i].min_qty_true) > parseFloat(this.allSymbolsByCoin[i].min_qty)){
            this.allSymbolsByCoin[i].min_qty_result = this.allSymbolsByCoin[i].min_qty_true
          } else {
            this.allSymbolsByCoin[i].min_qty_result = this.allSymbolsByCoin[i].min_qty
          }
        }
      } else {
        this.$message.error(res3.message)
      }
    },
    async getallSymbolsByCoin2(num, item) {
      let coinNameList = item.target_coin+'/'+item.cost_coin
      this.valueLin1 = coinNameList
      let coinName2List = coinNameList.split("/");
      if(num == 1){
        if (this.coinName == coinName2List[0]){
          this.coinName2 = coinName2List[1]
        }
        if(this.coinName == coinName2List[1]){
          this.coinName2 = coinName2List[0]
        }

        var minPosition = ''
        var coin = this.coinName + '-' + this.coinName2
        // var coin_counter = coinName2List[1]+'-'+coinName2List[0]
        if (this.robotType == 1){
          if (coin == (item.target_coin +'-'+item.cost_coin)){
            minPosition = item.min_qty * 200
          } else if(coin == (item.cost_coin +'-'+item.target_coin)){
            minPosition = item.min_qty_true*200
          }
        } else if(this.robotType == 0){
          if (coin == (item.target_coin +'-'+item.cost_coin)){
            minPosition = item.min_qty_true * 200
          } else if(coin == (item.cost_coin +'-'+item.target_coin)){
            minPosition = item.min_notional*200
          }
        }
        this.coin_min_one = this.$options.filters['roundEightFilter'](minPosition)
        this.$nextTick(()=>{
          document.getElementById('SJ_coin_min_one').innerText = this.$t('robot.position_amount_must_be') + '≥' + this.$options.filters['roundEightFilter'](minPosition)
        })

        await this.getCoinBalance(this.coinName2, '1_2')
        document.getElementById('SJ_coin_balance_3').innerText = ''
      }
      this.valueLin2 = '';
      this.coinName3 = '';
      if (num != 2) {
        document.getElementById('sj_input2').placeholder = this.coinName2 + this.$t('robot.trading_position')
      }
      const res4 = await getAllSymbolsByCoin2(this.coinName,this.coinName2)
      if (!res4) return
      if(res4.success){
        this.allSymbolsByCoin2 = res4.data
      }else {
        this.$message.error(res4.message)
      }
    },
    getallSymbolsByCoin3(item) {
      let coinNameList = item.target_coin+'/'+item.cost_coin
      this.valueLin2 = coinNameList
      let coinName2List = coinNameList.split("/");

      if (this.coinName2 == coinName2List[0]) {
        this.coinName3 = coinName2List[1]
      }
      if (this.coinName2 == coinName2List[1]) {
        this.coinName3 = coinName2List[0]
      }

      var minPosition = ''
      var coin = this.coinName2 + '-' + this.coinName3
      if (this.robotType == 1){
        if (coin == (item.target_coin +'-'+item.cost_coin)){
          minPosition = item.min_qty * 200
        } else if(coin == (item.cost_coin +'-'+item.target_coin)){
          minPosition = item.min_qty_true*200
        }
      } else if(this.robotType == 0){
        if (coin == (item.target_coin +'-'+item.cost_coin)){
          minPosition = item.min_qty_true * 200
        } else if(coin == (item.cost_coin +'-'+item.target_coin)){
          minPosition = item.min_notional*200
        }
      }

      var minPosition_three = ''
      var coin_three = this.coinName3 +'-'+ this.coinName
      for (let i = 0; i < this.allSymbolsByCoin.length; i++) {
        if (coin_three == this.allSymbolsByCoin[i].target_coin +'-'+this.allSymbolsByCoin[i].cost_coin){
          if(this.robotType == 0){
            minPosition_three = this.allSymbolsByCoin[i].min_qty_true * 200
          } else if(this.robotType == 1){
            minPosition_three = this.allSymbolsByCoin[i].min_qty * 200
          }
        }else if (coin_three == this.allSymbolsByCoin[i].cost_coin +'-'+this.allSymbolsByCoin[i].target_coin){
          if(this.robotType == 0){
            minPosition_three = this.allSymbolsByCoin[i].min_notional*200
          }else if(this.robotType == 1){
            minPosition_three = this.allSymbolsByCoin[i].min_qty_true*200
          }
        }
      }
      this.coin_min_two = this.$options.filters['roundEightFilter'](minPosition)
      this.coin_min_three = this.$options.filters['roundEightFilter'](minPosition_three)

      this.$nextTick(()=>{
        document.getElementById('SJ_coin_min_two').innerText = this.$t('robot.position_amount_must_be') + '≥' + this.$options.filters['roundEightFilter'](minPosition)
        document.getElementById('SJ_coin_min_three').innerText = this.$t('robot.position_amount_must_be') + '≥' + this.$options.filters['roundEightFilter'](minPosition_three)
      })

      this.getCoinBalance(this.coinName3, '1_3')
      document.getElementById('sj_input3').placeholder = this.coinName3 + this.$t('robot.trading_position')
    },
    getallSymbolsByCoin4(item, num) {
      if (num == 1){
        let coins = item.target_coin+'/'+item.cost_coin;
        this.valueLin3 = coins;
        let coinName2List = coins.split("/");
        // if (this.coinName2 == coinName2List[0]) {
        //   this.quoteCoin = coinName2List[1]
        // }
        // if (this.coinName2 == coinName2List[1]) {
        //   this.quoteCoin = coinName2List[0]
        // }
        this.coinName2 = coinName2List[0]
        this.quoteCoin = coinName2List[1]
        this.selectCoinItem = item
        this.huangjinvalue1 = '';
        this.huangjinvalue2 = '';
        this.huangjinvalue3 = '';
        this.huangjinvalue4 = '';
        this.inputCoin = '1';
        this.timer = setTimeout(() => {
          document.getElementById('wran_single_grid').placeholder = this.$t('robot.input_amount_holder')
        }, 1)
        this.getCoinBalance(this.quoteCoin);
        this.getCoinBalance(this.coinName2,'3_2')
      }else{
        let coinNameList = item.target_coin + "/" + item.cost_coin;
        this.valueLin3 = coinNameList;
        let coinName2List = coinNameList.split("/");
        if (this.coinName == coinName2List[0]) {
          this.coinName2 = coinName2List[1]
        }
        if (this.coinName == coinName2List[1]) {
          this.coinName2 = coinName2List[0]
        }

        var coin = this.coinName + '-' + this.coinName2
        var minPosition_one = this.getMinPosition(item, coin)
        var coin2 = this.coinName2 + '-' + this.coinName
        var minPosition_two = this.getMinPosition(item, coin2)

        this.coin_min_one = this.$options.filters['roundEightFilter'](minPosition_one)
        this.coin_min_two = this.$options.filters['roundEightFilter'](minPosition_two)

        this.$nextTick(()=>{
          document.getElementById('BB_coin_min_one').innerText = this.$t('robot.position_amount_must_be') + '≥' + this.$options.filters['roundEightFilter'](minPosition_one)
          document.getElementById('BB_coin_min_two').innerText = this.$t('robot.position_amount_must_be') + '≥' + this.$options.filters['roundEightFilter'](minPosition_two)
        })

        this.checkSymbolDuplicate(coin)
        this.getCoinBalance(this.coinName2,'2_2')
      }
      if (num != 1) {
        document.getElementById('bb_input2').placeholder = this.coinName2 + this.$t('robot.trading_position')
      }
    },

    getMinPosition(item, coin){
      var minPosition = ''
      if (this.robotType == 1){
        if (coin == (item.target_coin +'-'+item.cost_coin)){
          minPosition = item.min_qty * 200
        } else if(coin == (item.cost_coin +'-'+item.target_coin)){
          minPosition = item.min_qty_true*200
        }
      } else if(this.robotType == 0){
        if (coin == (item.target_coin +'-'+item.cost_coin)){
          minPosition = item.min_qty_true * 200
        } else if(coin == (item.cost_coin +'-'+item.target_coin)){
          minPosition = item.min_notional*200
        }
      }
      return this.$options.filters['roundEightFilter'](minPosition)
    },

    checkSymbol(item){
      this.coinName = item.coin_name
      this.huangjinvalue4 = ''
      this.ubMinValue = (item.min_qty_true*200).toFixed(2)
      var coin = this.coinName + '-USDT'
      if (this.robotType == 1){
        document.getElementById('ub_input').placeholder = this.$t('robot.position_amount_must_be')+'≥'+((item.min_qty_true*200).toFixed(2))
      }
      this.checkSymbolDuplicate(coin)
    },
    async getSymbolCount(){
      const res = await getSymbolCount();
      if(res){
        if(res.success){
          if (res.data) {
            // this.tradingVolume = res.data.automatic
            // this.tradingVolumeOfSemi = res.data.manual
            this.spotFullCount = res.data.spot_full_count
            this.spotFullUsable = res.data.spot_full_usable
            this.swapCount = res.data.swap_count
            this.swapUsable = res.data.swap_usable
            this.spotSemiCount = res.data.spot_semi_count
            this.spotSemiUsable = res.data.spot_semi_usable
          }
        }
      }
    },
    // 查看策略详情
    showDetails(item){
      if (item.strategyType == 4) {
        sessionStorage.setItem('detailItem', JSON.stringify(item))
        this.$router.push({name: 'martin_detail', query: {id: 1, switchValue: this.switchValue, robotType: this.robotType, robotState: this.robotInfo.state}})
      }else if (item.strategyType == 3) {
        sessionStorage.setItem('detailItem', JSON.stringify(item))
        this.$router.push({name: 'semi_detail', query: {id: 1, switchValue: this.switchValue, robotType: this.robotType, robotState: this.robotInfo.state}})
      } else {
        let id = ''
        if (item.strategyType == 0){
          id = item.belong_to;
        } else if(item.strategyType == 1 || item.strategyType == 2){
          id = item.coin;
        }
        sessionStorage.setItem('detailItem', JSON.stringify(item))
        if (item.product_type == 'Term') {
          this.$router.push({name: 'term_detail', query: {id: 1, switchValue: this.switchValue, robotState: this.robotInfo.state}})
        } else if(item.product_type == 'hedge'){
          this.$router.push({name: 'contract_detail', query: {id: 1, switchValue: this.switchValue, robotState: this.robotInfo.state}})
        }else {
          this.$router.push({name: 'auto_detail', query: {id: 1, switchValue: this.switchValue, robotState: this.robotInfo.state}})
        }
      }
    },

    async getTickerPrice(){
      this.strategyLoading = true;
      const res = await getGridStrategyHistoryList(this.robotType,this.pageNum,10,10) // requestTimes:10 不显示全局滚动条
      if (!res) return;
      if (res.success){
        this.strategyLoading = false
        this.capitalListTotal = res.data.totalRow
        this.totalRow = res.data.totalRow
        this.strategyList = res.data.list
        if (!this.isMobile) {
          document.getElementById('history_scroll').scrollTo(top)
        }
        if (this.strategyList){
          for (let i=0; i<this.strategyList.length; i++){
            if (this.strategyList[i].product_type == 'martin'){
              this.strategyList[i].strategyType = 4;
            } else if (this.strategyList[i].product_type == 'grid'){
              this.strategyList[i].strategyType = 3;
            } else if (this.strategyList[i].product_type == 'ub'){
              this.strategyList[i].strategyType = 2;
            } else if (this.strategyList[i].product_type == 'bb'){
              this.strategyList[i].strategyType = 1;
            } else if (this.strategyList[i].product_type == 'sj'){
              this.strategyList[i].strategyType = 0;
            } else if (this.strategyList[i].product_type == 'hedge'){
              this.strategyList[i].strategyType = 5
            } else if (this.strategyList[i].product_type == 'Term'){
              this.strategyList[i].strategyType = 6
            }

            if(this.strategyList[i].strategyType == 4 || this.strategyList[i].strategyType == 3){
              this.strategyList[i].coin_one = this.strategyList[i].coin.split('-')[1]
              this.strategyList[i].coin_two = this.strategyList[i].coin.split('-')[0]
              this.strategyList[i].add_time = this.strategyList[i].create_time
              var timearr = this.strategyList[i].add_time.replace(" ", ":").replace(/\:/g, "-").split("-");
              this.strategyList[i].add_time_short = ""+timearr[1] + "-" + timearr[2] + " " + timearr[3] + ":" + timearr[4]
            }

          }
        }
        if (this.robotType == 0){
          this.strategyList_bn = this.strategyList
        } else {
          this.strategyList_ok = this.strategyList
        }
      } else {
        this.strategyLoading = false
        this.$message.warning(res.message)
      }
    },

    async getCoinBalance(quoteCoin, num){
      const math = require('mathjs')
      const res = await getCoinBalance(quoteCoin, this.robotType, this.balanceType, this.instType, this.futuresType);
      if (!res) return;
      if (res.success){
        if (!res.data) return;
        if (num == '2_1'){
          let balance = ''
          if (parseFloat(res.data)<0.01){
            balance = res.data
          } else{
            balance = this.$util.num_intercept(res.data)
          }
          this.balance_4 = this.$options.filters['roundEightFilter']((balance*(1+0.5)).toFixed(12))
          document.getElementById('BB_coin_balance_1').innerText = this.$t('robot.available')+'：'+ this.$options.filters['roundEightFilter'](balance)
        } else if(num == '2_2'){
          let balance = ''
          if (parseFloat(res.data)<0.01){
            balance = res.data
          } else{
            balance = this.$util.num_intercept(res.data)
          }
          this.balance_5 = this.$options.filters['roundEightFilter']((balance*(1+0.5)).toFixed(12))
          document.getElementById('BB_coin_balance_2').innerText = this.$t('robot.available')+'：'+ this.$options.filters['roundEightFilter'](balance)
        } else if(num == '1_1'){
          let balance = ''
          if (parseFloat(res.data)<0.01){
            balance = res.data
          } else{
            balance = this.$util.num_intercept(res.data)
          }
          this.balance_1 = this.$options.filters['roundEightFilter']((balance*(1+0.5)).toFixed(12))
          // this.balance_1 = this.$options.filters['roundEightFilter'](math.bignumber(balance).add(math.multiply(math.bignumber(balance), 0.5)))
          document.getElementById('SJ_coin_balance_1').innerText = this.$t('robot.available')+'：'+ this.$options.filters['roundEightFilter'](balance)
        }else if(num == '1_2'){
          let balance = ''
          if (parseFloat(res.data)<0.01){
            balance = res.data
          } else{
            balance = this.$util.num_intercept(res.data)
          }
          this.balance_2 = this.$options.filters['roundEightFilter']((balance*(1+0.5)).toFixed(12))
          document.getElementById('SJ_coin_balance_2').innerText = this.$t('robot.available')+'：'+ this.$options.filters['roundEightFilter'](balance)
        }else if(num == '1_3'){
          let balance = ''
          if (parseFloat(res.data)<0.01){
            balance = res.data
          } else{
            balance = this.$util.num_intercept(res.data)
          }
          this.balance_3 = this.$options.filters['roundEightFilter']((balance*(1+0.5)).toFixed(12))
          document.getElementById('SJ_coin_balance_3').innerText = this.$t('robot.available')+'：'+ this.$options.filters['roundEightFilter'](balance)
        } else if(num == '3_2'){
          this.coinBalance2 = res.data
        }else {
          this.coinBalance = res.data
          this.balance_6 = this.$options.filters['roundEightFilter']((this.coinBalance*(1+0.5)).toFixed(12))
          this.balance_7 = this.$options.filters['roundEightFilter']((this.coinBalance*(1+0.5)).toFixed(12))
        }
      }
    },

    getCoinBalanceSpot(coin, num){
      getCoinBalance(coin, this.robotType, this.balanceType, 1, this.futuresType).then(res =>{
        if(!res) return
        if(res.success){
          if(res.data){
            this.balanceBnSpot = this.$options.filters['roundTwoFilter'](res.data)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },

    changeValue(e){
      this.huangjinvalue4 = this.$options.filters['roundEightFilter']((this.coinBalance * e/100).toFixed(8))
    },

    changeValue2(e){
      this.huangjinvalue5 = this.$options.filters['roundEightFilter']((this.coinBalance2 * e/100).toFixed(8))
    },

    changeSwitch(){
      return this.$message.warning('暫未開放，請耐心等待！')
    },

    changePage(num){
      this.pageNum = num
      this.getTickerPrice()
    },
    changeListImg(num) {
      if (num == 0) {
        this.changes = 1
        this.changeImage = require("../../assets/img/robot/robot_strategy/details.svg");
        this.$cookies.set('changes',this.changes)
      } else {
        this.changes = 0
        this.changeImage = require("../../assets/img/robot/robot_strategy/concise.svg");
        this.$cookies.set('changes',this.changes)
      }
    },
    noAsk(e){
      this.$cookies.set('noAsk',e)
    },
    openMdList(){
      return this.$message.warning('暫未開放，敬請期待...')
      this.showMd = this.showMd
    },
    closeReadOnly(val,num) {
      this.$nextTick(() => {
        if (!val) {
          let input = {}
          switch (num) {
            case 1:
              const {select1} = this.$refs;
              input = select1.$el.querySelector('.el-input__inner');
              break;
            case 2:
              const {select2} = this.$refs;
              input = select2.$el.querySelector('.el-input__inner');
              break;
            case 3:
              const {select3} = this.$refs;
              input = select3.$el.querySelector('.el-input__inner');
              break;
            case 4:
              const {select4} = this.$refs;
              input = select4.$el.querySelector('.el-input__inner');
              break;
            case 5:
              const {select5} = this.$refs;
              input = select5.$el.querySelector('.el-input__inner');
              break;
            case 6:
              const {select6} = this.$refs;
              input = select6.$el.querySelector('.el-input__inner');
              break;
            case 7:
              const {select7} = this.$refs;
              input = select7.$el.querySelector('.el-input__inner');
              break;
            case 8:
              const {select8} = this.$refs;
              input = select8.$el.querySelector('.el-input__inner');
              break;
            case 9:
              const {select9} = this.$refs;
              input = select9.$el.querySelector('.el-input__inner');
              break;
            case 10:
              const {select10} = this.$refs;
              input = select10.$el.querySelector('.el-input__inner');
              break;
          }
          input.removeAttribute('readonly');
        }
      })
    },

    checkAllChange(val) { // 批量启动的全选功能
      if (val == false){
        this.selectAutoStrategyList = []
      }
      for (let i=0; i<this.strategyList.length; i++){
        // if (this.strategyList[i].strategyType != 3 && this.strategyList[i].strategyType != 4){
        if ((this.strategyList[i].strategyType == 0) && this.strategyList[i].state == 2){
          this.strategyList[i].checked = val
          // this.selectAutoStrategyList.push(this.strategyList[i])
        }
      }
      this.isIndeterminate = false;
    },

    checkStrategyChange(index, id, productType){ // 全自动策略多选功能
      this.selectAutoStrategyList = []
      this.strategyList.forEach((item, i) =>{
        if (item.checked == true){
          this.selectAutoStrategyList.push(item)
        }
      })

      this.count = this.selectAutoStrategyList.length
      this.checkAll = this.count === this.autoStrategyList.length;
      this.isIndeterminate = this.count > 0 && this.count < this.autoStrategyList.length;
    },

    openStrategy(type, item){ // 全自动策略创建后手动开启功能
      this.selectAutoStrategyList = []
      this.selectAutoStrategyList.push(item)
      this.startAutomaticStrategy()
    },

    startAutoStrategy(){ // 批量手动开启全自动策略
      this.selectAutoStrategyList = []
      this.strategyList.forEach(item =>{
        if (item.checked == true){
          this.selectAutoStrategyList.push(item)
        }
      })
      if(this.selectAutoStrategyList.length == 0){
        this.$message.warning('请先选择需要开启的全自动策略')
        return
      }
      this.startAutomaticStrategy()
    },

    cancelCheck(){ // 取消批量开启功能
      this.showCheck = false
      this.checkAll = false
      this.selectAutoStrategyList = []
      for (let i=0; i<this.strategyList.length; i++){
        this.strategyList[i].checked = false
      }
    },

    async startAutomaticStrategy() { // 批量开启全自动策略接口
      var data = JSON.stringify(this.selectAutoStrategyList)
      const res = await startAutomaticStrategy(data, 1, this.robotType)
      if (!res) return
      if (res.success) {
        this.cancelCheck()
        this.selectAutoStrategyList = []
        this.getUserStrategyList()
      } else {
        this.$message.error(res.message)
        this.selectAutoStrategyList = []
        if (res.statusCode == '2415') {
          this.cancelCheck()
          this.getUserStrategyList()
        }
      }
    },

    async checkSymbolDuplicate(symbol){ // 校验重复的交易对
      if (this.robotType == 1){
        return
      }
      const res = await checkSymbolDuplicate(symbol)
      if (!res) return
      if (res.success){
        this.coinMessage = '';
      } else {
        this.coinMessage = '您已有一组黄金三角交易含有'+ symbol +'交易对，不建议再添加该交易对'
        this.$message.warning('您已有一组黄金三角交易含有'+ symbol +'交易对，不建议再添加该交易对')
        return
      }
    },

    // radioChange(evt) {
    //   for (let i = 0; i < this.items.length; i++) {
    //     if (this.items[i].value === evt.detail.value) {
    //       this.current = i;
    //       if(this.items[i].value=='5'){
    //         this.stopLoss = 5
    //       } else if(this.items[i].value=='0') {
    //         this.stopLoss = 0
    //       } else if(this.items[i].value == '100') {
    //         this.stopLoss = 100
    //       }
    //       break;
    //     }
    //   }
    // },

    selectCoin(){
      if(this.baseCoin == 'DOT'){
        this.hedgePosition = '800'
      } else if(this.baseCoin == 'BTC' || this.baseCoin == 'ETH'){
        if(this.robotType == 0){
          this.hedgePosition = '20000'
        } else if(this.robotType == 1) {
          this.hedgePosition = '20000'
        }
      }
      this.contract_amount = ''
      this.getFuturesMinSpace()
    },

    getMinimumOrderListTerm(change_robot_type, termType) {
      var type = 1
      if (change_robot_type) {
        if (change_robot_type == '1') {
          type = 1
        } else {
          type = 0
        }
      } else {
        type = this.robotType
      }

      var speedType = 2
      if (termType) {
        if (termType == '2') {
          speedType = 2
        } else if (termType=='3') {
          speedType = 3
        } else if(termType == '4') {
          speedType = 4
        }
      } else {
        speedType = this.termType
      }
      minimumOrderListTerm(2, type, speedType).then(res=>{
        if(!res) return
        if(res.success){
          this.tableData = []
          var list = res.data
          for (var i = 0; i < list.length; i++) {
            var context = {}
            context.coin = list[i].coin
            context.position = parseInt(list[i].min_space)
            this.tableData.push(context)
          }
          console.log('qweqweqweqweqwe', this.termType)
          if(this.termType == 4) {
            this.hedgeCoinList = this.hedgeCoinList.filter(item => item.coin_name !== 'BTC');
            var coinTop = {coin_name: 'BTC'}
            this.hedgeCoinList.splice(0, 0, coinTop)
          }

        } else {
          this.$message.error(res.message)
        }
      })
    },

    getCoinList(change_robot_type) {
      var type = 1
      if (change_robot_type) {
        if (change_robot_type == '1') {
          type = 1
        } else {
          type = 0
        }
      } else {
        type = this.robotType
      }
      var speed = 0
      if(this.isTrialVersion == 'speedVersion'){
        speed = 0
      } else if(this.isTrialVersion == 'singleVersion') {
        speed = 2
      } else {
        speed = 0
      }
      getFuturesMinSpaceAll(2, type, speed, this.futureType).then(res=>{
        if(!res) return
        if(res.success){
          this.hedgeCoinList = res.data
          for (var i = 0; i < this.hedgeCoinList.length; i++) {
            this.hedgeCoinList[i].coin_name = this.hedgeCoinList[i].coin
            this.hedgeCoinList[i].position = this.hedgeCoinList[i].min_space
          }
          if (this.termType == 4) {
            this.hedgeCoinList = this.hedgeCoinList.filter(item => item.coin_name !== 'BTC');
            var coinTop = {coin_name: 'BTC'}
            this.hedgeCoinList.splice(0, 0, coinTop)
            console.log('123123123', this.hedgeCoinList.length)
          }
          // var list = res.value[0]
          // this.tableData
          // for (const [key, value] of list) {
          //   var context = {}
          //   // context.coin = key
          //   // context.position = value
          //   context.coin_name = key
          //   if(context.coin_name.indexOf('USDT')>1){
          //     context.coin_name = context.coin_name.replace('USDT', '')
          //   }
          //   this.hedgeCoinList.push(context)
          // }
        } else {
          this.$message.error(res.message)
        }
      })
    },

    selectTermCoin(){
      this.contract_amount = ''
      this.getTermMinSpace()
    },
    getIsModuleTestUser(){
      getIsModuleTestUser(this.module).then(res=>{
        if(!res) return
        if(res.success){
          this.testUser = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    getFuturesMinSpace(){
      var speed = ''
      if (this.isTrialVersion == 'singleVersion'){
        speed = '2'
      } else {
        speed = ''
      }
      getFuturesMinSpace(this.baseCoin, 10, this.minPositionRobotType?this.minPositionRobotType:this.robotType, speed, this.futureType).then(res=>{
        if(!res) return
        if(res.success){
          this.hedgePosition = res.data.minSpace
          this.hedgePositionRatio = res.data.minSpaceTrue
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    getTermMinSpace(){
      minimum_spot_hedging(this.baseCoin, this.robotType, this.termType).then(res=>{
        if(!res) return
        if(res.success){
          this.hedgePosition = res.data.minSpace
          // this.hedgePositionRatio = res.data.minSpaceTrue
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    showMInPosition(hedgeType){
      this.hedgeType = hedgeType
      if (hedgeType == 1) {
        this.getMinimumOrderListTerm()
        this.dialogShowTermPosition = true
        // if (this.robotType==1){
        //   this.hedgeCoinList = termokxlist
        // } else {
        //   this.hedgeCoinList = termbnlist
        // }
      } else {
        // this.hedgeCoinList = [{coin_name: 'DOT'}, {coin_name: 'LINK'}, {coin_name: 'BTC'}, {coin_name: 'ETH'}, {coin_name: 'ICP'}, {coin_name: 'UNI'}, {coin_name: 'XRP'}, {coin_name: 'DOGE'}, {coin_name: 'WLD'}, {coin_name: 'ANT'}, {coin_name: 'SOL'}, {coin_name: 'AAVE'}, {coin_name: 'AGLD'}, {coin_name: 'API3'}, {coin_name: 'ALPHA'}, {coin_name: 'CRV'}, {coin_name: 'SNX'}, {coin_name: 'LIT'}, {coin_name: 'XEM'}, {coin_name: 'MATIC'}, {coin_name: 'TRX'}, {coin_name: 'ATOM'}, {coin_name: 'XMR'}, {coin_name: 'ETC'}, {coin_name: 'HBAR'}, {coin_name: 'BNB'}, {coin_name: 'TON'}, {coin_name: 'INJ'}, {coin_name: 'SHIB'}, {coin_name: 'XLM'}, {coin_name: 'AVAX'}, {coin_name: 'BCH'}, {coin_name: 'LTC'}, {coin_name: 'CRO'}, {coin_name: 'FIL'}]
        this.getCoinList()
        this.dialogMinPosition = true
      }
      this.baseCoin = ''
      this.hedgePosition = ''
      this.minPositionRobotType = this.robotType
    },

    setMinpositionType(type){
      this.minPositionRobotType = type+''
      this.change_robot_type = type
      if (this.change_robot_type == 0) {
        this.futureType = 2
      }

      this.getCoinList(this.change_robot_type+'')

      if (!this.baseCoin) return
      this.getFuturesMinSpace()
    },

    setMinpositionTypeForTerm(type){
      this.minPositionRobotType = type+''
      this.change_robot_type = type
      this.minPositiontermType = 2
      this.getMinimumOrderListTerm(this.change_robot_type+'')

      if (!this.baseCoin) return
      this.getFuturesMinSpace()
    },

    setTypeForTerm(termType){
      this.minPositiontermType = termType
      console.log('qweqweqweqweqweqwe', this.change_robot_type)
      this.getMinimumOrderListTerm(this.change_robot_type+'', termType)
    },

    setTypeForSpeed(speed) {
      if (speed == 2) {
        this.futureType = 2
        this.isTrialVersion = 'singleVersion'
      } else {
        this.futureType = 1
        this.isTrialVersion = ''
      }
      this.getCoinList()
    },

    setTypeForHedge(hedgeType){
      this.futureType = hedgeType
      this.getCoinList()
    },

    switchFutureType(type) {
      if (this.futureType == type) return
      this.futureType = type
      this.getCoinList()
    },

    clearMinPositionData(){
      this.dialogMinPosition = false
      this.minPositionRobotType = ''
      console.log('asdasdasdasd', this.minPositionRobotType)
    }
  },
}
</script>

<style scoped>

.robot-strategy {
  /*height: 569px;*/
  height: unset;
}

.trade_z{
  border: #EAECEF 1px solid;
  border-radius: 2px;
  padding: 12px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  /*width: 60%;*/
}
.trade_z img {
  width: 36px;
  display: inline-block;
  vertical-align: middle;
}

.expanded_b {
  vertical-align: middle;
  width: 19px;
  margin-left: 10px
}

.trade{
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
  width: 86%;
}

.trade :nth-child(1) {
  font-size: 14px;
  display: block;
  font-weight: 800;

}

.trade :nth-child(2) {
  font-size: 14px;
  display: block;
  font-weight: 500;
  margin-top: 3px;
}

.trade_add_y{
  width: 28px;
  height: 28px;
  background: #E3B936;
  border-radius: 50%;
  color: white;
  font-size: 29px;
  display: inline-block;
  text-align: center;
  position: absolute;
  line-height: 28px;
  top: 30%;
  right: 15px;
  box-shadow: 0px 2px 8px 0px rgba(227, 185, 54, 0.2), 0px 2px 4px 0px rgba(227, 185, 54, 0.3);
}

.trade_add_b{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  font-size: 29px;
  display: inline-block;
  text-align: center;
  position: absolute;
  line-height: 28px;
  top: 30%;
  right: 15px;
  background: #AB54DB;
  box-shadow: 0px 2px 8px 0px rgba(44, 117, 255, 0.15), 0px 2px 4px 0px rgba(44, 117, 255, 0.3);
}

.create_celue {
  display: inline-block;
  width: 30%;
  vertical-align: top
}

.celue_div el-tab-pane{
  font-size: 14px;
  font-family: PingFangTC-Regular, PingFangTC;
  font-weight: 400;
  color: #AB54DB;
}

.celue_list{
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px #EAECEF solid;
}

.concise_list span{
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #1E2329;
}
.concise_list span:nth-child(n+1){
  margin-left: 10px;
}

.celue_list img{
  width: 33px;
  display: inline-block;
  vertical-align: middle;
}

.celue_title {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: black;
  vertical-align: middle;
}

.celue_content{
  position: relative;
  margin-top: 6px;

}

.celue_content div{
  display: inline-block;
}

.celue_content span{
  font-size: 14px;
  color: #72777E;
  font-weight: 300;
}

.coin_color_deep{
  color: #000000;
}

.coin_color_light{
  color: #C0C4C8;
}

.list_img{
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
}

.sj_title >>>.el-dialog__header{
  /*width: unset;*/
  /*display: block;*/

}

.risl_dialog_xieyi{
  margin-left: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #AB54DB;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .robot-strategy{
    height: unset;
  }
  .create_celue {
    display: block;
    width: 100%;
    vertical-align: top
  }
  .celue_div{
    display: inline-block;
    width: 100%;
    position: relative;
  }
  .celue_button{
    background-color: #F4F8FF;
    font-size: 14px;
    font-family: PingFangTC-Regular, PingFangTC;
    font-weight: 400;
    color: #AB54DB;
    border-radius: 1px;
    padding: 8px 20px;
    border: unset;
    justify-content: center;
  }
  .trade{
    width: 75%;
  }
  .trade span:nth-child(2){
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .trade_add_y{
    position: unset;
    top: 30%;
  }
  .trade_add_b{
    position: unset;
    top: 30%;
  }
  .list_img{
    margin-left: 20px;
  }
  .sj_explain {
    display: block;
    margin-top: -8px;
    margin-bottom: 10px;
  }

  >>>.el-tabs__item {
    padding: 0 8px;
  }
  >>>.el-select .el-input__inner {
    cursor: pointer;
    padding-right: 0;
  }
  .del_grid_span{
    width: unset;
  }
  .robot_set{
    min-width: unset;
  }
  .bb_side{
    margin: 0 5px 0 0 !important;
  }
  >>>.el-dialog{
    min-height: 350px;
  }

  .contract >>>.el-dialog{
    min-height: unset;
  }

  .dialog-one{
    display: block;
    margin-left: 0;
  }

  .dialog-Second{
    display: block;
    margin-left: 0;
  }
  .dialog_phone_footer >>>.el-dialog__footer{
    text-align: center !important;
  }
  .dialog_phone_footer >>>.el-dialog{
    min-height: 600px;
  }
  .error-tip{
    text-align: left !important;
  }

  .mobile-simple-strategy {
    display: inline-block;
    color: #1E2329;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    /*overflow: hidden;*/
    width: 90%;
  }

  .mobile-simple-strategy-position {
    max-width: 45%;
    margin-left: 0 !important;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .edit_bb_all {
    display: block;
    justify-content: space-between;
    padding: 0 0px
  }

  .edit_bb_dialog >>>.el-dialog__body{
    padding: 0 20px !important;
  }
  .edit_bb_dialog >>>.el-dialog{
    min-height: 100px;
  }

  .risl_dialog_xieyi{
    margin-left: 0;
    display: inline-block;
    vertical-align: middle;
    color: #AB54DB;
    cursor: pointer;
  }
}

@media screen and (min-width: 900px){
  .create_celue{
    border-right: 1px #EAECEF solid;
    padding-right: 1%;
  }

  .celue_div{
    /*border-left: 1px solid rgb(234, 236, 239);*/
    /*padding-left: 1%;*/
    display: inline-block !important;
    width: 68%;
    vertical-align: top;
    position: absolute;
    right: 0px;
  }

  .del_grid_span{
    width: 540px;
  }

  .sj_explain {
    display: inline-block;
    position: absolute;
    top: 23px;
    left: 210px;
  }

  .sj_explain_english {
    display: inline-block;
    position: absolute;
    top: 45px;
    left: unset;
  }

  .robot_set{
    min-width: 1030px
  }

  .dialog-one{
    display: inline-block;
    margin-left: 30px;
  }

  .dialog-Second{
    display: inline-block;
    margin-left: 30px;
  }

  .contentWidth2{
    width: 97.4%;
  }
  .contentWidth1{
    width: 100%;
  }

  .dialog-pc-bb >>>.el-dialog__body{
    /*padding-bottom: 60px;*/
    /*top: 150px !important;*/
    /*left: 20px !important;*/
    /*width: 89.5% !important;*/
    padding: 10px 20px 20px;
  }

  .edit_bb_all {
    display: flex;
    justify-content: space-between;
    padding: 0 0px
  }

  .edit_bb_dialog >>>.el-dialog__body{
    padding: 0 20px
  }

}

.set_gray{
  color: #72777E;
}
.set_gray span{
  color: #72777E !important;
}

.celue-selelct >>>.el-input__inner {
  cursor: pointer;
  border: none;
  text-align: right;
}

>>>.el-tree-node__label {
  font-size: 14px;
  font-weight: normal;
}

>>>.el-tabs__nav-wrap::after{
  height: 1px;
  background-color: unset;
}

>>>.el-tabs__active-bar{
  background-color: unset !important;
}

>>>.el-tabs__header{
  margin: 0px;
}

>>>.el-select-dropdown__empty {
  display: none;
}

/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

>>>.el-radio-button__inner {
  width: 100%;
  height: 30px;
  padding: 0;
   font-size: 12px;
  line-height: 30px;
}

.md-in-amount >>>.el-input__inner {
  padding: 0 50px 0 70px;
  text-align: right;
  color: #1E2329;
}

>>>.el-slider__runway{
  height: 2px;
}
>>>.el-slider__button{
  width: 8px;
  height: 8px;
  /*background-color: #BFBFBF;*/
  background-color: #AB54DB;
  border: unset;
}
>>>.el-slider__button-wrapper{
  top: -17px;
}

>>>.el-slider__stop{
  width: 6px;
  height: 6px;
  background: #FFFFFF;
  border: 1px solid #979797;
  top: -3px;
}
>>>.el-slider__bar {
  height: 2px;
  background-color: #AB54DB;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  position: absolute;
}

>>> .el-slider__marks-text {
  left: 98%;
  width: 38px;
  text-align: right;
  margin-top: 10px;
}

>>>.el-pagination{
  padding: 2px 0;
  margin: 12px 0 !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(26, 25, 25, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}

.el-divider--horizontal {
  margin: 10px 0 15px;
}

.dialog-second >>>.el-dialog__body{
  padding: 0px 20px 10px 20px;
  margin-top: -10px;
}

.links {
  width: 200px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #AB54DB;
  line-height: 20px;
}
.remember{
  color: #606266;
}

.links div {
  cursor: pointer;
}

>>>.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #AB54DB;
  border-color: #AB54DB;
}

>>>.el-checkbox__inner{
  background-color: white;
  border-color: #606266;
  width: 16px;
  height: 16px;
}
>>>.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #AB54DB;
}

>>>.el-checkbox__inner::after {
  height: 9px;
  width: 4px;
}

>>>.el-radio__inner {
  width: 16px;
  height: 16px;
}
>>>.el-radio__input.is-checked+.el-radio__label {
  color: #1E2329;
}
>>>.el-radio__label{
  color: #1E2329;
}

>>>.el-dialog__body > div > div.el-select-dropdown.el-popper{
  position: absolute !important;
  top: 36px !important;
  left: 0px !important;
}

>>>.el-dialog__body > div > div > div.el-select-dropdown.el-popper{
  position: absolute !important;
  top: 36px !important;
  left: 0px !important;
}

>>>.el-dialog__body > div > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__bar {
  opacity: 1 !important;
  /*background-color: #AB54DB;*/
}
.section3_select >>>.el-select-dropdown{
  position: absolute !important;
  top: 36px !important;
  left: 0 !important;
}

.section3_select >>>.el-input__inner {
  text-align: right;
  padding-right: 28px;
}

.array-android-css{
  margin-top: 4px;
}
.array-android-css2{
  line-height: 0 !important;
  margin-top: -3px;
}

.strategy >>>.el-dialog__body {
  padding: 10px 20px 20px;
}

.risk_dialog >>>.el-dialog__footer{
  text-align: center;
}

.risk_dialog >>>.el-dialog__body{
  padding-top: 10px;
}

.risk_dialog >>>.el-checkbox__inner{
  vertical-align: middle;
}

.change_item_bg {
  background-color: #fefefe;
  height: 20px;
  width: 10px;
  border-radius: 6px;
  position: relative;
  z-index: 9;
  cursor: pointer;
  border: 1px solid #DCDFE6;
}

.contract >>>.el-collapse{
  border-top: unset !important;
  border-bottom: unset !important;
}

.stop_less_style >>>.el-collapse-item__header{
  border-bottom: unset;
  height: unset;
  line-height: unset;
}

>>>.el-collapse-item__arrow::before{
  content: '\e6df' !important;
  font-size: 16px;
  color: #C0C4CC;
}

>>>.el-collapse-item__arrow.is-active {
  transform: rotate(-180deg) !important;
}

>>>.el-collapse-item__wrap{
  border-bottom: unset;
}

>>>.el-collapse-item__content {
  padding-bottom: 0;
}

.min_position >>>.el-dialog__body{
  padding: 0px 20px 220px;
}

.Term >>>.el-dialog {
  height: 500px;
}

>>>.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  overflow: hidden; /* 针对Webkit浏览器 */
  scrollbar-arrow-color: transparent;
}

/* 自定义整个滚动条 */
>>>.el-table--scrollable-y ::-webkit-scrollbar {
  width: 5px; /* 设置滚动条的宽度 */
  border-radius: 25px !important;
}

/* 自定义滚动条轨道 */
>>>.el-table--scrollable-y ::-webkit-scrollbar-track {
  background: #f1f1f1; /* 设置轨道的背景颜色 */
}

/* 自定义滚动条的滑块（thumb） */
>>>.el-table--scrollable-y ::-webkit-scrollbar-thumb {
  background: #C1C1C1; /* 设置滑块的背景颜色 */
  border-radius: 25px;
}

/* 当滑块悬停或活动时自定义滚动条的滑块 */
>>>.el-table--scrollable-y ::-webkit-scrollbar-thumb:hover {
  background: transparent; /* 设置滑块在悬停状态下的背景颜色 */
}

</style>
