<template>
  <div class="container">
    <div class="home-top">
      <div v-if="showCardActivity">
        <div v-if="!isMobile" style="background: #000000; height: 50px; line-height: 50px; text-align: center;position: fixed;top: 64px;width: 100%;">
          <img style="display: inline-block; vertical-align: middle; margin-right: 10px; width: 21px;" src="../../assets/img/icon/guangbo_icon.png">
          <span style="color: #FFFFFF; font-size: 16px; display: inline-block; letter-spacing: 0.07em; font-weight: 500"><span class="guanggao-t-1">UPLUS，</span><span class="guanggao-t-2">重磅升级！</span>所有合约用户燃料费/点卡<span class="guanggao-t-3">7折</span>减免中..</span>
        </div>
        <div v-if="isMobile" style="background: linear-gradient(100deg, #6f359f 0%, #4e62e4 70%, #573599 100%); width: 100%; margin: 0 auto; height: 40px; line-height: 40px; position: fixed; top: 54px;">
          <span class="scrolling-text" style="display: inline-block; width: 100%; color: #FFFFFF; margin: 0 auto; font-size: 14px">UPLUS，重磅升级！所有合约用户燃料费/点卡7折减免中..</span>
        </div>
      </div>
      <div v-else>
        <div v-if="isMobile" id="downloadBar_mb" style="background: #AB54DB; width: 100%;height: 43px; position: fixed; top: 54px;z-index: 99;transition-duration: .5s" :style="{width:isClosed==false?'3%':'100%', heifgt:isClosed==false?'0px':'43px', right: isClosed==false?'227':'', top: isClosed==false?'4px':'54px', transform:isClosed==false?'scale(0.01, 0.1)':''}">
          <div style="display: flex; height: -webkit-fill-available; align-items: center; justify-content: center">
            <span style="display: inline-block; font-size: 16px;line-height: 22px;color: #FFFFFF">全自动量化机器人</span>
            <div style="display: inline-block;border: 1px solid #FEFEFE;border-radius: 8px; padding: 3px 10px;cursor: pointer;margin-left: 30px" @click="gotoDownLoad()">
              <span style="display: inline-block;vertical-align: middle;color: #FFFFFF;font-size: 12px">立即下载</span>
              <img style="width: 17px; height: 17px;display: inline-block;vertical-align: middle; margin-left: 10px" src="../../assets/img/download/download_icon.png">
            </div>
            <span class="el-icon-close" style="position: absolute; right: 15px; top: 50%; font-size: 20px; color: #FFFFFF; transform: translate(0, -50%); cursor: pointer" @click="closeDownloadBar()"></span>
          </div>
        </div>
        <div v-if="!isMobile" id="downloadBar_pc" style="background: #AB54DB; width: 100%;height: 60px; position: fixed; top: 64px;z-index: 99;transition-duration: .5s" :style="{width:isClosed==false?'3%':'100%', heifgt:isClosed==false?'0px':'60px', right: isClosed==false?'180px':'', top: isClosed==false?'4px':'64px', transform:isClosed==false?'scale(0.01, 0.1)':''}">
          <div style="width: 100%; height: -webkit-fill-available; display: flex;align-items: center; justify-content: center;position: relative">
            <span style="display: inline-block; font-size: 18px;line-height: 22px;letter-spacing: 0.365em;color: #FFFFFF">智能合约交易 | 全自动量化机器人</span>
            <div style="display: inline-block;margin-left: 100px; border: 1px solid #FEFEFE;border-radius: 8px; padding: 3px 30px;cursor: pointer" @click="gotoDownLoad()">
              <span style="display: inline-block;vertical-align: middle;color: #FFFFFF">立即下载</span>
              <img style="width: 17px; height: 17px;display: inline-block;vertical-align: middle; margin-left: 10px" src="../../assets/img/download/download_icon.png">
            </div>
            <span class="el-icon-close" style="position: absolute; right: 20px; top: 50%; font-size: 23px; color: #FFFFFF; transform: translate(0, -50%); cursor: pointer" @click="closeDownloadBar()"></span>
          </div>
        </div>
      </div>
      <div class="nav-container">
        <div class="nav">

        </div>
        <div v-if="isMobile" class="quantitative" style="margin-top: 30px; height: 450px; display: flex; flex-direction: column; align-items: center; justify-content: center; transition-duration: .5s" :style="{height: isClosed==false?'407px':'450px'}">
          <img style="height: 140px; width: 158px; position: unset;" src="../../assets/img/home/banner_right_1.png" alt="">
          <div class="quantitative-title" style="margin-top: 35px; min-height: unset">UPlus 量化交易机器人</div>
          <div class="quantitative-sub-title" style="min-height: unset; margin: 10px 0 0; width: 100%; text-align: center;">
            <div class="quantitative-title1" style="font-size: 16px; width: 100%;">月化收益高达 <span class="invite-num" style="font-size: 24px">30% - 60%</span></div>
            <div class="quantitative-title2" style="font-size: 16px; margin-top: 10px">百万加密货币投资者信赖</div>
          </div>
          <div @click="$router.push('/robot')" class="quantitative-btn" style="position: unset; margin-top: 50px; background: #AB54DB;border-radius: 28px;height: 46px;width: 70%; line-height: 50px;font-size: 16px">开启机器人</div>
          <div @click="gotoProfit(2)" class="quantitative-btn" style="position: unset; margin-top: 20px; border: #AB54DB 1px solid; background: rgba(171, 84, 219, 0.3);border-radius: 28px;height: 44px; width: 70%; line-height: 50px;font-size: 16px;">查看全网收益</div>
        </div>
        <div v-if="!isMobile" class="quantitative" style="margin-top: 20px; height: 656px; display: flex; align-items: center; justify-content: space-between; transition-duration: .5s" :style="{height: isClosed==false?'596px':'656px'}">
          <div>
            <div class="quantitative-title">UPlus<br>量化交易机器人</div>
            <div class="quantitative-sub-title">
              <div class="quantitative-title1" style="font-size: 28px">月化收益高达 <span class="invite-num" style="">30% - 60%</span></div>
              <div class="quantitative-title2" style="font-size: 28px">百万加密货币投资者信赖</div>
            </div>
            <div @click="$router.push('/robot')" class="quantitative-btn" style="display: inline-block; position: unset; margin-top: 56px; background: #AB54DB;border-radius: 28px;height: 60px;line-height: 60px;font-size: 20px">开启机器人</div>
            <div @click="gotoProfit(1)" class="quantitative-btn" style="display: inline-block; position: unset; border: #AB54DB 1px solid; margin-top: 56px; background: rgba(171, 84, 219, 0.3);border-radius: 28px;height: 58px;line-height: 58px;font-size: 20px;color: #fefefe; margin-left: 30px">查看全网收益</div>
          </div>
          <img style="height: 417px; width: 471px; position: unset;" src="../../assets/img/home/banner_right_1.png" alt="">
        </div>
      </div>
    </div>
    <div v-if="!isMobile" class="telegram_bg_dark" style="position:fixed; right: 30px; top: 160px">
      <div style="display: flex; align-items: center; padding: 15px 32px">
        <img style="width: 50px; height: 50px;" :src="telegram_icon">
        <div style="display: inline-block; margin-left: 25px; position: relative">
          <a class="telegram_bth_bg" href="https://t.me/Uplus_Cynthia">添加官方电报</a>
          <a class="telegram_bth_bg" style="margin-top: 10px" href="https://t.me/uplus6666">订阅官方频道</a>
          <button class="telegram_bth_bg" style="margin-top: 10px" @mouseover="downloadDialog()" @mouseout="hideDownloadDialog()">立即下载App</button>
          <div  v-if="showDownloadImage" :class="isShowDownloadColor?'shwo_download_grey_bg':'shwo_download_bg'">
            <div style="margin-top: 30px">
              <div style="display: inline-block">
                <img style="width: 66px; height: 66px;" src="../../assets/img/home/download/iOS_miaoshu.png">
                <span style="display: block; font-size: 12px">iOS 下载</span>
              </div>
              <div style="display: inline-block; font-size: 12px; margin-left: 20px">
                <img style="width: 66px; height: 66px;" src="../../assets/img/home/download/new_platform.png">
                <span style="display: block">Android 下载</span>
              </div>
            </div>
            <span style="display: block; font-size: 14px; color: #AB54DB; font-weight: 500; cursor: pointer; margin-top: 10px" @click="$router.push('download')">【更多下载方式】</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile"  class="telegram_bg_dark" style="position:fixed; right: 10px; top: 110px;z-index: 99">
      <div style="display: flex; flex-direction: column; align-items: center; padding: 10px">
        <img style="display: block; width: 25px; height: 25px;" :src="telegram_icon">
        <a class="telegram_bth_bg" style="width: 88px;height: 28px; margin-top: 7px;font-size: 12px" href="https://t.me/Uplus_Cynthia">添加官方电报</a>
        <a class="telegram_bth_bg" style="width: 88px;height: 28px; margin-top: 7px;font-size: 12px" href="https://t.me/uplus6666">订阅官方频道</a>
      </div>
    </div>
    <div v-if="isMobile" style="width: 100%; display: flex;flex-direction: column;align-items: center;">
      <div style="width: 100%; height: 56px;background: #24242F;display: flex; align-items: center">
        <div style="padding: 0 20px" @click="$router.push('helpNoticeList?titleNum=5_1&lastPage=lastPage')">
          <img style="display: inline-block;width: 18px;height: 18px;vertical-align: top" src="../../assets/img/home/home_notice.png">
          <span v-if="index == 0" style="display: inline-block;font-size: 14px;color: #FEFEFE;width: 91%;margin-left: 2%" v-for="(item, index) in noticeLise" >{{ item.title }}<span>{{ item.time }}</span> | <span style="color: #AB54DB">查看更多</span></span>
        </div>
      </div>
    </div>
    <div v-if="!isMobile" style="width: 100%; display: flex;flex-direction: column;align-items: center;">
      <div style="width: 100%; height: 100px;background: #393948">
        <div v-if="noticeLise && noticeLise.length != 0" style="max-width: 1200px; height: 100px; margin: 0 auto;display: flex; align-items: center;cursor: pointer" @click="$router.push('helpNoticeList?titleNum=5_1&lastPage=lastPage')">
          <div v-if="index==0" style="display: inline-block; width: 45%" v-for="(item, index) in noticeLise">
            <span style="font-size: 12px;color: #FFBB54;border: 1px solid #FFBB54;border-radius: 5px;padding: 3px 5px">New</span>
            <span style="font-size: 14px;color: #F9FAFA;margin-left: 10px">{{ item.time }}</span>
            <span style="display: block; margin-top: 5px; font-size: 14px;color: #F9FAFA;">{{ item.title }}</span>
          </div>
          <div style="display: inline-block; height: 40px;width: 1px;background: #FFFFFF;margin: 0 4.9%"></div>
          <div v-if="index==1" style="display: inline-block; width: 45%;" v-for="(item, index) in noticeLise">
            <span style="font-size: 14px;color: #F9FAFA;">{{ item.time }}</span>
            <span style="display: block; margin-top: 5px; font-size: 14px;color: #F9FAFA;">{{ item.title }}</span>
          </div>
        </div>
      </div>
      <img style="height: 51px; margin-top: -10px" src="../../assets/img/home/notic_icon.png">
    </div>
    <div class="content-container" style="max-width: unset; padding: 0; background: #F7F8FA;" :style="{marginTop:isMobile?'unset':'-40px'}">
      <Home_product></Home_product>
    </div>
    <div class="advice-container-external" style="background: #393948">
      <div class="advice-container">
        <Home_advice_lg class="lg"></Home_advice_lg>
        <Home_advice_sm class="sm"></Home_advice_sm>
      </div>
    </div>
    <div class="content-container" style="width: 100%; max-width: unset;background: #F7F8FA;padding: unset">
      <Home_process></Home_process>
    </div>
    <div class="content-container" style="width: 100%; max-width: unset;padding: unset">
      <Home_data></Home_data>
    </div>
    <div v-if="!isMobile" style="height: 320px;width: 100%;background: #F7F8FA;display: flex; flex-direction: column;align-items: center; justify-content: center">
      <span style="display: block;font-weight: 700;font-size: 40px;color: #AB54DB;">UPlus</span>
      <span style="display: block;font-weight: 500;font-size: 32px;color: #191919;margin-top: 20px">注册立享永久免费量化工具</span>
      <span style="display: block;width: 237px;height: 50px;border: 1px solid #191919;border-radius: 50px;font-weight: 500;font-size: 15px;line-height: 50px;text-align: center;color: #191919;margin-top: 50px;cursor: pointer" @click="$router.push('../register')">立即注册</span>
    </div>
    <div v-if="isMobile" style="height: 260px;width: 100%;background: #F7F8FA;display: flex; flex-direction: column;align-items: center; justify-content: center">
      <span style="display: block;font-weight: 700;font-size: 24px;color: #AB54DB;">UPlus</span>
      <span style="display: block;font-weight: 500;font-size: 16px;color: #191919;margin-top: 5px">注册立享永久免费量化工具</span>
      <span style="display: block;width: 237px;height: 40px;border: 1px solid #191919;border-radius: 50px;font-weight: 500;font-size: 14px;line-height: 40px;text-align: center;color: #191919;margin-top: 50px;cursor: pointer" @click="$router.push('../register')">立即注册</span>
    </div>
    <div id="download_app" style="width: 100%;background: #393948;">
      <home_download></home_download>
    </div>
    <div style="background: #F7F8FA;">
      <home_partners></home_partners>
    </div>
    <div style="background: #393948;width: 100%;">
      <home_person></home_person>
    </div>

<!--    <div class="content-container">-->
<!--      <home_advantages></home_advantages>-->
<!--    </div>-->
    <Footer_lg v-if="!isMobile" class="lg-footer"></Footer_lg>
    <Footer_sm v-if="isMobile" class="sm-footer"></Footer_sm>
    <Navbar :isclosed="isClosed"></Navbar>
    <div v-if="$route.path !='/messageBack' && $route.path != '/robot/martin_add' && !showMessage" class="message_icon" style="border-radius: 50%;cursor: pointer" @click="gotoMessage()">
      <div v-if="msgCount > 0" style="position:absolute;left:0;top:0;background-color: #FF4D4F;width: 10px;height: 10px;border-radius: 50%"></div>
      <img src="../../assets/img/message.svg" style="display: block;width: 23px; margin: 10px auto 0;"></img>
    </div>
    <el-drawer
        class="message-bax"
        :title="$t('footer.ul_li1')"
        :visible.sync="showMessage"
        :modal="false"
        :before-close="handleClose"
        :direction="direction">
      <div style="width: 100%;height: 1px;background: #EAECEF;margin: 10px 3% 10px 0"></div>
      <div style="background-color: white; position: relative;height: 96.6%">
        <div class="message-bax-top" id="message-content" style="overflow: auto" ref="viewBox">
          <div style="font-size: 14px" v-for="(item, i) in messageList">
            <div style="position:relative;padding:0 20px">
              <span v-if="i== 0 || (i != 0 && (item.create_this_time-messageList[i-1].create_this_time)>1000*60*60*2)" style="display: block; width: 100%; text-align: center;margin-top: 10px;font-size: 12px;color: #72777E">{{ item.create_time }}</span>
              <div v-if="item.type =='user'" style="width: 100%; text-align: -webkit-right;margin-top: 10px">
                <div style="display: inline-block; width: 76%;">
                  <div v-if="item.content != 'pic_info'" style="display: block;max-width: 100%;width: fit-content;background-color: #E0EBFF;padding: 15px;border-radius: 10px;margin-bottom: 10px">
                    <span style="display:block; width:100%; text-align: left;word-break: break-all;color: #1E2329" v-html="item.content"></span>
                  </div>
                  <img v-if="item.picture" style="display:block;width: 100px;border-radius: 10px" :src="item.picture_small">
                </div>
                <img v-if="user_header" style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;margin-left: 6px;display: inline-block;vertical-align: top" :src="user_header">
                <img v-else style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;margin-left: 6px;display: inline-block;vertical-align: top" src="../../assets/img/avatar.png">
              </div>
              <div v-if="item.type == 'service'" style="display:block; width:100%; text-align: left;margin-top: 10px">
                <img style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;display: inline-block;margin-right: 6px;vertical-align: top" src="../../assets/img/msg_header.jpg">
                <div style="display: inline-block;width: 76%">
                  <span style="display: block;vertical-align: top;word-break: break-all;width: fit-content;background-color: #f7f7f7;padding: 15px;border-radius: 10px;color: #1E2329">{{ item.content?item.content=='^^^&&&***###'?'你好！我是规划师，很高兴为你服务。请用一两句话简述你的问题。':item.content:'' }}</span>
                  <img v-if="item.replyPictureList" style="display: block;width: 100px;margin-top: 10px; border-radius: 10px;" v-for="items in item.replyPictureList" :src="items + '/small'">
                </div>
              </div>
              <div v-if="(messageList.length == 0 || i == messageList.length -1) && showTimeNoOnline == true" style="display:block; width:100%; text-align: left;margin-top: 10px">
                <img style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;display: inline-block;margin-right: 6px;vertical-align: top" src="../../assets/img/msg_header.jpg">
                <div style="display: inline-block;width: 76%">
                  <span style="display: block;vertical-align: top;word-break: break-all;width: fit-content;background-color: #f7f7f7;padding: 15px;border-radius: 10px;color: #1E2329;-webkit-user-select: text; user-select: text;">客服上班时间是：
                    ☀️上午09：00-12:00
                    🌙下午13：00-18:00

                    有任何问题您可以在这个时间段联系我们🌷~
                    非在班时间的话可以先留言，上班后也会第一时间回复您的呢❤️
                    祝您身体健康，工作顺利，财源滚滚👍</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="message-bax-bottom" style="position:relative;">
          <div style="position: relative">
            <div style="display:inline-block;vertical-align: middle">
              <el-upload
                  class="avatar-uploader"
                  :action="upload"
                  name="pic"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-preview="handlePictureCardPreview">
                <div class="" style="text-align: center;">
                  <img style="width: 30px; height: 28px; text-align: center" src="../../assets/img/msg_pic.svg">
                </div>
              </el-upload>
            </div>
            <el-input style="display:inline-block;font-size: 14px;border: unset;width: 60%;vertical-align: middle;" :autosize="{ minRows: 1, maxRows: 3 }" type="textarea" :placeholder="$t('robot.enter_content')" v-model="feedbackContent" @input="totalinput($event)"></el-input>
            <el-button class="submit-btn" type="primary" style="" @click="submit()">{{ $t('footer.send') }}</el-button>
          </div>
        </div>
      </div>
      <!--        <iframe style="height: 100%; width: 100%;" src="https://chatbot.aliyuncs.com/intl/index.htm?from=4qOUAx1INE" name="obj" frameborder="0"></iframe>-->
    </el-drawer>
    <div v-if="isShowLiveView" style="position: fixed; left: 50px; top: 180px; z-index: 9999; cursor: pointer" @click="gotoLiveView()">
      <div style="background: #C3ACD7; border-radius: 12px; height: 46px; width: 125px; text-align: center;">
        <img style="width: 30px; margin-top: -50%; height: 30px;" src="../../assets/img/icon/logo_little.png" mode=""></img>
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -40%); width: 100%;">
          <span style="color: #2B2828; font-size: 14px; display: inline-block; vertical-align: middle; font-weight: 600;">UPLUS直播</span>
          <img style="width: 16px; height: 16px; display: inline-block; vertical-align: middle; margin-left: 5px; border-radius: 3px;" src="../../assets/img/icon/living.gif"></img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Home_product from "./Home_product";
import Home_advice_lg from "./Home_advice/Home_advice_lg";
import Home_advice_sm from "./Home_advice/Home_advice_table";
import home_advantages from "./home_advantages";
import Home_process from "./Home_process";
import Home_data from "./Home_data";
import home_partners from "./home_partners"
import home_download from "./home_download"
import home_person from "./home_person"
import Footer_lg from "../../components/footer/Footer_lg";
import Footer_sm from "../../components/footer/Footer_sm";
import Navbar from "./Navbar";
import { getNoticeMsgList } from "../../server/helper.js"
import { feedbackRequest, getFeedbackCount, getFeedbackMsg } from "../../server/auth.js"
import {baseUrl} from "../../utils/request";
import {getActivityById_Other} from "../../server/activity"
export default {
  name: "Home_new",
  components:{
    Home_product,
    Home_advice_lg,
    Home_advice_sm,
    home_advantages,
    Home_process,
    Home_data,
    home_partners,
    home_download,
    home_person,
    Footer_lg,
    Footer_sm,
    Navbar
  },

  watch:{
    "$store.state.showFeedBack":{
      handler:function (newValue, oldValue){
        if (newValue == true){
          this.showMessage = newValue
          this.getMessageInfo()
          this.$nextTick(()=>{
            this.scrollListener()
          })
        }
      }
    }
  },

  data(){
    return{
      isMobile: false,
      noticeLise: [],
      msgCount: 0,
      showMessage: false,
      selectValue: '其他',
      feedbackContent: '',
      messageList: [],
      showTimeNoOnline: false, // 为true时 提示客服不在线
      pageNum: 1,
      pageSize: 10,
      totalPage: 1,
      user_header: '',
      timer: null, // 定时器
      msgHeight: 0,
      direction: 'btt',
      upload: baseUrl + '/other/uploadImage',
      uid: '',
      isClosed: true,
      showDownloadImage: false,
      telegram_icon: require('../../assets/img/home/home_telegram_white.png'),
      isShowDownloadColor: false,
      token: '',
      isShowLiveView: false,
      liveActivityInfo: {},
      showCardActivity: false
    }
  },

  created() {
    // this.$i18n.locale='en'
    if (this.$util._isMobile()){
      this.isMobile = true
    }
    this.saveTip()
    this.getNoticeMsgList()
    window.addEventListener('scroll',this.changeNav,true)
    this.livingView(10)
  },

  mounted() {
    var thisTimes = new Date().getTime()
    if(thisTimes>=1703865600000 && thisTimes<1704643200000) {
      this.showCardActivity = true
    } else {
      this.showCardActivity = false
    }
    this.messageList = []
    this.index = this.$route.query.showMessage
    if (sessionStorage.getItem('isClosed')){
      this.isClosed = JSON.parse(sessionStorage.getItem('isClosed'))
      if (this.isClosed == false){
        if(this.isMobile){
          document.getElementById('downloadBar_mb').style.display = 'none'
        } else {
          document.getElementById('downloadBar_pc').style.display = 'none'
        }
      } else {
        if(this.isMobile){
          document.getElementById('downloadBar_mb').style.display = 'block'
        } else {
          document.getElementById('downloadBar_pc').style.display = 'block'
        }
      }
    }
    this.token = localStorage.getItem('token')
    var that = this
    if(!this.token){
      const fpPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.onload = resolve
        script.onerror = reject
        script.async = true
        script.src = 'https://cdn.jsdelivr.net/npm/' + '@fingerprintjs/fingerprintjs@3/dist/fp.min.js'
        document.head.appendChild(script)
      }).then(() => FingerprintJS.load())
      fpPromise.then(fp => fp.get()).then(result => {
        const visitorId = result.visitorId
        this.uid = visitorId
        if (this.$store.state.showFeedBack == true){
          this.$store.state.showFeedBack = false
          this.showMessage = true
          this.getMessageInfo();
          this.$nextTick(()=>{
            this.scrollListener()
          })
        }
      })
    } else {
      if (this.$store.state.showFeedBack == true){
        this.$store.state.showFeedBack = false
        this.showMessage = true
        this.getMessageInfo();
        this.$nextTick(()=>{
          this.scrollListener()
        })
      }
    }
  },

  methods:{
    changeNav(){
      if (this.isMobile){
        if (document.documentElement.scrollTop<=535) this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
        if (document.documentElement.scrollTop>535&&document.documentElement.scrollTop<=1138) this.telegram_icon = require('../../assets/img/home/home_telegram_black.png')
        if (document.documentElement.scrollTop>1138&&document.documentElement.scrollTop<=1689) this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
        if (document.documentElement.scrollTop>1689&&document.documentElement.scrollTop<=2373) this.telegram_icon = require('../../assets/img/home/home_telegram_black.png')
        if (document.documentElement.scrollTop>2373&&document.documentElement.scrollTop<=2896) this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
        if (document.documentElement.scrollTop>2896&&document.documentElement.scrollTop<=3154) this.telegram_icon = require('../../assets/img/home/home_telegram_black.png')
        if (document.documentElement.scrollTop>3154&&document.documentElement.scrollTop<=3751) this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
        if (document.documentElement.scrollTop>3751&&document.documentElement.scrollTop<=4243) this.telegram_icon = require('../../assets/img/home/home_telegram_black.png')
        if (document.documentElement.scrollTop>4243&&document.documentElement.scrollTop<=5000) this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
      } else {
        if (document.documentElement.scrollTop<=600) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
          this.isShowDownloadColor = false
        }
        if (document.documentElement.scrollTop>600&&document.documentElement.scrollTop<=1186) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_black.png')
          this.isShowDownloadColor = true
        }
        if (document.documentElement.scrollTop>1186&&document.documentElement.scrollTop<=1840) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
          this.isShowDownloadColor = false
        }
        if (document.documentElement.scrollTop>1840&&document.documentElement.scrollTop<=2300) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_black.png')
          this.isShowDownloadColor = true
        }
        if (document.documentElement.scrollTop>2300&&document.documentElement.scrollTop<=3000) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
          this.isShowDownloadColor = false
        }
        if (document.documentElement.scrollTop>3000&&document.documentElement.scrollTop<=3322) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_black.png')
          this.isShowDownloadColor = true
        }
        if (document.documentElement.scrollTop>3322&&document.documentElement.scrollTop<=4068) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
          this.isShowDownloadColor = false
        }
        if (document.documentElement.scrollTop>4068&&document.documentElement.scrollTop<=4690) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_black.png')
          this.isShowDownloadColor = true
        }
        if (document.documentElement.scrollTop>4690&&document.documentElement.scrollTop<=5000) {
          this.telegram_icon = require('../../assets/img/home/home_telegram_white.png')
          this.isShowDownloadColor = false
        }
      }
    },
    saveTip(){
      var that = this;
      var userAgent = navigator.userAgent;
      // alert(userAgent)
      if ((userAgent.indexOf('iPhone') > -1) && userAgent.indexOf('Safari') > -1){
        if (this.isPrivateMode()) {
          this.showPrivate();
        }
      } else if(userAgent.indexOf('UCBrowser') > -1 || userAgent.indexOf('Quark') > -1){
        this.storageTest()
      }else{
        if (userAgent.indexOf('UCBrowser') > -1) return
        let fs = window.RequestFileSystem || window.webkitRequestFileSystem;
        // this.$message.warning(window.webkitRequestFileSystem);
        if (!fs) {
          console.log("check failed?");
        } else {
          fs(window.TEMPORARY,100,function(){
            // that.$message.warning('asdasdasdasdasdasdasd')
            console.log('非无痕模式')
          },function(){
            // that.$message.warning('asdasdasdasdasdasdasd')
            this.showPrivate();
          });
        }
      }
    },
    isPrivateMode() {
      var isPrivate = false;
      try {
        window.openDatabase(null, null, null, null);
      } catch (_) {
        isPrivate = true;
      }
      return isPrivate;
    },
    storageTest() {
      var that = this
      var test = (storage) => {
        if (!!storage) {
          try {
            storage.setItem('storageTest', 'value')
            if (storage.getItem('storageTest') !== 'value') {
              storage.removeItem('storageTest');
              // throw '您的浏览器处于无痕模式,请退出无痕模式后再重新打开本页面'
              // that.$message.warning('您的浏览器处于无痕模式,请退出无痕模式后再重新打开本页面')
              that.showPrivate()
            }
            storage.removeItem('storageTest');
            return { valid: true }
          } catch (e) {
            // that.$message.warning(e)
            console.log(e)
            return { valid: false, msg: e }
          }
        } else {
          // this.$message.warning('您的浏览器不支持本地存储')
          // console.log('您的浏览器不支持本地存储')
          return { valid: false, msg: '您的浏览器不支持本地存储' };
        }
      }
      return {
        localStorage: test(window.localStorage),
        sessionStorage: test(window.sessionStorage)
      }
    },
    showPrivate(){
      this.$confirm('您开启了“无痕浏览”模式，这将影响您的正常使用，请先切换为普通浏览模式！', '警告', {
        confirmButtonText: '确定',
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showClose: false,
        type: 'warning'
      }).then(() => {}).catch(() => {});
    },
    gotoDownLoad(){
      document.querySelector('#download_app').scrollIntoView({
        behavior: "smooth",
        // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
        block: "center",
        // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
        inline: "nearest"
        // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
      })
    },
    getNoticeMsgList(){
      getNoticeMsgList().then(res =>{
        if(!res) return
        if(res.success){
          this.noticeLise = res.data.list
          for (var i = 0; i < this.noticeLise.length; i++) {
            this.noticeLise[i].time = this.noticeLise[i].send_time.split(' ')[0]
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    gotoProfit(num){
      if(num == 1){
        window.open('https://uplus724.com/#/pages/index/allRank_pc')
      } else {
        window.open('https://uplus724.com/#/pages/index/allRank')
      }
    },

    getFeedbackCount(requestTimes){
      getFeedbackCount(requestTimes).then(res=>{
        if (res){
          if (res.success){
            this.msgCount = res.data
          }
        }
      })
    },

    openMessageBack(data){ //pc端传参
      this.getMessageInfo();
      this.showMessage = data;
      this.timer = setTimeout(() => {
        this.scrollListener()
      }, 200)
    },

    gotoMessage(){
      if (this.$util._isMobile()){
        this.$router.push('/messageBack')
      } else {
        this.messageList = []
        this.firstTime = ''
        this.lastTime = ''
        this.getMessageInfo();
        if(new Date().getHours()>18 || new Date().getHours()<9){
          this.showTimeNoOnline = true
        }
        this.showMessage = true;
        this.timer = setTimeout(() => {
          this.scrollListener()
          // this.scrollBottom()
        }, 200)
      }
      this.msgCount = 0;
    },

    handleClose(){
      this.$store.state.showFeedBack = false
      this.showMessage = false
      this.box.removeEventListener("scroll", this.addListener, false)
    },
    totalinput(value) {
      if (value.length > 500) {
        this.feedbackContent = value.substr(0, 500)
        this.$message.warning('最多只能輸入500個字符')
      }
      this.thisNum = this.feedbackContent.length
    },

    scrollListener(){
      this.box = this.$refs.viewBox
      this.box.addEventListener('scroll', this.addListener, false)
    },

    addListener(){
      if (this.$refs.viewBox.scrollTop == 0){
        // this.pageNum++
        if(this.messageList.length > 0){
          this.firstTime = this.messageList[0].time
          this.lastTime = ''
        } else {
          this.firstTime = ''
        }
        this.getMessageInfo(10)
      }
    },

    async submit(content) {
      if (this.token){
        this.uid = ''
      }
      if (!this.selectValue) {
        this.$message.warning('请选择问题类型')
        return
      }
      if (!this.feedbackContent) {
        this.$message.warning('请输入问题描述')
        return
      }
      if(this.$util.filterText(this.feedbackContent)){
        this.$message.warning('包含敏感词汇，请重新编辑！')
        return
      }
      const res = await feedbackRequest(this.selectValue, this.feedbackContent, this.imageUrl, this.uid, 10)
      if (res.success) {
        // if (!content){
        //   let info = {}
        //   info.content = this.feedbackContent.replaceAll('\n', '<br/>')
        //   info.create_time = this.$util.formatDateTime(this.$util.formatDate_dd(new Date().getTime(),'/'))
        //   info.create_this_time = new Date().getTime()
        //   this.messageList.push(info)
        // }
        if(this.messageList.length > 0){
          this.lastTime = this.messageList[this.messageList.length - 1].time
          this.firstTime = ''
        } else {
          this.lastTime = ''
        }
        await this.getMessageInfo(10, 'Refresh', 'addMessage')
        // this.selectValue='';
        this.feedbackContent='';
        this.imageUrl='';
        // this.scrollBottom();
        // await this.getMessageInfo(10);
      } else {
        this.$message.error(res.message);
      }
    },
    scrollBottom(){
      const that = this
      that.timer = setInterval(function () {
        // console.log(document.readyState)
        if (document.readyState === 'complete') {
          let div = document.getElementById('message-content');
          div.scrollTop = div.scrollHeight; // 将聊天框滚轮拉到最底部
          this.msgHeight = div.scrollHeight
          window.clearInterval(that.timer)
        }
      }, 800)
    },
    scrollCenter(){
      const that = this
      that.timer = setInterval(function () {
        // console.log(document.readyState)
        if (document.readyState === 'complete') {
          let div = document.getElementById('message-content');
          div.scrollTop = div.scrollHeight-this.msgHeight; // 将聊天框滚轮拉到最底部
          this.msgHeight = div.scrollHeight
          window.clearInterval(that.timer)
        }
      }, 500)
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data;
      this.feedbackContent = 'pic_info'
      var info = {}
      info.picture = this.imageUrl
      info.picture_small = this.imageUrl+'/small'
      info.content = this.feedbackContent
      info.create_time = this.$util.formatDateTime(this.$util.formatDate_dd(new Date().getTime(),'/'))
      info.create_this_time = new Date().getTime()
      // this.messageList.push(info)
      this.submit('pic_info')
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt12M = file.size / 1024 / 1024 < 12;

      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt12M) {
        this.$message.error('图片大小不能超过 12MB!');
      }
      return (isPNG || isJPG) && isLt12M;
    },
    handlePictureCardPreview(file, fileList) {
      // console.log(12)
    },
    async getMessageInfo(requestTimes, Refresh, addMessage){
      if (!Refresh && (this.pageNum > this.totalPage)) {
        return
      }
      const res = await getFeedbackMsg(requestTimes, this.pageNum, this.pageSize, this.uid, this.lastTime, this.firstTime)
      if (res) {
        if (res.success) {
          if(!Refresh && !this.firstTime && !this.lastTime){
            this.totalPage = res.data.totalPage>0?res.data.totalPage:1
          }
          this.messageList_linshi = res.data.list
          for (let i = 0; i < this.messageList_linshi.length; i++) {
            this.messageList_linshi[i].content = this.messageList_linshi[i].content.replace('\n', '<br/>')
            this.messageList_linshi[i].create_this_time = this.$util.formatUnix(this.messageList_linshi[i].time)
            // this.messageList_linshi[i].update_this_time = this.$util.formatUnix(this.messageList_linshi[i].update_time)
            this.messageList_linshi[i].create_time = this.$util.formatDateTime(this.messageList_linshi[i].time)
            if (this.messageList_linshi[i].time) {
              this.messageList_linshi[i].update_time = this.$util.formatDateTime(this.messageList_linshi[i].time)
            }
            if (this.messageList_linshi[i].picture) {
              this.messageList_linshi[i].picture_small = this.messageList_linshi[i].picture + '/small'
            }

            if (this.messageList_linshi[i].picture && this.messageList_linshi[i].type == 'service'){
              this.messageList_linshi[i].replyPictureList = this.messageList_linshi[i].picture.split(',')
            }
          }
          // this.messageList = this.messageList_linshi.concat(this.messageList)
          if(Refresh || (addMessage && addMessage == 'addMessage')){
            this.messageList = this.messageList.concat(this.messageList_linshi)
          } else {
            this.messageList = this.messageList_linshi.concat(this.messageList)
          }

          if(Refresh || (!this.firstTime && !this.lastTime)){
            if(this.messageList.length > 0){
              this.lastTime = this.messageList[this.messageList.length - 1].time
            }
            this.scrollBottom()
          }
          // if (this.pageNum == 1){
          //   this.scrollBottom()
          // } else{
          //   this.scrollCenter()
          // }

        } else {
          this.$message.warning(res.message)
        }
      }
    },
    closeDownloadBar(){
      var closed = setTimeout(()=>{
        if (this.isMobile){
          document.getElementById('downloadBar_mb').style.display = 'none'
        } else {
          document.getElementById('downloadBar_pc').style.display = 'none'
        }
      },5000)
      this.isClosed = false
      sessionStorage.setItem('isClosed', JSON.stringify(this.isClosed))
    },
    downloadDialog(){
      this.showDownloadImage = true
    },

    hideDownloadDialog() {
      var hideDld = setTimeout(()=>{
        this.showDownloadImage = false
        clearTimeout(hideDld)
      }, 3000)
    },

    livingView(hideLoad){
      getActivityById_Other(17, hideLoad).then(res=>{
        if(!res) return
        if(res.success){
          if (res.data){
            this.liveActivityInfo = res.data
            this.isShowLiveView = true
          } else {
            this.isShowLiveView = false
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    gotoLiveView(){
      var url= this.liveActivityInfo.url
      var a = document.createElement('a');
      a.target='_blank'
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  },
  destroyed(){
    window.removeEventListener('scroll', this.changeNav,true)
  },
}
</script>

<style scoped>
/*底部*/
@media screen and (max-width: 1079px) {
  .lg-footer{
    display: none;
  }
}
@media screen and (min-width: 1079px){
  .sm-footer{
    display: none;
  }
}


.home-top{
  background-image: linear-gradient(to right ,#0F141C ,#171953);
  /*background: linear-gradient(270deg, #08094B 0%, #171953 100%);*/

  /*padding: 0 30px;*/
}
.nav-container,.content-container{
  max-width: 1200px;
  margin: 0 auto;
}
.content-container,.advice-container{
  /*padding: 0 30px;*/
  max-width: 1200px;
  margin: 0 auto;
}
.advice-container-external{
  background: rgba(243, 247, 255, 0.3);
}
.invite-num {
  color: transparent;
  background-image: linear-gradient(90deg, #BE7EF1 0%,#B77CF2 23.96%, #5B60F9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 36px;
  margin: 0 5px;
}
@media screen and (min-width: 900px) {
  .home-top {
    /*background: url("../../assets/img/new_home/home_bg.svg");*/
    background: #393948;
    /*height: 780px;*/
    background-size: cover;
  }
}
@media screen and (max-width: 900px){
  .content-container,.advice-container{
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .lg{
    display: none;
  }
}
@media screen and (min-width: 900px){
  .sm{
    display: none;
  }

  .quantitative{
    position: relative;
    /*padding: 78px 0 89px 0;*/
    height: 419px;
  }

  .quantitative .quantitative-title{
    /*position: absolute;*/
    font-size: 43px;
    font-weight: 400;
    color: #FFFFFF;
    top: 60px;
  }
  .quantitative .quantitative-sub-title{
    /*position: absolute;*/
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #FFFFFF;
    /*margin: 32px 0 98px 0;*/
    margin: 23px 0 102px 0;
    /*top: 100px;*/
  }

  .quantitative .quantitative-btn{
    width: 220px;
    height: 48px;
    background: #AB54DB;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 115px;
  }
}
/**
导航栏
 */
.nav{
  height: 64px;
}
.nav>.nav-left{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav>.nav-left>img{
  width: 90px;
  height: 24px;
}
.nav>.nav-left>div{
  display: inline-block;
}
.nav>.nav-left>div>span{
  font-size: 16px;
  font-weight: 400;
  color: #B7B8BB;
  margin-left: 56px;
  height: 64px;
  line-height: 64px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}
.nav>.nav-left>div>span:hover{
  color: #AB54DB;
  border-bottom: #AB54DB solid 4px;
}

.nav{
  display: flex;
  justify-content: space-between;
}
.nav .nav-right{
  font-size: 16px;
  font-weight: 400;
  color: #B7B8BB;
  display: flex;
  align-items: center;
}
.nav .nav-right>span{
  margin-right: 40px;
}
/*         quantitative              */
.quantitative{
  position: relative;
  /*padding: 78px 0 89px 0;*/
}
.quantitative .quantitative-title{
  font-size: 60px;
  font-weight: 400;
  color: #FFFFFF;
}
.quantitative .quantitative-sub-title{
  font-size: 23px;
  line-height: 40px;
  font-weight: 400;
  color: #FFFFFF;
  /*margin: 32px 0 98px 0;*/
  margin: 29px 0 102px 0;
}
.quantitative img{
  position: absolute;
  right: 0;
  bottom: 70px;
}
.quantitative .quantitative-btn{
  width: 220px;
  height: 48px;
  background: #AB54DB;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.nav .sm{
  display: none;
}
/*鼠标悬浮处理*/
.quantitative .quantitative-btn:hover{
  background: #AB54DB;
  opacity: 0.9;
}
.nav-right>span:hover{
  opacity: 0.9;
  cursor: pointer;
}
.nav-right>img:hover{
  opacity: 0.9;
  cursor: pointer;
}

@media screen and (max-width: 1054px){
  .quantitative img{
    width: 300px;
  }
}
@media screen and (max-width: 900px){
  .home-top{
    /*padding: 0 20px;*/
  }
  .nav-left>div{
    display: none !important;
  }
  .sm{
    display: block !important;
  }
  .lg{
    display: none;
  }
  .nav-right>span:nth-child(1){
    width: 58px;
    height: 28px;
    border-radius: 2px;
    border: 1px solid #AB54DB;
    font-size: 14px;
    color: #AB54DB;
    text-align: center;
    line-height: 28px;
    margin-right: 24px;
  }
  .nav-right>span:nth-child(2){
    display: none !important;
  }
  .quantitative .quantitative-sub-title{
    width: 60%;
    opacity: 0.8;
    /*font-family: "PingFang SC";*/
    /*font-weight: lighter;*/
  }
  .quantitative img{
    width: 40%;
    bottom: 70px;
  }
  .quantitative{
    height: auto;
    padding: 38px 0 40px 0;
  }
  .quantitative .quantitative-title{
    font-size: 24px;
    font-weight: 500;
    /*font-family: "PingFang SC";*/
  }
}
@media screen and (max-width: 600px){
  .quantitative{
    min-height: 320px;
  }

  .quantitative-title{
    min-height: 64px;
    font-size: 24px;
  }

  .quantitative .quantitative-sub-title{
    min-height: 132px;
    font-size: 16px;
    line-height: 24px;
  }

  .quantitative .quantitative-sub-title>div:nth-child(1){
    width: 160%;
  }
  .quantitative .quantitative-sub-title>div:nth-child(2){
    width: 90%;
    margin-top: 12px;
  }
  .quantitative img{
    width: 50%;
    top: 180px;
    /*bottom: 170px;*/
  }
}
@media screen and (max-width: 435px){
  .quantitative .quantitative-sub-title{
    margin: 26px 0 50px 0;
  }
  .quantitative .quantitative-btn{
    width: 100%;
  }
}

.message-bax div >>>.el-drawer.ttb, >>>.el-drawer.btt {
  width: 400px;
  height: 70% !important;
  position: absolute;
  left: unset;
  right: 2%;
  bottom: 2% !important;
  border-radius: 10px 10px 0 0;
}

.message-bax-top{
  height: 84.5% !important;
}
.message-bax-bottom{
  height: 15% !important;
}

.message_icon {
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 888;
  background: #AB54DB;
  width: 44px;
  height: 44px;
  box-shadow: 0px 2px 4px 0px rgba(44, 117, 255, 0.2), 0px 4px 8px 0px rgba(44, 117, 255, 0.1);
  /*opacity: 0.7;*/
}
@media screen and (max-width: 2048px){
  .message-bax div >>>.el-drawer.ttb, >>>.el-drawer.btt {
    width: 400px;
    height: 70% !important;
    position: absolute;
    left: unset;
    right: 2%;
    bottom: 2% !important;
  }
  .message-bax-top{
    height: 80% !important;
  }
  .message-bax-bottom{
    height: 19% !important;
  }
}

@media screen and (max-width: 1920px){
  .message-bax div >>>.el-drawer.ttb, >>>.el-drawer.btt {
    width: 400px;
    height: 700px !important;
    position: absolute;
    left: unset;
    right: 2%;
    bottom: 2% !important;
  }
  .message-bax-top{
    height: 80% !important;
  }
  .message-bax-bottom{
    height: 19% !important;
  }
}

@media screen and (max-width: 900px) {
  .message-bax div >>> .el-drawer.ttb, >>> .el-drawer.btt {
    width: 400px;
    height: 700px !important;
    position: absolute;
    left: unset;
    right: 2%;
    bottom: 2% !important;
  }

  .message-bax-top {
    height: 80% !important;
  }

  .message-bax-bottom {
    height: 19% !important;
  }
}
.message-bax >>>.el-drawer__body{
  overflow: hidden;
}

.message-bax >>>.el-drawer__wrapper {
  position: absolute;
  right: 5%;
  bottom: 10%;
  overflow: hidden;
  margin: 0;
}

.message-bax >>>.el-radio__input {
  display: none;
  border-radius: 36px;
}

.message-bax .is-checked {
  color: white;
  border: none;
  background-color: #AB54DB;
}

.message-bax .el-radio--medium.is-bordered {
  padding: 0px;
  border-radius: 36px;
  height: 23px;
  /*margin: 0px auto;*/
  margin-left: 10px;
  margin-right: 0;
  vertical-align: middle;
  width: 12%;
  text-align: center;
}

.message-bax >>>.el-radio__label {
  font-size: 14px;
  vertical-align: middle;
  line-height: 23px;
  padding-left: 0px;
}

.message-bax >>>.el-radio__input.is-checked + .el-radio__label {
  color: white;
}

.message-bax .avatar-uploader {
  /*margin: 8px 20px;*/
  margin: 8px 15px 8px 20px;
  width: 28px;
  height: 28px;
  /*border: 1px #EAECEF solid;*/
  border-radius: 1px;
}

.message-bax .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.message-bax .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.message-bax .avatar {
  width: 50px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: block;
}

.message-bax >>>.el-textarea__inner{
  border: unset;
  resize: none;
  padding: 5px 15px 5px 0 !important;
}

.message-bax .submit-btn {
  position: absolute;
  display: inline-block;
  background-color: #AB54DB;
  right: 20px;
  top: 0;
  height: 40px;
  bottom: 0;
  margin: auto 0;
  vertical-align: middle;
}

.telegram_bg_dark {
  background: rgba(100, 100, 100, 0.09);
  border-radius: 16px;
}

.telegram_bth_bg {
  display: flex;
  background: #BF5FC1;
  border-radius: 8px;
  width: 141px;
  height: 28px;
  text-align: center;
  border: unset;
  color: #fefefe;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}

.shwo_download_bg {
  background-image: url("../../assets/img/home/show_download_bg.png");
  width: 190px;
  height: 166px;
  position: absolute;
  margin-top: 20px;
  right: -10px;
  background-size: cover;
  background-repeat: round;
  text-align: center;
  filter: ;
}

.shwo_download_grey_bg {
  background-image: url("../../assets/img/home/show_download_grey_bg.png");
  width: 190px;
  height: 166px;
  position: absolute;
  margin-top: 20px;
  right: -10px;
  background-size: cover;
  background-repeat: round;
  text-align: center;
  filter: ;
}
.scrolling-text {
  overflow: hidden;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform:  translateX(-100%);
  }
}
.guanggao-t-1 {
  background: linear-gradient(120deg, #87E2CE 10%, #4A8CF6 40%, #8C7BFA 100%); /* 从左到右渐变 */
  -webkit-background-clip: text; /* Safari/Chrome支持背景裁切文本内容 */
  -webkit-text-fill-color: transparent; /* Safari/Chrome透明化文字颜色 */
  font-size: 18px;
}
.guanggao-t-2 {
  background: linear-gradient(120deg, #CF70E0 10%, #F97ABF 35%, #F192A5 50%, #FDBF95 100%); /* 从左到右渐变 */
  -webkit-background-clip: text; /* Safari/Chrome支持背景裁切文本内容 */
  -webkit-text-fill-color: transparent; /* Safari/Chrome透明化文字颜色 */
  font-size: 18px;
}
.guanggao-t-3 {
  background: linear-gradient(120deg, #9CA8FF 10%, #FBA3F8 100%); /* 从左到右渐变 */
  -webkit-background-clip: text; /* Safari/Chrome支持背景裁切文本内容 */
  -webkit-text-fill-color: transparent; /* Safari/Chrome透明化文字颜色 */
  font-size: 18px;
}

</style>
