<template>
  <div  style="">
    <div v-if="!isMobile" style="width: 1200px; height: 748px; margin: 0 auto;display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <div style="text-align: center">
        <span style="display:block;font-size: 48px;font-weight: 500;color: #FFFFFF;">让财富近在咫尺</span>
        <span style="display:block;font-size: 24px;color: #FFFFFF; opacity: 0.6;margin-top: 20px">您现在需要的一切都在 UPlus 应用程序中。</span>
      </div>
      <div style="display: flex; justify-content: center; align-items: center;margin-top: 50px">
        <img style="width: 724px; height: 423px;" src="../../assets/img/home/home_down_bg.png">
        <div style="margin-left: 100px; text-align: center; display: flex; flex-direction: column">
          <div style="display:flex; justify-content: space-around">
            <div>
              <img style="display:block;width: 158px;height: 158px;margin: 0px auto 0" src="../../assets/img/home/download/iOS_miaoshu.png">
              <span style="font-size: 14px; color: #FEFEFE; display: block; margin-top: 10px">iOS 下载</span>
            </div>
            <div style="margin-left: 50px">
              <img style="display:block;width: 158px;height: 158px;margin: 0px auto 0" src="../../assets/img/home/download/new_platform.png">
              <span style="font-size: 14px; color: #FEFEFE; display: block; margin-top: 10px">Android 下载</span>
            </div>
          </div>
          <div style="display: block; margin-top: 20px">
<!--            <div style="display: inline-block;cursor: pointer" @click="downloadIos">-->
<!--              <img style="display:inline-block;width: 40px;height: 40px;vertical-align: middle" src="../../assets/img/home/home_down_ios.png">-->
<!--              <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">下载iOS</span>-->
<!--            </div>-->
<!--            <div style="display: inline-block;margin-left: 70px;cursor: pointer" @click="downloadAndroid">-->
<!--              <img style="display:inline-block;width: 40px;height: 40px;vertical-align: middle" src="../../assets/img/home/home_down_android.png">-->
<!--              <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">下载Android</span>-->
<!--            </div>-->
<!--            <p style="margin-top: 10px; color: #FF4D4F; font-size: 14px; width: 100%; text-align: left">注：苹果手机用户需要翻墙使用！</p>-->
            <div style="display: inline-block;cursor: pointer; border: 1px #fefefe solid; border-radius: 6px; padding: 5px 12px; opacity: 0.8;margin-top: 12px" @click="otherDownlaod">
              <img style="display:inline-block;width: 16px;height: 16px;vertical-align: middle" src="../../assets/img/home/download_icon.png">
              <span style="display:inline-block;font-weight: 500;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.9);vertical-align: middle; margin-left: 8px; letter-spacing: 0.05em;">更多下载方式</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile" style="height: 600px; width: 100%;display: flex; flex-direction: column; justify-content: center">
      <div style="text-align: center">
        <span style="display:block;font-size: 24px;font-weight: 500;color: #FFFFFF;">让财富近在咫尺</span>
        <span style="display:block;font-size: 16px;color: #FFFFFF; opacity: 0.6;margin-top: 10px">您现在需要的一切都在 UPlus 应用程序中。</span>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-top: 58px">
        <img style="width: 236px; height: 140px;" src="../../assets/img/home/home_down_bg.png">
        <div style="display: flex; justify-content: space-between; width: 80%; margin-top: 40px">
<!--          <div>-->
<!--            <div style="cursor: pointer" @click="downloadIos">-->
<!--              <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/home/home_down_ios_mb.png">-->
<!--              <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">直接下载</span>-->
<!--            </div>-->
<!--            <div style="cursor: pointer; margin-top: 10px" @click="downloadPlatform">-->
<!--              <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/home/home_down_ios_mb.png">-->
<!--              <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">iOS 下载</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div style="cursor: pointer" @click="downloadAndroid">-->
<!--              <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/home/home_down_android_mb.png">-->
<!--              <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">直接下载</span>-->
<!--            </div>-->
<!--            <div style="cursor: pointer; margin-top: 10px" @click="downloadPlatform">-->
<!--              <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/home/home_down_android_mb.png">-->
<!--              <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: rgba(255, 255, 255, 0.6);vertical-align: middle; margin-left: 12px">Android 下载</span>-->
<!--            </div>-->
<!--          </div>-->
          <div style="">
            <div style="cursor: pointer;" @click="downloadsiOS(1)">
              <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/home/home_down_ios_mb.png">
              <span style="display: inline-block; font-size: 14px; color: #FFFFFF; margin-left: 10px">iOS H5版<span style="color: #FFBB54"> (推荐)</span></span>
            </div>
            <div style="margin-top: 16px">
              <div style="cursor: pointer;" @click="downloadsiOS(3)">
                <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/home/home_down_ios_mb.png">
                <span style="display: inline-block; font-size: 14px; color: #FFFFFF; margin-left: 10px">iOS 分发下载</span>
              </div>
              <span style="color: #FFBB54;font-size: 12px; margin-left: 20px">(此包无法自动更新)</span>
            </div>
          </div>
          <div style="">
            <div style="cursor: pointer;" @click="downloadsiOS(2)">
              <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/home/home_down_ios_mb.png">
              <span style="display: inline-block; font-size: 14px; color: #FFFFFF; margin-left: 10px">iOS beta版<span style="color: #FFBB54"> (推荐)</span></span>
            </div>
            <div style="cursor: pointer; margin-top: 16px" @click="downloadAndroidPlatform">
              <img style="display:inline-block;width: 30px;height: 30px;vertical-align: middle" src="../../assets/img/home/home_down_android_mb.png">
              <span style="display: inline-block; font-size: 14px; color: #FFFFFF; margin-left: 10px">Android 下载</span>
            </div>
          </div>
        </div>
<!--        <p style="margin-top: 10px; color: #FF4D4F; font-size: 14px; width: 80%;">注：苹果手机用户需要翻墙使用！</p>-->
<!--        <div style="width: 80%; margin: 20px auto 0">-->
<!--          <span style="color: #FFFFFF; font-size: 14px">无法下载？请复制链接到手机浏览器进行下载！</span>-->
<!--          <div style="display: inline-block; cursor: pointer; color: rgba(255, 255, 255, 0.6); font-size: 14px; margin-top: 10px; width: 100%;">-->
<!--            <span style="display: inline-block; vertical-align: middle">苹果下载链接：</span>-->
<!--            <span style="display: inline-block; width: 25%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; vertical-align: middle">https://uplus100-oss.oss-cn-beijing.aliyuncs.com/file/download/uplus-app.mobileconfig</span>-->
<!--            <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: #AB54DB; vertical-align: middle; margin-left: 12px" @click="copy('https://uplus100-oss.oss-cn-beijing.aliyuncs.com/file/download/uplus-app.mobileconfig')">复制链接</span>-->
<!--          </div>-->
<!--          <div style="display: inline-block; cursor: pointer; color: rgba(255, 255, 255, 0.6); font-size: 14px; margin-top: 3px; width: 100%;">-->
<!--            <span style="display:inline-block;">安卓下载链接：</span>-->
<!--            <span style="display: inline-block; width: 28%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; vertical-align: middle">https://uplus100-oss.oss-cn-beijing.aliyuncs.com/file/download/uplus-app.apk</span>-->
<!--            <span style="display:inline-block;font-weight: 700;font-size: 14px;text-align: center;color: #AB54DB;vertical-align: middle; margin-left: 12px" @click="copy('https://uplus100-oss.oss-cn-beijing.aliyuncs.com/file/download/uplus-app.apk')">复制链接</span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import download from '../../utils/registered_address'
export default {
  name: "home_download",
  data(){
    return{
      isMobile: false
    }
  },

  created() {
    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },

  methods: {
    otherDownlaod(){
      this.$router.push('download')
    },
    downloadAndroid(){
      const link = document.createElement("a"); //自己创建的a标签
      link.href = download.download_android_address();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },
    downloadIos(){
      const link = document.createElement("a"); //自己创建的a标签
      // link.href = 'https://uplus100-oss.oss-cn-beijing.aliyuncs.com/file/download/uplus-app.mobileconfig';
      link.href = download.download_ios_address();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },
    downloadsiOS(num){
      var url = download.download_ios_h5_address()
      if (num == 1) {
        url = download.download_ios_h5_address()
      } else if(num == 2) {
        url = download.download_ios_beta_address()
      } else {
        url = download.download_ios_p_address()
      }
      const link = document.createElement("a"); //自己创建的a标签
      link.href = url;
      link.target='_blank'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadAndroidPlatform(){
      const link = document.createElement("a"); //自己创建的a标签
      link.href = download.download_android_address();
      link.target='_blank'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },
    downloadPlatform(){
      const link = document.createElement("a"); //自己创建的a标签
      link.href = download.download_android_address();
      link.target='_blank'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },

    copy(value){
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', value);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    }
  }
}
</script>

<style scoped>

</style>
