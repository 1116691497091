<template>
  <div class="container" style="z-index: 2">
    <div class="top">
      <div class="nav">
        <div class="nav-left">
          <img src="../../assets/img/head_logo.png" alt="" id="navIsShowImg">
          <div class="tabs"  ref="navIsShow">
            <span id="home" @click="changeScroll(60,'home')">{{ $t('navbar.home') }}</span>
            <span id="product" @click="changeScroll(600,'product')">{{ $t('navbar.products') }}</span>
            <span id="suggest" @click="changeScroll(1200,'suggest')">{{ $t('navbar.suggest') }}</span>
            <span id="process" @click="changeScroll(1800,'process')">{{ $t('navbar.process') }}</span>
            <span id="service" @click="changeScroll(2300,'service')">全网收益</span>
            <span id="partner" @click="changeScroll(4060,'partner')">合作伙伴</span>

          </div>
        </div>
        <div class="nav-right">
          <img v-if="isClosed == false" class="download-app" :class="isClosed==false?'download_img_show':'download-app'" id="download_img" src="../../assets/img/download.svg" style="cursor:pointer;" @click="download_app()" alt="">
          <span v-if="isLogin" class="sign_in" @click="$router.push('/login')">{{ $t('navbar.sign_in') }}</span>
          <span v-if="isLogin" class="sign_up"  @click="$router.push('/register')">{{ $t('navbar.sign_up') }}</span>
            <div v-else>
            <span @click="$router.push('/center')" class="login_btn" style="display: flex;align-items: center;">
<!--                <div class="lg" style="rgb(169, 169, 173);font-size: 14px;margin-right: 10px;cursor: pointer">控制中心</div>-->
                <img v-if="userInfo.user_face=='' || userInfo.user_face==null" style="width: 30px;height: 30px;border-radius: 50%" class="img-lg" src="../../assets/img/avatar.png" alt="" >
                <img v-else style="width: 30px;height: 30px;border-radius: 50%" class="img-lg" :src="userInfo.user_face" alt="" >
                <span v-if="isMobile" style="display: inline-block;font-size: 14px;margin: 0 0 0 8px;cursor: pointer;color: #A9A9AD;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 60px;">{{ username }}</span>
                <span v-if="!isMobile" style="display: inline-block;font-size: 14px;margin: 0 0 0 8px;cursor: pointer;color: #A9A9AD;">{{ username }}</span>
<!--                <img v-if="userInfo.charge_style2==3" src="../../assets/img/ssvip.svg" alt="">-->
<!--                <img v-else-if="userInfo.charge_style2==2" src="../../assets/img/SVIP.svg" alt="">-->
<!--                <img v-else src="../../assets/img/VIP.svg" alt="">-->
              </span>
            </div>
          <el-dropdown class="lg" placement="bottom-start" trigger="click" style="margin-left: 30px;display: flex; align-items: center;" @command="selectLang">
            <span class="el-dropdown-link" style="display:inline-flex;align-items: center; ">
              <img style="width: 23px;height: 23px;border-radius: 50%;margin-right: 5px" src="../../assets/img/home/change_language_icon.png" alt="" >
            </span>
            <el-dropdown-menu slot="dropdown" class="menuImg">
              <el-dropdown-item :class="{language_selected: selectLanguage == '简体中文'}" command="简体中文">简体中文</el-dropdown-item>
              <el-dropdown-item :class="{language_selected: selectLanguage == '繁体中文'}" command="繁体中文">繁体中文</el-dropdown-item>
              <el-dropdown-item :class="{language_selected: selectLanguage == 'English'}" command="English">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

<!--          <img class="lg" style="width: 24px;height: 24px;" src="../../assets/img/center/found.png" alt="">-->
<!--          <img class="sm" style="width: 24px;height: 24px;" src="../../assets/img/center/navigation.svg" alt="">-->
        </div>
      </div>
    </div>
<!--    <el-dialog-->
<!--        :visible.sync="showcode"-->
<!--        title=""-->
<!--        :center="true"-->
<!--        :close-on-click-modal="true"-->
<!--        width="315px"-->
<!--        :show-close="true"-->
<!--        append-to-body="true"-->
<!--        class="dialog_down">-->
<!--      <div v-if="!this.$util._isMobile()" style="text-align: center; border-radius: 20px">-->
<!--        <img style="width: 200px; height: 200px; margin: 0 auto; display: block" src="../../assets/img/download_code_1.png" alt="pointer">-->
<!--        <span style="font-size: 20px;margin-top: 30px;display: block">扫描二维码下载</span>-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import {getMemberRemainNum} from "../../server/member";
import {boolean} from "mathjs";

export default {
  name: "Navbar",
  data(){
    return {
      isLogin:false,
      username:'',
      isVip:true,
      userInfo:{
        id:''
      },
      showcode: false,
      selectLanguage: '',
      isClosed: false,
      isMobile: false,
      // memberInfo:{
      //   duration_type:1
      // }
    }
  },

  props:{
    isclosed: {type:Boolean, required: true}
  },

  watch:{
    isclosed(val){
      this.isClosed = val
    }
  },

  created() {
    const userInfo=window.localStorage.getItem('userInfo')
    if (userInfo) {
      const {name,mobile,charge_style2,username,id} = JSON.parse(userInfo)
      this.userInfo=JSON.parse(userInfo)
      name===null||name===''? this.username=mobile:this.username=name.length>6 ?name.slice(0,6)+'...' :name
      if ((mobile===null||mobile==='')&&(name===null||name==='')) this.username=username
      charge_style2===2 ?this.isVip=false :this.isVip=true
    }
    !window.localStorage.getItem('token')? this.isLogin=true:this.isLogin=false
    this.selectLanguage = localStorage.getItem('language')

    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },
  mounted() {
    this.$refs.navIsShow.style.display = 'block'
    this.isClosed = sessionStorage.getItem('isClosed')
    this.changeColor('home')
      window.addEventListener('scroll',this.changeNav,true)
        // this.getMemberRemain()
  },
  methods:{
    //會員有效期的接口
    // async getMemberRemain(){
    //   if (localStorage.getItem('token')==null) return
    //   const memberInfo= await getMemberRemainNum()
    //   if (memberInfo.data==null) return
    //   this.memberInfo=memberInfo.data
    // },
    selectLang(e){
      // this.selectLanguage=e
      this.selectLanguage=e
      localStorage.setItem('language', e)
      e==='繁体中文' ? this.$i18n.locale='zh-tw' : e==='简体中文' ? this.$i18n.locale='zh' : this.$i18n.locale='en'
      location.reload()
    },
    changeNav(){
      // if (document.documentElement.scrollTop>0) {
      //   this.$refs.navIsShow.style.display = 'block'
      //   // this.$refs.navIsShowImg.style.display = 'block'
      // }
      // if (document.documentElement.scrollTop==0) {
      //   this.$refs.navIsShow.style.display = 'none'
      //   // this.$refs.navIsShowImg.style.display = 'none'
      // }
      if (document.documentElement.scrollTop<200) this.changeColor('home')
      if (document.documentElement.scrollTop>300&&document.documentElement.scrollTop<700)  this.changeColor('product')
      if (document.documentElement.scrollTop>700&&document.documentElement.scrollTop<1000) this.changeColor('suggest')
      if (document.documentElement.scrollTop>1500&&document.documentElement.scrollTop<2000) this.changeColor('process')
      if (document.documentElement.scrollTop>2200&&document.documentElement.scrollTop<2600) this.changeColor('service')
      if (document.documentElement.scrollTop>4060&&document.documentElement.scrollTop<4300) this.changeColor('partner')
    },
    changeScroll(value,e){
      document.documentElement.scrollTop=value
        const tab=document.querySelectorAll('.tabs span')
        Object.keys(tab).map(item=>{
          tab[item].style.color='#B7B8BB'
          tab[item].style.borderBottom="none"
        })
      // border-bottom: #AB54DB solid 4px;
      document.getElementById(e).style.color='#AB54DB'
      document.getElementById(e).style.borderBottom="#AB54DB solid 4px"
    },
    changeColor(e){
      const tab=document.querySelectorAll('.tabs span')
      Object.keys(tab).map(item=>{
        tab[item].style.color='#B7B8BB'
        tab[item].style.borderBottom="none"
      })
      // border-bottom: #AB54DB solid 4px;
      document.getElementById(e).style.color='#AB54DB'
      document.getElementById(e).style.borderBottom="#AB54DB solid 4px"
    },
    download_app(){
      if (this.$util._isMobile()){
        this.$router.push({name: 'download', query:{showBack: 1}})
      } else {
        this.$router.push({name: 'download', query:{showBack: 1}})
        // this.showcode = true
      }
    },
  },
  destroyed(){
    window.removeEventListener('scroll', this.changeNav,true)
  },

}
</script>

<style scoped>
@media screen and (min-width: 1260px){
  .nav-left{
    position: absolute;
    left: 36px;
    height: 64px;
  }
  .nav-right{
    position: absolute;
    right: 36px;
    height: 64px;
  }

}

.container{
  width: 100%;
  position: fixed;
  /*background-image: linear-gradient(to right ,#0F141C ,#171953);*/
  background: #393948;
  top: 0;

  /*background: linear-gradient(270deg, #08094B 0%, #10151D 100%);*/
}
.top{
  padding: 0 30px;
}
.nav{
  height: 64px;
  margin: 0 auto;
  max-width: 1200px;
}
.nav>.nav-left{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav>.nav-left>img{
  width: 90px;
  height: 24px;
}
/*.nav>.nav-left>div{*/
/*  display: inline-block;*/
/*}*/
.nav>.nav-left>div>span{
  font-size: 16px;
  font-weight: 400;
  color: #B7B8BB;
  margin-left: 56px;
  height: 64px;
  line-height: 64px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}
.nav>.nav-left>div>span:hover{
  color: #AB54DB;
  border-bottom: #AB54DB solid 4px;
}

.nav{
  display: flex;
  justify-content: space-between;
}
.nav .nav-right{
  font-size: 16px;
  font-weight: 400;
  color: #B7B8BB;
  display: flex;
  align-items: center;
}
/*.nav .nav-right>span:nth-child(1){*/
/*  margin-right: 30px;*/
/*}*/

.sign_in{
  margin-right: 30px;
}

.nav .sm{
  display: none;
}
/*鼠标悬浮处理*/
.nav-right>span:hover{
  opacity: 0.9;
  cursor: pointer;
}
.nav-right>img:hover{
  opacity: 0.9;
  cursor: pointer;
}
@media screen and (max-width: 900px) {
  .top{
    padding: 0 20px;
    height: 54px;
  }
  .nav-left > div {
    display: none !important;
  }
  .sm {
    display: block !important;
  }
  .lg {
    display: none;
  }

  .sign_in {
    /*width: 58px;*/
    height: 28px;
    border-radius: 2px;
    /*border: 1px solid #AB54DB;*/
    font-size: 14px;
    color: #AB54DB;
    text-align: center;
    line-height: 28px;
    margin-right: 30px !important;
  }

  .sign_up {
    font-size: 14px;
    text-align: center;
    line-height: 28px;
    opacity: 0.7;
  }

  /*.nav-right > span:nth-child(2) {*/
  /*  width: 58px;*/
  /*  height: 28px;*/
  /*  border-radius: 2px;*/
  /*  border: 1px solid #AB54DB;*/
  /*  font-size: 14px;*/
  /*  color: #AB54DB;*/
  /*  text-align: center;*/
  /*  line-height: 28px;*/
  /*  margin-right: 20px !important;*/
  /*}*/
  /*.nav-right > span:nth-child(3) {*/
  /*  font-size: 14px;*/
  /*  text-align: center;*/
  /*  line-height: 28px;*/
  /*  opacity: 0.7;*/
  /*}*/
  .nav{
    height: 54px;
    margin: 0 auto;
    max-width: 1200px;
  }
}

.dialog_down >>>.el-dialog--center{
  border-radius: 12px;
  padding: 40px 0;
}

.download-app {
  margin-right:26px;
  width: 30px;
  z-index: 999999 !important;
  opacity: 0;
}

.download_img_show{
  transform: scale(1, 1);
  /*transition-duration: .5s;*/
  width: 30px;
  opacity: 1;
  -webkit-animation: twinkling ease-in 0.6s;
}

@-webkit-keyframes twinkling{    /*透明度由0到1*/
  0%{
    opacity:0; /*透明度为1*/
  }
  100%{
    opacity: 1;
  }
}

.nav-left>div{
  display: none;
}

.language_selected{
  color: #AB54DB;
}
</style>
